<template>
  <div class="col-4 col-md-6 col-lg-4 col-xl-2 mb-3">
    <b-overlay spinner-variant="primary" class="h-100" :show="isAdding">
      <figure
        @click="productSelected(product)"
        class="
          card
          card-menu
          clickeable
          card-product
          pusheable
          h-100
          justify-content-between
        "
      >
        <div :class="isMobile ? 'img-wrap-mobile' : 'img-wrap-desktop'">
          <img class=""  :src="fullImagePath" width="100%" />
        </div>
        <figcaption class="info-wrap p-1">
          <h6 class="title text-center product-text">{{ product.name }}</h6>
          <div class="price-wrap h6 text-center">
            <span class="price-new">
              <strong>$ {{ getPrice(product) }} </strong>
            </span>
          </div>
          <!-- price-wrap.// -->
        </figcaption>
        <!-- <div class="d-flex position-absolute top-0 h-100 w-100 justify-content-center align-items-center shadow"  v-if="isAdding">
                <b-spinner small ></b-spinner>
            </div> -->
      </figure>
    </b-overlay>
    <vtr-alert :alerts="alerts"></vtr-alert>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "Product",
  props: ["product", "quantity", "command","isMobile"],
  data() {
    return {
      alerts: [],
    };
  },
  methods: {
    productSelected(product) {
      if (
        product.composable_product_items.length > 0 ||
        product.extras.length > 0
      ) {
        this.$store.dispatch("showComposableProductPanel", product);
      } else if (product.purchasable_type === "available_package") {
        this.$store.dispatch("showProductPanelPackage", product);
      } else {
        this.$store.dispatch("showProductPanel", product);
      }
    },
    getPrice(product) {
      // //TODO: Use currentPrice property
      let channelPrices;
      if (this.distributionChannel != null) {
        /*  if a channel is set, respect just prices for this channel */
        channelPrices = product.prices.filter(
          (p) => p.distribution_channel_id == this.distributionChannel.id
        );
        if (channelPrices.length == 0)
          channelPrices = product.prices.filter(
            (p) => p.distribution_channel_id == null
          );
      } else {
        /*  else, respect just prices for restaurant (null channel) */
        channelPrices = product.prices.filter(
          (p) => p.distribution_channel_id == null
        );
      }
      return channelPrices.length > 0
        ? channelPrices[channelPrices.length - 1].amount / 100
        : 0;
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {
    ...mapGetters(["distributionChannel"]),
    ...mapState({
      tenantUrl: (state) => state.tenantUrl,
      accountSelected: (state) => state.command.accountSelected,
      productsBeingAdded: (state) => state.productsBeingAdded,
      
    }),
    price() {
      return this.getPrice(this.product);
    },
    fullImagePath() {
      return this.tenantUrl != null
        ? this.tenantUrl + "" + this.product.image_path
        : "";
    },
    isAdding() {
      return this.productsBeingAdded.includes(this.product.id);
    },
  },
};
</script>

<style>
</style>