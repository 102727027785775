<template>
<main class="main-menu">
    <section class="breadcrumb-area d-flex align-items-center mt-5">
        <div class="container">
            <b-row v-if="loadingCategories" class="d-flex justify-content-center align-items-center flex-column" style="height: 80vh">
                <b-spinner variant="primary" class="mb-2" style="width: 4rem; height: 4rem;"></b-spinner>
                <h2 class="u-text">Espere, cargando menu</h2>
            </b-row>
            <div class="row" v-else>
                <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                    <div class="breadcrumb-wrap text-center">
                        <div class="breadcrumb-title mt-100 mb-30">
                            <h2><b-link class="text-danger font-weight-bold mr-2" @click.prevent="goMain"><i class="fas fa-arrow-left"></i></b-link>{{ hotel.name }}</h2>
                            <h2>Menú</h2>                                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section v-if="!loadingCategories" class="menu">
        <b-container class="mt-4 my-4">
            <b-row class="pb-4" v-for="(cat, i) in categories" :key="`category_product${i}`">
                <div class="col-md-12" v-if="productList(cat).length > 0">
                    <div class="title">{{ cat.name }}</div>
                </div>
                <div class="col-md-12">
                    <ul class="menu">
                        <li v-for="(prod, ii) in productList(cat)" :key="`product_item${ii}`">
                            <!--NOMBRE DEL PRODUCTO-->
                            <div class="detail">
                                <strong>{{ prod.name }}</strong>
                                <span class="dots"></span>
                                <span class="price">{{ (getPrice(prod)) | currency }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </b-row>
            <a class="btnAllProds" @click="toggleTemplate">{{$t('title.return_menu')}}</a>
        </b-container>
    </section>
</main>
</template>

<script>
import {mapState} from 'vuex';
export default {
    data() {
        return {
            distributionChannel: null
        }
    },
    mounted() {
        this.$store.dispatch('getProducts');
    },
    computed: {
        ...mapState({
            hotel: state => state.hotel,
            loadingCategories: state => state.loadingCategories,
            categories: state => state.categories
        }),
        products() {
            return this.$_recursiveProducts(this.categories, []);
        }
    },
    methods: {
        productList(category) {
            var products = [];
            //Get products of recursive categories
            //NOTE: Only the first parent name is shown in the menu
            let categories = this.categoryChildren(category, []); //Params ====> (Object, array)
            if(categories.length > 0) {
                products = this.products.filter(p => (categories.indexOf(parseInt(p.category_id)) > -1));
            }
            return products;
        },
        categoryChildren(category, arr) {
            var children = arr;
            if(children.length == 0) {
                children.push(parseInt(category.id));
            }

            if ('subcategories' in category) {
                for (let cat of category.subcategories) {
                    children.push(parseInt(cat.id));
                    this.categoryChildren(cat, children);
                }
            }

            if ('sub_categories' in category) {
                for (let cat of category.sub_categories) {
                    children.push(parseInt(cat.id));
                    this.categoryChildren(cat, children);
                }
            }

            return children;
        },
        $_recursiveProducts(items, arr) {
            //In order to be iterable, non-array objects must have a [Symbol.iterator]() method.
            var products = arr;
            for (let cat of items) {
                for (let p of cat.products) {
                    products.push(p);
                }

                if ('subcategories' in cat) {
                   this.$_recursiveProducts(cat.subcategories, products);
                }

                if ('sub_categories' in cat) {
                   this.$_recursiveProducts(cat.sub_categories, products);
                }
            }

            return products;
        },
        goMain() {
            this.$router.push({ name: 'Home', params: { id: this.$root.table_qrcode } });
        },
        getPrice(product) {
            let channelPrices;
            if (this.distributionChannel != null) {
                /*  if a channel is set, respect just prices for this channel */
                channelPrices = product.prices.filter(p => p.distribution_channel_id == this.distributionChannel.id);
                if (channelPrices.length == 0)
                    channelPrices = product.prices.filter(p => p.distribution_channel_id == null);
            } else {
                /*  else, respect just prices for restaurant (null channel) */
                channelPrices = product.prices.filter(p => p.distribution_channel_id == null);
            }
            return channelPrices.length > 0 ? channelPrices[channelPrices.length - 1].amount / 100 : 0;
        },
        toggleTemplate() {
            this.$store.commit('toggleMenuTemplate', 'Template');
        }
    }
}
</script>

<style scoped>
    main.main-menu {
        background-image: url(http://zcube.in/thesignatures/img/bg/test-bg.png);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        overflow: auto;
        color: #ffff;
    }

    .breadcrumb-title h2 {
        font-size: 50px;
        margin-bottom: 25px;
        line-height: 1;
        transition: 0.5s all ease;
    }

    .breadcrumb-title h2:nth-of-type(2) {
        color: #FFCE1C;
        font-weight: 500;
        transition: 0.5s all ease;
    }

    section.menu .row {
        -webkit-transition: opacity 1s ease-in;
        -o-transition: opacity 1s ease-in;
        transition: opacity 1s ease-in;
    }

    section.menu .title {
        font-size: 35px;
        font-weight: 300;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        margin: 20px 20px 20px 20px;
        line-height: 47px;
    }

    section.menu ul {
        list-style: none;
        text-align: left;
        margin: 0px;
        padding: 0px;
    }

    section.menu ul li {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
        color: #ffffff;
        display: inline-block;
        width: 49.8%;
        margin-bottom: 15px;
        padding-right: 25px;
        transition: 0.5s all ease;
    }

    .dots {
        border-bottom: dotted 4px #999;
        margin-top: 0.8em;
        width: 80%;
    }

    .menu strong {
        padding: 0 10px 0 0;
        font-weight: normal;
    }

    section.menu ul li .detail {
        text-transform: none;
        font-weight: 400;
        color: #cacaca;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    section.menu span.price {
        font-weight: 700;
        font-style: italic;
        font-size: 18px;
        color: #ffc107;
        padding: 0 0 0 5px;
        bottom: -.2em;
        right: 0;
    }

    @media (max-width: 767px) {
        .breadcrumb-title h2:nth-of-type(1) {
            display: none;
        }

        section.menu ul li {
            width: 100%;
        }

        .breadcrumb-title h2 {
            font-size: 30px;
        }

        section.menu .title {
            font-size: 25px;
            line-height: 0%;
        }

        section.breadcrumb-area {
            margin-top: 1.5rem !important;
        }
    }

</style>