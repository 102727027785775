<template>
  <div>
    <template>
      <span class="text-base">
        <i class="fa fa-spin fa-spinner" v-if="savingComment"></i
      ></span>
    </template>
    <b-form-textarea
      class=""
      size="lg"
      :debounce="500"
      :value="text"
      @update="changeProductComment"
      :placeholder="$t('title.comments')"
      rows="3"
      :disabled="disabled"
    ></b-form-textarea>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
//import debounce from "lodash/debounce";
export default {
  props: ["product", "comment", "disabled"],
  data() {
    return {
      text: "",
      savingComment: false,
    };
  },
  computed: {
    ...mapState({
      productBeingEdited: (state) => state.productBeingEdited
      }),
  },
  methods: {
    ...mapActions(["updateProductSelected"]),
    changeProductComment(value) {

      console.log("comment:",value)
      this.text = value;
      if (this.text != null && this.text != "") {
        this.product.comments = this.text;
      }
      //this.$store.dispatch("updateProductSelected", [{ id: this.productId, comments: this.text }]);
    },
    /*changeProductComment: debounce(async function (value) {
      this.text = value;
      this.savingComment = true;
      await this.updateProductSelected(["comments", this.text,this.product]);

      this.savingComment = false;
    }, 500),*/
  },
  watch: {
    comment: {
      immediate: true,
      handler(newValue) {
        this.text = newValue;
      },
    },
  },
};
</script>