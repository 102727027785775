  <template>
  <div
    class="navigation-container-template"
    v-if="productSelected == null && productComposableSelected == null"
  >
    <div class="bg-white d-flex p-3 shadow flex-shrink-0 overflow-hidden ">
      <div class="d-flex navigation-selector flex-grow-1">
        
          <!--<b-navbar-brand
              class="mt-md-3 mr-3 mr-md-0 btn-pad"
              href="#"
              @click="filterCategory(cat)"
              :id="`button-category-${i}`"
            >
              <span class="tex-navigation">{{ cat.name }}</span>
            </b-navbar-brand>-->

          <b-button
          v-for="(cat, i) in category_list" 
          :key="`category_product${i}`"
             :class="isMobile? '':'mt-md-3 mr-3 mr-md-0 btn-pad'"
            :size="isMobile? 'sm' :'lg'"
            :id="`button-category-${i}`"
            :variant="`outline-${variant}`"
            @click="filterCategory(cat)"
          >
            {{ cat.name }}
          </b-button>
          <!-- <b-navbar-brand
              v-if="
                cat.subcategory_id == null &&
                !cat.packages &&
                !haveSubCategory(cat)
              "
              class="mt-md-3 mr-3 mr-md-0 btn-pad"
              href="#"
              @click="filterCategory(cat)"
              :id="`button-category-${i}`"
            >
              {{ cat.name }}
            </b-navbar-brand>
            <b-nav-item-dropdown
              @click="filterCategory(cat)"
              :text="cat.name"
              right
              v-if="
                cat.subcategory_id == null &&
                !cat.packages &&
                haveSubCategory(cat) && !haveSubCategory_(cat)
              "
            >
              <b-dropdown-item
                href="#"
                v-for="(sub, j) in cat.subcategories"
                @click="filterCategory(sub)"
                :key="`category_product${j}`"
              >
                {{ sub.name }}</b-dropdown-item
              >
            </b-nav-item-dropdown>

            <b-nav-item-dropdown
              :text="cat.name"
              right
              v-if="
                cat.subcategory_id == null &&
                !cat.packages &&
                !haveSubCategory(cat) && haveSubCategory_(cat)
              "
            >
              <b-dropdown-item
                href="#"
                v-for="(sub_, j) in subCategories(cat)"
                @click="filterCategory(sub_)"
                :key="`category_product${j}`"
              >
                {{ sub_.name }}</b-dropdown-item
              >
            </b-nav-item-dropdown>-->

          <!-- <b-button 
             v-for="(cat, i) in category_list"   
             :key="`category_product${i}`"    
          class="mt-md-3 mr-3 mr-md-0 btn-pad"
          :id="`button-category-${i}`"
          :variant="`outline-${variant}`"
          @click="filterCategory(cat)"
          
        >
          {{ cat.name }}
        </b-button>-->
      
      </div>
    </div>
  </div>
</template> 
<script>
import { mapState } from "vuex";
export default {
  name: "CategoryNavigation",

  props: {
    variant: {
      type: String,
      default: "primary",
    },
    isMobile:{
      default:false,
      require:true,
    }
  },
  methods: {
    $_recursiveCategories(items, arr) {
      //In order to be iterable, non-array objects must have a [Symbol.iterator]() method.
      var cats = arr;
      for (let cat of items) {
        cats.push(cat);
        if ("subcategories" in cat) {
          this.$_recursiveCategories(cat.subcategories, cats);
        }

        if ("sub_categories" in cat) {
          this.$_recursiveCategories(cat.sub_categories, cats);
        }
      }

      return cats;
    },

    $_recursiveSubCategories(items, arr) {
      //In order to be iterable, non-array objects must have a [Symbol.iterator]() method.
      var cats = arr;
      for (let cat of items) {
        cats.push(cat);
        if ("subcategories" in cat) {
          this.$_recursiveCategories(cat.subcategories, cats);
        }

        if ("sub_categories" in cat) {
          this.$_recursiveCategories(cat.sub_categories, cats);
        }
      }

      return cats;
    },
    haveSubCategory(cat) {
      let have = false;

      if (cat.subcategories) {
        if (cat.subcategories.length > 0) {
          have = true;
        }
      }
      return have;
    },

    haveSubCategory_(cat) {
      let have = false;

      if (cat.sub_categories) {
        if (cat.sub_categories.length > 0) {
          have = true;
        }
      }
      return have;
    },
    subCategories(cat) {
      return this.$_recursiveSubCategories(cat, []);
      /*let sub = [];
      if ("subcategories" in cat) {
        if (cat.subcategories.length > 0) {
          sub = cat.subcategories;
        }
      } else if ("sub_categories" in cat) {
        if (cat.sub_categories.length > 0) {
          sub = cat.sub_categories;

          console.log("sub", sub);
        }
      }

      return sub;*/
    },

    filterCategory(category) {
      console.log("category", category);
      let refname = `category-${category.id}`;
      document.getElementById(refname).scrollIntoView(true);
    },
  },
  computed: {
    category_list() {
      let cats = [];
      if (this.packages.length > 0) {
        let pq = [
          {
            id:0,
            name: this.$tc("title.package", 2),
            packages: this.packages,
          },
        ];
        cats = pq.concat(this.categories);
      } else {
        cats = this.categories;
      }

      return cats;
    },
    category_list_() {
      let cats = [];
      if (this.packages.length > 0) {
        let pq = [
          {
            name: this.$tc("title.package", 2),
            packages: this.packages,
          },
        ];
        cats = pq.concat(this.categories);
      } else {
        cats = this.categories;
      }

      return this.$_recursiveCategories(cats, []);
    },

    ...mapState({
      productSelected: (state) => state.productSelected,
      packages: (state) => state.packages,
      categories: (state) => state.categories,
      productComposableSelected: (state) => state.productComposableSelected,
    }),
  },
};
</script>

<style scoped>
.navigation-container-template {
  width: 100%;
  
  flex-wrap: wrap;
  justify-content: start !important;
}

.btn-pad {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.tex-navigation {
  color: #138496;
  margin: 26px auto 0px;
  width: initial;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.2;
  font-size: 2.25rem;
  letter-spacing: 2px;
  padding: 0;
}

@media screen and (min-width: 768px) and (max-height: 760px) {
  .btn-pad {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
