var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white d-flex flex-md-column flex-nowrap p-3 shadow flex-shrink-0 overflow-hidden h-100"},[_c('div',{staticClass:"d-flex flex-md-column flex-nowrap justify-content-center overflow-auto flex-grow-1"},[(!_vm.currentComponent && !_vm.showingExtras  &&  _vm.confirmed() === false  && _vm.preConfirmed() === false)?_c('b-button',{attrs:{"variant":_vm.productComposableBeingEdited !== null ? 'primary' : 'success',"disabled":_vm.processing,"size":"lg"},on:{"click":_vm.addProduct}},[(_vm.processing)?_c('b-spinner',{attrs:{"small":"","variant":"success"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.productComposableBeingEdited !== null ? _vm.$tc("title.updated_product", 1) : _vm.$tc("title.add_products", 1))+" ")],1):_vm._e(),(
        _vm.productComposableBeingEdited !== null &&
        _vm.showPrice &&
        _vm.currentComponent == null &&
        !_vm.showingExtras &&
        _vm.preConfirmed() === false &&
        _vm.confirmed() === false
      )?_c('b-button',{attrs:{"size":"lg","variant":"danger","disabled":_vm.deleting},on:{"click":_vm.deleteProduct}},[(_vm.deleting)?_c('b-spinner',{attrs:{"small":"","variant":"success"}}):_c('span',{staticClass:"fas fa-trash"}),_vm._v(" "+_vm._s(_vm.$tc("title.removed_product", 1))+" ")],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }