import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './lang/en';

import axios from "axios";

Vue.use(VueI18n);

export const LANG_KEY = 'vtr_lang';

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

const loadedLanguages = ['en'];

function setI18nLanguage(tenantApiUrlBase, lang, qr_code, command) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);

  if (command != null) {
    let params = {
      preffered_lang: lang,
    }
    axios.defaults.headers.common['Accept-Language'] = lang;

    //console.error(tenantApiUrlBase, lang, qr_code, command);
    if(tenantApiUrlBase && qr_code)
      axios.post(tenantApiUrlBase + '/api-v2/current-reservation/' + qr_code + '/change-language/' + command.open_table.id, params);

  }

  return lang;
}

export function loadLanguageAsync(tenantApiUrlBase, lang, qr_code, command) {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(tenantApiUrlBase, lang, qr_code, command));
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(tenantApiUrlBase, lang, qr_code, command));
  }

  return import(/* webpackChunkName: "lang-[request]" */ `./lang/${lang}.js`)
    .then(messages => {
      i18n.setLocaleMessage(lang, messages.default[lang]);
      loadedLanguages.push(lang);
      return setI18nLanguage(tenantApiUrlBase, lang, qr_code, command);
    })
}
