import { render, staticRenderFns } from "./ComposableProductSelector.vue?vue&type=template&id=d24f4252&scoped=true&"
import script from "./ComposableProductSelector.vue?vue&type=script&lang=js&"
export * from "./ComposableProductSelector.vue?vue&type=script&lang=js&"
import style0 from "./ComposableProductSelector.vue?vue&type=style&index=0&id=d24f4252&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d24f4252",
  null
  
)

export default component.exports