<template>
    <b-row class="mx-0 flex-grow-1 flex-shrink-1 flex-nowrap flex-basis-auto">
      <div
        v-if="hasPaymentPlatform"
        class="
          col-lg-12
          box
          bg-products
          d-none d-md-block
          position-fixed position-md-relative
          h-100 h-md-auto
          top-0
          px-0 px-md-3
          fixable
          d-block
          overflow-x-hidden
        "
        style="z-index: 10"
      >
        <h5 class="mb-3 d-flex justify-content-between">
          <span>
            <a href="#" class="d-inline-block mr-3" @click.prevent="back">
              <i class="fas fa-arrow-left"></i>
              {{ $t("title.back") }}
            </a>
          </span>
        </h5>
        <div class="flex-grow-1 py-lg-5" v-if="!make_payment && !isSaving">
            <div class="d-flex flex-column h-100">
                <b-col class="">
                    <div class="text-center pt-4 pb-0">
                        <h2 class="font-size-pay text-info">
                            {{ $t("info.select_options") }}:
                        </h2>
                    </div>
                    <b-tabs content-class="mt-3" align="center" v-model="_account" >
                        <b-tab
                            v-for="account in accounts"
                            :title="getAccountTitle(account)"
                            :key="account.account"
                            :disabled="disableAccount(account) || !checkAllIsConfirmed(account.purchases)"
                        >
                        <div>
                            <b-button
                                :title="$t('title.request_account')"
                                class="btn-req-bill ml-2 mb-1"
                                variant="warning"
                                size="md"
                                @click.prevent="makeRequest()"
                                :disabled="callingWaiter || requestingInfo"
                            >
                                <b-icon class="fa-4x" icon="file-earmark-text"></b-icon>
                                {{ $t("title.request_account") }}
                            </b-button>
                            <b-button
                                :title="$t('title.request_account')"
                                class="btn-req-bill ml-2 mb-1"
                                variant="success"
                                size="md"
                                @click.prevent="preparePayAccount(account)"
                                :disabled="callingWaiter || requestingInfo"
                            >
                                <b-icon class="fa-4x" icon="credit-card"></b-icon>
                                {{ $t("title.make_payment") }}
                            </b-button>
                        </div>
                    </b-tab>
                </b-tabs>
                </b-col>
            </div>
        </div>
        <consumption-note
            v-if="make_payment && !isSaving"
            :account="account"
            :company="company"
        />
      </div>
    </b-row>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import { getAccountTitle, disableAccount, checkAllIsConfirmed } from "../../js/utils.js";
  import ConsumptionNote from './ConsumptionNote.vue';

  export default {
    name: "newpay",
    props: ["callingWaiter", "company"],
    data() {
      return {
        requestingInfo: false,
        make_payment: false,
        isSaving: false,
        alerts: [],
        account: null,
      };
    },
    components: {
        ConsumptionNote
    },
    mounted() {
      // Nothing here yet
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },
    computed: {
        ...mapState({
            //hotel: (state) => state.hotel,
            //menuTemplate: (state) => state.menuTemplate,
            commandData: (state) => state.command,
            accounts: (state) => state.command.open_table.table_accounts,
            //: (state) => state.command.open_table.waiter,
            //waiterAction: (state) => state.waiterAction,
            //client: (state) => state.client,
            //closeOpenTable: (state) => state.closeOpenTable,
            //isLoadingCommand: (state) => state.isLoadingCommand,
            hasPaymentPlatform: (state) => state.hasPaymentPlatform,
            accountSelected: (state) => state.command.accountSelected, //this shit doesn't update
            //currentQRCode: (state) => state.currentQRCode,
            //tipsSettings: (state) => state.tipsSettings,
            //tableName: (state) => state.tableName,
            //table: (state) => state.table,
        }),
        //this var is for the tab only
        _account: {
            get() {
                console.error(this.accountSelected)
                const currentAccount = this.accounts.findIndex( a => a.account == this.accountSelected);
                return currentAccount;
            },
            set(value) {
                const activeTab = parseInt(this.accounts[value].account);
                this.$store.commit("changeAccountSelected", [activeTab]);
            },
        },
    },
    methods: {
        getAccountTitle,
        disableAccount,
        checkAllIsConfirmed,
        back() {
            this.$router.push({
                name: "Home",
                params: { id: this.$root.table_qrcode },
            });
        },
        changeAccountSelected(event) {
            this.$store.dispatch("changeAccountSelected", [event]);
            //this.$store.dispatch("buttonBillData");
        },
        makeRequest() {
            this.$emit("makerequest");
        },
        preparePayAccount(account) {
            this.account = account;
            this.make_payment = true;
            //this.total = this.getTotal(this.purchases);
        }
    },
  };
  </script>