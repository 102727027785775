<template>
  <!-- The height of this component is delegated to parent for shortcut when only has one component or only has one extra -->
  <div class="d-flex flex-column h-100 text-sm">
    <div class="mb-3 d-flex justify-content-between">
      <span
        v-if="limit > 0 && confirmed === false && preConfirmed === false"
        class="text-base text-dark font-weight-bold"
      >
        {{ $t("title.choose_option") }} {{ limit }} {{ $t("title.type_of") }}
        {{ title }}
      </span>

      <span
        v-else-if="confirmed === false && preConfirmed === false"
        class="text-base text-dark font-weight-bold"
      >
        {{ $t("title.choose_your") }} {{ title }}
      </span>
      <br />

      <b-button
        variant="success"
        @click.prevent="close"
        v-if="!withoutConfirmation"
      >
        <b-spinner v-if="saving" small></b-spinner>
        <span v-if="selected === 0">{{ emptySelectionLabel }}</span>
        <span v-else>{{ $t("info.confirm_selection") }}</span>
      </b-button>
    </div>
    <div class="mb-3 d-flex justify-content-between">
      <span class="text-sm py-1 px-2 border border-info text-info rounded">
        {{ selected }} <span v-if="limit !== null">/ {{ limit }}</span>
      </span>
    </div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="warning"
      @dismissed="dismissCountDown = 0"
    >
      {{ $t("info.num_max_product") }} {{ limit }}
    </b-alert>
    <div
      class="
        d-flex
        py-4
        bg-white
        w-100
        overflow-x-auto overflow-y-hidden
        sticky-panel
      "
    >
      <div
        v-for="(s, index) in selection"
        :key="index"
        class="
          d-flex
          justify-content-center
          align-items-center
          wx-20
          h-20
          bg-gray-200
          mx-2
          rounded
          flex-shrink-0
        "
        :class="{
          'border border-primary text-primary': index === currentIndex,
        }"
        ref="options"
      >
        <div v-if="s.product" class="flex-grow-1 w-100 py-4 text-sm">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-weight-bold">{{ index + 1 }}</span>
            <b-icon
              v-if="isComposable(s.product)"
              icon="pencil-square"
              scale="1.2"
              class="text-primary"
              @click="editSelection(s, index)"
            ></b-icon>
            <b-icon
              v-if="confirmed === false"
              icon="x"
              scale="1.2"
              class="text-danger"
              @click="removeItem(index)"
            ></b-icon>
          </div>
          <div class="text-center">
            <img
              :src="getFullImagePath(s.product)"
              :alt="s.product.name"
              class="h-6"
            />
          </div>
          <div class="text-center text-truncate">
            {{ s.product.name }} x {{ s.qty }}
          </div>
        </div>
        <div
          v-else
          class="text-lg"
          :class="{
            'text-dark': index !== currentIndex,
            'text-primary': index === currentIndex,
          }"
        >
          {{ index + 1 }}
        </div>
      </div>
    </div>
    <div
      v-if="!confirmed"
      class="
        d-flex
        flex-grow-1 flex-wrap
        align-items-start align-content-start
        mt-3
        overflow-x-hidden overflow-y-auto
      "
    >
      <div
        v-for="item in items"
        :key="item.id"
        class="w-md-25 w-50 p-2 text-base"
        @click="selectItem(item, !onlyHasExtras(item))"
      >
        <div
          class="
            bg-white
            p-3
            text-dark text-center text-dark
            border
            position-relative
          "
        >
          <slot name="item-name" :item="item">
            <span class="">{{ item.name }}</span>
            <b-button
              v-if="onlyHasExtras(item)"
              @click="selectItem(item)"
              class="floating-extra-button"
              variant="dark"
            >
              <b-icon icon="plus"></b-icon>
            </b-button>
          </slot>
        </div>
      </div>
    </div>
    <composable-product
      v-if="composableItem"
      :product="composableItem"
      :nesting="nesting + 1"
      :value="this.editingComponent"
      @add-product="addComposableItem"
      @close-panel="composableProductClose"
    ></composable-product>
  </div>
</template>

<script>
// import ComposableProduct from './ComposableProduct.vue'
//import ProductQuantityButton from './ProductQuantityButton.vue'
import cloneDeep from "lodash/cloneDeep";
import { mapState } from "vuex";
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    qty: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
    nesting: {
      type: Number,
      default: 1,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    emptySelectionLabel: {
      type: String,
      default: () => "Dejar vacio",
    },
    withoutConfirmation: {
      type: Boolean,
      default: false,
    },
    confirmed: {
      type: Boolean,
      default: false,
    },
    preConfirmed: {
      type: Boolean,
      default: false,
    },
    command: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      composableItem: null,
      editingComponent: null,
      currentQty: 0,
      selection: [],
      currentIndex: 0,
      dismissCountDown: 0,
      productquantity: 0,
    };
  },
  components: {
    ComposableProduct: () => import("./ComposableProduct.vue"),
    //ProductQuantityButton,
  },
  computed: {
    ...mapState({
      tenantUrl: (state) => state.tenantUrl,
      productComposableBeingEdited: (state) =>
        state.productComposableBeingEdited,
      processing: (state) => state.processing,
    }),
    missing() {
      if (!this.limit) return 0;

      return this.limit - this.selected;
    },
    selected() {
      return this.selection.reduce((acc, s) => s.qty + acc, 0);
    },
  },
  mounted() {
    //this.scrollToCurrentIndex();
  },
  methods: {
    confirmProduct() {
      console.log(this.selected);
      this.productComposableBeingEdited != null
        ? this.editProduct()
        : this.close();
    },

    editProduct() {
      this.$emit("update-selector", this.selection.slice());
      this.$emit("add-product");
      //this.$emit("close");
    },

    onlyHasExtras(item) {
      return (
        (!Object.prototype.hasOwnProperty.call(
          item,
          "composable_product_items"
        ) ||
          item.composable_product_items.length == 0) &&
        Object.prototype.hasOwnProperty.call(item, "extras") &&
        item.extras.length > 0
      );
    },
    updateSelection() {
      if (this.withoutConfirmation) {
        this.$emit("update-selector", this.selection.slice());
      }
    },
    saveQuantity(item, currentQty, deep = true) {
      console.log("selectItem:item", item);
      console.log("productquantity", currentQty);

      if (this.limit && parseFloat(this.qty) > this.missing) {
        this.dismissCountDown = 3;
        return;
      }

      if (
        deep &&
        ((Object.prototype.hasOwnProperty.call(
          item,
          "composable_product_items"
        ) &&
          item.composable_product_items.length > 0) ||
          (Object.prototype.hasOwnProperty.call(item, "extras") &&
            item.extras.length > 0))
      ) {
        // dispatch('selectComposableProduct', item)
        this.composableItem = item;
        this.currentQty = this.qty;
      } else {
        let selected = this.selection[this.currentIndex];
        // selected.product = item
        selected.product = {
          id: item.id,
          name: item.name,
          image_path: item.image_path,
          price: parseInt(item.current_price),
        };
        selected.qty = this.qty;

        if (this.limit === null) {
          this.selection.push({
            product: null,
            qty: 0,
          });
        }

        this.currentIndex++;
        this.$store.commit("resetAccumulator", null, { root: true });
        this.$nextTick(() => {
          this.scrollToCurrentIndex();
        });
        this.updateSelection();
      }
    },

    selectItem(item, deep = true) {
      console.log("selectItem:item", item);

      if (this.limit && parseFloat(this.qty) > this.missing) {
        this.dismissCountDown = 3;
        return;
      }

      if (
        deep &&
        ((Object.prototype.hasOwnProperty.call(
          item,
          "composable_product_items"
        ) &&
          item.composable_product_items.length > 0) ||
          (Object.prototype.hasOwnProperty.call(item, "extras") &&
            item.extras.length > 0))
      ) {
        // dispatch('selectComposableProduct', item)
        this.composableItem = item;
        this.currentQty = this.qty;
      } else {
        let selected = this.selection[this.currentIndex];
        // selected.product = item
        selected.product = {
          id: item.id,
          name: item.name,
          image_path: item.image_path,
          price: parseInt(item.current_price),
        };
        selected.qty = this.qty;

        if (this.limit === null) {
          this.selection.push({
            product: null,
            qty: 0,
          });
        }

        this.currentIndex++;
        this.$store.commit("resetAccumulator", null, { root: true });
        this.$nextTick(() => {
          this.scrollToCurrentIndex();
        });
        this.updateSelection();
      }
    },
    removeItem(index) {
      if (this.limit === null) {
        this.currentIndex--;
        this.selection.splice(index, 1);
        return;
      }

      const l = this.selection.length;

      for (let i = 0; i < l - index; i++) {
        let nextTo = this.selection[index + i + 1];

        if (nextTo && nextTo.product) {
          let current = this.selection[index + i];
          current.product = nextTo.product;
          current.qty = nextTo.qty;
        } else {
          this.cleanSlot(index + i);
          this.currentIndex = index + i;
          break;
        }
      }

      this.$nextTick(() => {
        this.scrollToCurrentIndex();
      });
      this.updateSelection();
    },
    cleanSlot(index) {
      let selected = this.selection[index];
      selected.product = null;
      selected.qty = 0;
      this.updateSelection();
    },
    addComposableItem(data) {
      let selected = this.selection[this.currentIndex];
      selected.product = data;
      selected.qty = this.currentQty;

      if (this.limit === null) {
        this.selection.push({
          product: null,
          qty: 0,
        });
      }

      if (this.editingComponent !== null) {
        this.editingComponent = null;
        // TODO: Manage current index
      }

      this.composableItem = null;
      this.currentQty = 1;

      this.currentIndex++;
      this.$store.commit("resetAccumulator", null, { root: true });
      this.updateSelection();
    },
    composableProductClose() {
      if (this.editingComponent !== null) {
        this.editingComponent = null;
        // TODO: Manage current index
      }

      this.composableItem = null;
      this.currentQty = 1;
    },
    isComposable(product) {
      return (
        Object.prototype.hasOwnProperty.call(product, "components") &&
        product.components
      );
    },
    editSelection(selection, index) {
      console.log("selection", selection);
      this.currentIndex = index;
      this.editingComponent = {
        selection: selection.product.components,
        extras: selection.product.extras,
      };
      this.composableItem = this.items.find(
        (i) => i.id === selection.product.id
      );
      this.currentQty = selection.qty;
    },
    scrollToCurrentIndex() {
      if (this.currentIndex < this.selection.length)
        this.$refs.options[this.currentIndex].scrollIntoView();
    },
    close() {
      this.$emit("update-selector", this.selection.slice());
      this.$emit("close");
    },
    closeSelector() {
      this.$emit("close");
    },
    getFullImagePath(product) {
      return this.tenantUrl != null ? this.tenantUrl + product.image_path : "";
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.selection = cloneDeep(value); // [...value]

          if (this.limit === null) {
            this.selection.push({
              product: null,
              qty: 0,
            });
          }

          const currentIndex = this.selection.findIndex(
            (s) => s.product === null
          );
          this.currentIndex =
            currentIndex === -1 ? this.selection.length - 1 : currentIndex;
          // this.scrollToCurrentIndex();
        }
      },
    },
  },
};
</script>

<style scoped>
.sticky-panel {
  position: sticky;
  top: 0px;
}

.floating-extra-button {
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>