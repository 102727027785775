<template>
  <div class="d-flex flex-column position-relative w-100 h-100">
    <composable-product
      :product="productComposableSelected.composableProducts"
      @add-product="addProduct"
      @close-panel="hideComposableProductPanel"
      :value="composableProductValue"
      :hide-price="hidePrice"
      :command="command"
      @delete-product="deleteProduct"
      @update-product="updateProduct"
    ></composable-product>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ComposableProduct from "./ComposableProduct.vue";

export default {
  data() {
    return {
      cachedQty: 1,
    };
  },
  props: ["command"],
  components: {
    ComposableProduct,
  },
  computed: {
    ...mapState({
      hidePrice: (state) => state.hideComposableProductPrice,
      productComposableSelected: (state) => state.productComposableSelected,
      composableProductValue: (state) => state.composableProductValue,
    }),
  },
  mounted() {
    const number = parseFloat(this.$store.state.padAccumulator);
    this.cachedQty = isNaN(number) ? 1 : number;
  },
  methods: {
    ...mapActions(["hideComposableProductPanel"]),
    addProduct(product) {
      this.$store.commit("setQty", this.cachedQty);
      this.$store.dispatch("addProduct", product);
      // console.log(JSON.parse(JSON.stringify(products)))
    },
    deleteProduct(product) {
      this.$store.dispatch("deleteProduct", product);
    },
    updateProduct(product) {
      this.$store.dispatch("updateProduct", product);
    },
  },
};
</script>
