<template>
  <div id="app" class="app-content d-flex h-100">
    <transition 
      name="fade"
      mode="out-in"
    >
        <router-view/>
    </transition>
  </div>
</template>
<script>

export default {
  name: 'App'
};
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg-gray-200 {
    background-color: #EDF2F7 !important;
}

.no-menu.app-content {
  padding-top: 0px !important;
}

.no-menu.app-content main {
  overflow: auto !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
