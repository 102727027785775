<template>
  <div
    class="
      bg-white
      d-flex
      flex-md-column flex-nowrap
      p-3
      shadow
      flex-shrink-0
      overflow-hidden
      h-100
      card-menu
    "
  >
    <div
      class="
        d-flex
        flex-md-column flex-nowrap
        justify-content-start
        overflow-auto
        flex-grow-1
      "
    >
      <b-button
        variant="info"
        @click="hideComposableProductPanel"
        block
        pill
        size="lg"
        class="button-span-align"
      >
        <b-badge variant="light">{{ buttonBillData.totalProducts }}</b-badge>
        <span>{{ $t("title.see_order") }}</span>
        <span> {{ formatMoney(buttonBillData.total) }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatMoney } from "../../../js/utils.js";
export default {
  name: "ButtonBill",
  methods: {
    ...mapActions(["hideComposableProductPanel"]),
    formatMoney,
  },
  computed: {
    ...mapState({
      buttonBillData: (state) => state.buttonBillData,
    }),
  },
};
</script>

<style scoped>
.btn-pad {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

@media screen and (min-width: 768px) and (max-height: 760px) {
  .btn-pad {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
