import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
import money from "v-money";
import axios from 'axios';
import Vuex from 'vuex';
import store from './js/store/index.js';
import Vue2Filters from 'vue2-filters';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { mapState } from "vuex";


import { i18n, loadLanguageAsync } from "./i18n-setup";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

import { BootstrapVue, IconsPlugin, AlertPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./css/styles.css";

// Make BootstrapVue available throughout your project
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(AlertPlugin);
Vue.use(VueCookies);
Vue.use(money, { precision: 2 }); /*  register directive v-money and component <money>    */
Vue.use(Vuex);
Vue.use(Vue2Filters);

Vue.component('vtr-lw-spinbutton', () => import(/* webpackChunkName: "js/vtr-spinbutton" */ "./components/utils/LWSpinButton.vue"));
Vue.component('vtr-alert', () => import(/* webpackChunkName: "js/vtr-alert" */ './components/utils/VTRAlert.vue'));

new Vue({
  i18n,
  router,
  data: {
    API_BASE_URL: process.env.VUE_APP_API_MASTER_BASE_URL,
  },
  mounted() {
    if (this.$i18n.locale !== this.lang) {
      this.changeLang(this.lang);
    }
  },
  methods: {
    async changeLang(lang) {
      console.log("lang", lang)
      this.$store.commit('setLang', lang)
      let qr_code = this.$route.params.id;
      loadLanguageAsync(this.tenantApiUrlBase, lang, qr_code, this.commandData);
    },
  },
  computed: {
    ...mapState({
      hotel: (state) => state.hotel,
      lang: (state) => state.lang,
      commandData: (state) => state.command,
    }),
    table_qrcode() {
      return (typeof this.$route.params.id != 'undefined') ? this.$route.params.id : null;
    },
    tenantUrl() {
      return this.$store.state.tenantUrl;
    },
    tenantApiUrlBase() {
      return process.env.VUE_APP_LOCAL_TEST ? process.env.VUE_APP_LOCAL_TEST : this.$store.state.tenantApiUrlBase;
    }
  },
  watch: {
    lang(newLang) {
      //console.log(`it changed to ${newWidth} from ${oldWidth}`);
      this.changeLang(newLang);
    },
  },
  windowWidth(newWidth) {
    //console.log(`it changed to ${newWidth} from ${oldWidth}`);
    this.windowWidth = newWidth;
    this.detectMobile();
  },
  store: new Vuex.Store(store),
  render: h => h(App)
}).$mount("#app");

router.beforeEach((to, from, next) => {
  //app.$Progress.start();
  console.log(to + "=>>" + from);
  next();
});

router.afterEach((to, from) => {
  console.log(to + "=>>" + from);
});

