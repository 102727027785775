<template>
  <div>
    <div v-if="paidFor">
      <h4 class="text-center text-success">
        ¡Tu pago se ha completado correctamente!
      </h4>
    </div>

    <div v-else ref="paypal"></div>
  </div>
</template>
<script>
import axios from "axios";
import { i18n } from "./../../i18n-setup";
export default {
  name: "PaypalGateway",
  i18n,
  props: {
    order: {
      type: Object,
      default: () => null,
    },
    paymentPlatforms: null,
    currentQRCode: null,
  },
  data() {
    return {
      loaded: false,
      paidFor: false,
    };
  },

  mounted() {
    this.loadControls();
  },
  computed: {
    clientID() {
      let client_id = "";
      this.paymentPlatforms.map((platform) => {
        client_id = platform.client_id;
      });
      return client_id;
    },
    paymentPlatformId() {
      let payment_platform_id = "";
      this.paymentPlatforms.map((platform) => {
        payment_platform_id = platform.id;
      });
      return payment_platform_id;
    },
    currentPaypalCurrency() {
      if (
        typeof process.env.MIX_PAYPAL_CURRENCY == "undefined" ||
        process.env.MIX_PAYPAL_CURRENCY == ""
      ) {
        return "USD";
      } else {
        return process.env.MIX_PAYPAL_CURRENCY;
      }
    },
    paypalCurrency() {
      if (this.order.currency != null) {
        return this.order.currency.code;
      } else {
        return this.currentPaypalCurrency;
      }
    },
    orderItems() {
      if (this.order != null) {
        return this.order.items;
      }
      return [];
    },

    tipPaypal() {
      if (this.order != null) {
        return this.order.tipPaypal;
      }
      return null;
    },
    products() {
      let products = [];

      products = this.orderItems.map((ord, ind) => {
        return {
          //https://developer.paypal.com/api/orders/v2/#definition-item

          name: ord.purchasable.name.slice(0, 127), //Minimum length: 1, Maximum length: 127.
          description: "Producto " + (ind + 1), //Maximum length: 127
          category: "PHYSICAL_GOODS",
          unit_amount: {
            currency_code: this.paypalCurrency,
            value: ord.amount / 100,
          },
          quantity: ord.quantity,
          //tax: "0"
        };
      });
      if (this.tipPaypal != null && this.tipPaypal.amount) {
        console.warn(this.tipPaypal)
        //console.warn(this.tipPaypal.purchasable)
        //console.warn(this.tipPaypal.purchasable?.name)
        let tipPaypal = {
          //https://developer.paypal.com/api/orders/v2/#definition-item

          name: this.tipPaypal.purchasable.name.slice(0, 127), //Minimum length: 1, Maximum length: 127.
          description: this.tipPaypal.purchasable.name.slice(0, 127), //Maximum length: 127
          category: "PHYSICAL_GOODS",
          unit_amount: {
            currency_code: this.paypalCurrency,
            value: this.tipPaypal.amount / 100,
          },
          quantity: this.tipPaypal.quantity,
          //tax: "0"
        };

        products.push(tipPaypal);
      }

      return products;
    },
  },
  methods: {
    loadControls() {
      //if(typeof(this.clientID) != 'undefined') {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${this.clientID}&currency=${this.paypalCurrency}`;
      script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);
      //}
    },
    setLoaded() {
      this.loaded = true;
      var vm = this;
      const { persons } = this.order;
      const payer = (persons.first_name && persons.last_name && persons.email) ? {
        email_address: persons.email,
        name: {
          given_name: persons.first_name,
          surname: persons.last_name,
        },
        /*phone: {
          phone_number: {
            national_number: "5551234567"
          },
        },*/
        address: {
          country_code: "MX",
          postal_code: persons.postal_code,
        },
      }: null;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            console.log("createOrder", data);

            return actions.order.create({
              application_context: {
                shipping_preference: "NO_SHIPPING",
                //user_action: "PAY_NOW",
              },
              payer: payer,
              purchase_units: [
                {
                  //reference_id:"REFID-000-1001",
                  //description: "Paypal order from Solar tun Site",
                  amount: {
                    currency_code: this.paypalCurrency,
                    value: this.order.amount_total, //Final o gross amount to pay
                    breakdown: {
                      //Purchase details
                      item_total: {
                        //Initial total
                        currency_code: this.paypalCurrency,
                        value: this.order.item_total,
                      },
                      shipping: {
                        currency_code: this.paypalCurrency,
                        value:
                          this.order.shipping != null
                            ? this.order.shipping.shipping_fee
                            : 0, //Is added to item_total
                      },
                      tax_total: {
                        currency_code: this.paypalCurrency,
                        value: this.order.tax_total, //Is added to item_total
                      },
                      discount: {
                        currency_code: this.paypalCurrency,
                        value: this.order.discount, //Is substracted to item_total
                      },
                      shipping_discount: {
                        currency_code: this.paypalCurrency,
                        value:
                          this.order.shipping != null
                            ? this.order.shipping.shipping_discount
                            : 0, //Is substracted to item_total
                      },
                    },
                  },
                  items: this.products, //Importante seguir el formato
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            // This function captures the funds from the transaction.
            const order = await actions.order.capture();
            console.log("order", order);
            this.paidFor = true;
            this.capturePayment(data, this.order.id, order.status);
          },
          onCancel: function (data) {
            let title = i18n.t("info.info_the_pay");
            let text = i18n.t("messages.cancel_pay");
            console.log("onCancel", data);
            vm.$swal({
              title: title,
              text: text,
              icon: "info",
              timer: 5000,
              showConfirmButton: false,
            });
          },
          onError: (err) => {
            // For example, redirect to a specific error page
            //window.location.href = "/your-error-page-here";
            let title = i18n.t("info.info_the_pay");
            this.$swal({
              title: title,
              text: err,
              icon: "error",
              timer: 5000,
            });
          },
        })
        .render(this.$refs.paypal);
    },
    async capturePayment(paypal, orderId, status) {
      try {
        let title = i18n.t("info.paypal");
        let text = i18n.t("messages.wait_save_pay");
        this.$swal({
          timer: 30000,
          title: title,
          html: text,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        const params = {
          payment_platform_id: this.paymentPlatformId,
          table_account_id: orderId,
          order_id: paypal.orderID,
          status: status,
        };

        console.log("paypal", paypal);
        console.log("params", params);

        const response = await axios.post(
          this.$root.tenantApiUrlBase +
            "/api-v2/payment-platforms-table-account/" +
            this.currentQRCode +
            "/save",
          params
        );

        console.log("response", response);

        this.$emit("on-successful-payment", true);
      } catch (error) {
        let title = i18n.t("info.info_the_pay");
        this.$swal({
          title: title,
          timer: 5000,
          text: error,
          icon: "error",
        });
        this.$emit("on-successful-payment", false);
      }
    },
  },
};
</script>
