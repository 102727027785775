<template>
  <b-list-group>
    <b-list-group-item
      v-for="(product, index) in purchases"
      :key="product.id"
      button
      class="
        border-0
        rounded-0
        d-flex
        flex-column
        align-items-stretch
        pl-0
        pr-0
        py-2
      "
      @click.stop="selectProduct(product, account)"
      :active="productSelected && product.id === productSelected.id"
    >
      <!--class="border-0 rounded-0 d-flex justify-content-between"-->
      <div class="d-flex w-100 justify-content-end px-2">
        <span
          :class="
            (product.purchasable_type === 'product' && product.amount > 0) ||
            product.purchasable_type === 'available_package'
              ? 'flex-grow-1 font-weight-bold  font-size-purchase'
              : 'flex-grow-1'
          "
        >
          <i
            class="fa fa-check-circle text-success"
            v-if="product.confirmed"
          ></i>
          {{ keepCount ? `# ${index + 1}) ` : ""
          }}{{ product.purchasable.name }}
        </span>
        <span
          v-if="product.purchasable_type !== 'composable_product_item'"
          class="qty-style text-right  font-size-purchase"
        >
          {{
            product.amount == 0
              ? " x " + product.quantity
              : formatMoney(product.amount / 100) + " x " + product.quantity
          }}</span
        >
        <div
          v-if="product.purchasable_type !== 'composable_product_item'"
          class="amount-style text-right  font-size-purchase"
        >
          <span class="qty-style text-right"
            >{{
              product.amount == 0
                ? ""
                : formatMoney((product.amount / 100) * product.quantity)
            }}
          </span>
        </div>
      </div>
      <div
        class="d-flex w-100 justify-content-between font-size-purchase"
        v-if="
          (product.comments != null && product.comments != '') ||
          product.special_added.length > 0
        "
      >
        <span class="pl-4 text-light-gray">
          <i class="fa fa-comments-alt mr-2"></i>
          {{
            specialLabel(product.special_added) +
            (product.comments != "" && product.special_added.length > 0
              ? ", " + product.comments
              : product.comments)
          }}
        </span>
      </div>
      <div
        v-if="
          product.purchases.length > 0 &&
          (product.purchasable_type === 'product' ||
            product.purchasable_type === 'composable_product_item')
        "
        class="pl-3"
      >
        <b-collapse :id="collapseId(product)" class="mt-2">
          <purchase-list
            class=""
            :account="account"
            :purchases="product.purchases"
            :level="level + 1"
            :keep-count="
              product.purchasable_type === 'composable_product_item' &&
              product.purchasable.keep_count
            "
          ></purchase-list>
          <div
            v-if="
              product.purchases.length > 0 &&
              product.purchasable_type === 'product'
            "
            class="
              list-group-item
              border-0
              rounded-0
              d-flex
              flex-column
              pl-3
              py-1
            "
          >
            <b-button
              @click.stop="selectProductwithComponentes(product, account)"
              variant="primary"
              :title="
                product.confirmed
                  ? $t('title.see_product')
                  : $t('title.edit_product')
              "
              class=""
            >
              <span class="text-center">{{
                product.confirmed
                  ? $t("title.see_product")
                  : $t("title.edit_product")
              }}</span>
            </b-button>
          </div>
        </b-collapse>
      </div>

      <div v-if="product.extras && product.extras.length > 0" class="pl-3">
        <b-collapse :id="collapseId(product)" class="mt-2">
          <span class="">{{ $tc("title.extras",2) }}:</span>
          <purchase-list
            class=""
            :account="account"
            :purchases="product.extras"
            :level="level + 1"
          ></purchase-list>
        </b-collapse>
      </div>

      <b-collapse :id="collapseId(product)" class="mt-2">
        <b-list-group
          v-if="product.purchasable_type === 'available_package'"
          class="w-100"
        >
          <b-list-group-item
            v-for="packageProduct in product.purchases"
            :key="`purchase_product_${product.id}_${packageProduct.id}`"
            class="border-0 rounded-0 d-flex flex-column pl-3 py-1"
            @click.stop="selectProductPackage(packageProduct, product, account)"
            :active="
              productSelected && packageProduct.id === productSelected.id
            "
            :class="{
              'bg-primary':
                productSelected && product.id === productSelected.id,
            }"
          >
            <div class="d-flex justify-content-between">
              <div>
                <i
                  class="fa fa-check-circle text-success"
                  v-if="packageProduct.confirmed"
                ></i>
                {{ packageProduct.purchasable.product.name }}
              </div>
              <div>x {{ packageProduct.purchasable.required_quantity }}</div>
            </div>
            <div
              class="d-flex w-100 justify-content-between"
              v-if="
                (packageProduct.comments != null &&
                  packageProduct.comments != '') ||
                packageProduct.special_added.length > 0
              "
            >
              <span class="pl-4 text-light-gray">
                <i class="fa fa-comments-alt mr-2"></i>
                {{
                  specialLabel(packageProduct.special_added) +
                  (packageProduct.comments != "" &&
                  packageProduct.special_added.length > 0
                    ? ", " + packageProduct.comments
                    : packageProduct.comments)
                }}
              </span>
            </div>
          </b-list-group-item>
        </b-list-group>
        <div
          v-if="
            (product.purchases.length > 0 &&
              product.purchasable_type === 'available_package') ||
            (product.extras.length > 0 && product.purchases.length == 0)
          "
          class="
            list-group-item
            border-0
            rounded-0
            d-flex
            flex-column
            pl-3
            py-1
          "
        >
          <b-button
            @click="selectProductwithComponentes(product, account)"
            variant="primary"
            :title="
              product.confirmed
                ? $t('title.see_product')
                : $t('title.edit_product')
            "
          >
            <span class="text-center">{{
              product.confirmed
                ? $t("title.see_product")
                : $t("title.edit_product")
            }}</span>
          </b-button>
        </div>
      </b-collapse>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatMoney } from "../../js/utils.js";

export default {
  name: "purchase-list",
  props: {
    purchases: {
      required: true,
    },
    hidePrice: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 1,
    },
    keepCount: {
      type: Boolean,
      default: false,
    },
    account: {
      required: true,
    },
  },
  components: {},
  computed: {
    ...mapState({
      productSelected: (state) => state.productSelected,
    }),
  },
  methods: {
    ...mapActions([
      "showProductPanel",
      "showEditProductPanelPackage",
      "showEditProductPanel",
    ]),

    formatMoney,
    collapseId(product) {
      return "Collapse-" + product.id;
    },
    selectProduct(product, account) {
      if (
        (product.purchases.length > 0 &&
          (product.purchasable_type === "product" ||
            product.purchasable_type === "composable_product_item" ||
            product.purchasable_type === "available_package")) ||
        product.extras.length > 0
      ) {
        let name = this.collapseId(product);
        this.$root.$emit("bv::toggle::collapse", name);
      } else if (product.purchasable_type === "available_package") {
        this.showProductPanelPackage([product, undefined, this.level, account]);
      } else {
        this.showEditProductPanel([product, undefined, this.level, account]);
      }
    },
    selectProductPackage(product, parent, account) {
      console.log("selectProductPackage", product);

      this.showEditProductPanelPackage([product, parent, this.level, account]);
    },
    selectProductwithComponentes(product, account) {
      if (product.purchasable_type !== "composable_product_item") {
        if (this.level > 1) {
          this.$store.commit("setHideComposableProductPrice", true);
          this.showEditProductPanel([product, undefined, this.level, account]);
        } else {
          this.$store.commit("setHideComposableProductPrice", false);
        }
        this.showEditProductPanel([product, undefined, this.level, account]);
      } else if (product.purchasable_type === "available_package") {
        this.showEditProductPanelPackage([
          product,
          undefined,
          this.level,
          account,
        ]);
      }
    },
    specialLabel(specials) {
      return specials.reduce((c, e) => {
        return c != "" ? c + ", " + e.name : e.name;
      }, "");
    },
  },
};
</script>

<style scoped>
.amount-style {
  width: 65px;
}

.pencil-style {
  width: 20px;
}

.qty-style {
  width: 75px;
}
</style>
