<template>
  <div class="product-col clickeable" @click="packageSelected(product)">
    <figure   class="
          card
          card-menu
          clickeable
          card-product
          pusheable
          h-100
          justify-content-between
        ">
      <div class="product-image">
        <b-img
          class="u-image"
          :src="getFullImagePath(product)"
          rounded="circle"
          alt=""
        ></b-img>
      </div>
      <h4 class="u-text product-title">{{ product.name }}</h4>
      <h6 class="u-text product-price">
        {{ getPricePackage(product) | currency }} {{ "MXN" }}
      </h6>
    </figure>
    <package-modal
      @savePackage="savePackage"
      @cancel="cancel"
      :name="product.name"
      :slots="slots"
      :slot-positions="slotPositions"
      :show="show"
      :is-saving="isAdding"
      :isMobile="isMobile"
    ></package-modal>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import PackageModal from "../../ProductPackage/PackageModal.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "MenuPackage",
  components: { PackageModal },
  props: {
    product: {
      require: true,
      default: null,
    },
    quantity: {
      require: true,
      default: 1,
    },
    command: {
      require: true,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      isAdding: false,
    };
  },
  computed: {
    ...mapGetters(["distributionChannel"]),
    ...mapState({
      accountSelected: (state) => state.command.accountSelected,
      isMobile: (state) => state.isMobile,
      tenantUrl: (state) => state.tenantUrl,
    }),
    slots() {
      return groupBy(this.product.available_package_items, (item) => item.slot);
    },
    slotPositions() {
      return Object.keys(this.slots).sort();
    },
    shouldSelectProducts() {
      return this.slotPositions.reduce((acc, position) => {
        return this.slots[position].length > 1 || acc;
      }, false);
    },
    price() {
      if (this.distributionChannel) {
        const channelPrice = this.product.prices.find(
          (p) => p.distribution_channel_id == this.distributionChannel.id
        );
        if (channelPrice) {
          return channelPrice;
        }
      }

      return this.product.current_price;
    },
  },
  methods: {
    getFullImagePath(product) {
      return this.tenantUrl != null ? this.tenantUrl + product.image_path : "";
    },
    getPricePackage(product) {
      let price = 0;
      if (product.prices.length > 0) {
        price = product.prices.filter((p) => p.distribution_channel_id == null);
      } else {
        price = product.current_price.amount / 100;
      }

      return price;
    },
    packageSelected(product) {
      if (this.shouldSelectProducts) {
        this.show = true;
      } else {
        this.$store.dispatch("showProductPanelPackage", product);
        //this.savePackage(this.slotPositions.map(p => this.slots[p][0]));
      }
    },
    async savePackage(slots) {
      let data = {
        available_package_id: this.product.id,
        price_id: this.price.id,
        items: slots.map((s) => s.id),
        quantity: this.quantity,
        open_table_id: this.command.open_table.id,
        account: this.accountSelected,
        table_qrcode: this.$root.table_qrcode,
      };
      this.isAdding = true;
      try {
        let res = await this.$http.post(
          this.$root.tenantApiUrlBase +
            `/api-v2/table-reservations/${this.$root.table_qrcode}/purchases/addPackage`,
          data
        );
        this.$emit("setQty", 1);
        this.show = false;

        console.log(res);
      } catch (e) {
        let aMessage = "Error: " + e.message;
        if (e.response && e.response.data && e.response.data.error) {
          aMessage = "Error: " + e.response.data.message;
        }
        if (e.response && e.response.status == 403) {
          aMessage = "Acción no permitida";
        }
        console.log(aMessage);
        this.isAdding = false;
      }

      this.isAdding = false;
    },
    cancel() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.product-col {
  position: relative;
  flex: 1;
  max-width: auto !important;
  padding: auto !important;
}

.product-image {
  text-transform: none;
  font-style: italic;
  margin: 32px 2px 0;
}

img.u-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.product-title {
  text-transform: none;
  font-style: italic;
  margin: 32px 2px 0;
}

.product-price {
  color: #f12c0e !important;
  font-weight: 700;
  margin: 20px 0 0;
}

.u-text {
  word-wrap: break-word;
  position: relative;
}
</style>