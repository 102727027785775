import { i18n } from "./../../i18n-setup";
import axios from "axios";

function extractSelectionValue(product) {
  let selection = {}

  product.purchasable.composable_product_items.forEach((composableProduct) => {
    const purchase = product.purchases.find((p) => p.purchasable_id == composableProduct.id && p.purchasable_type == 'composable_product_item');

    if (purchase) {
      selection[purchase.purchasable_id] = purchase.purchases.map(composablePurchase => {
        let product = {
          id: composablePurchase.purchasable.id,
          name: composablePurchase.purchasable.name,
          image_path: composablePurchase.purchasable.image_path,
          price: composablePurchase.amount
        }

        const innerSelections = extractSelectionValue(composablePurchase);

        if (Object.keys(innerSelections).length > 0) {
          product.components = innerSelections;
        }

        product.extras = extractExtrasValue(composablePurchase)


        return {
          id: composablePurchase.id,
          product,
          qty: composablePurchase.quantity
        }
      })

      const currentComposableProduct = product.purchasable.composable_product_items.find(item => item.id == purchase.purchasable_id);
      const size = selection[purchase.purchasable_id].length;
      const expectedSize = currentComposableProduct.maximum_number_of_product_types;
      if (currentComposableProduct && size < expectedSize) {
        for (let i = size; i < expectedSize; i++) {
          selection[purchase.purchasable_id].push({
            product: null,
            qty: 0
          })
        }
      }
    } else {
      selection[composableProduct.id] = []

      for (let i = 0; i < composableProduct.maximum_number_of_product_types; i++) {
        selection[composableProduct.id].push({
          product: null,
          qty: 0
        })
      }
    }
  })

  /* product.purchases.forEach((purchase) => {
    if (purchase.purchasable_type === 'composable_product_item') {
      selection[purchase.purchasable_id] = purchase.purchases.map(composablePurchase => {
        let product = {
          id: composablePurchase.purchasable.id,
          name: composablePurchase.purchasable.name,
          image_path: composablePurchase.purchasable.image_path,
          price: composablePurchase.amount
        }

        const innerSelections = extractSelectionValue(composablePurchase);

        if (Object.keys(innerSelections).length > 0) {
          product.components = innerSelections;
        }

        product.extras = extractExtrasValue(composablePurchase)

        
        return {
          id: composablePurchase.id,
          product,
          qty: composablePurchase.quantity
        }
      })

      const currentComposableProduct = product.purchasable.composable_product_items.find(item => item.id == purchase.purchasable_id);
      const size = selection[purchase.purchasable_id].length;
      const expectedSize = currentComposableProduct.maximum_number_of_product_types;
      if (currentComposableProduct && size < expectedSize) {
        for (let i = size; i < expectedSize; i++) {
          selection[purchase.purchasable_id].push({
            product: null,
            qty: 0
          })
        }
      }
    }
  }) */

  return selection
}

function extractExtrasValue(product) {
  return product.extras.map(extra => {
    return {
      id: extra.id,
      product: {
        id: extra.purchasable.id,
        name: extra.purchasable.name,
        image_path: extra.purchasable.image_path,
        price: extra.amount
      },
      qty: extra.quantity
    }
  })
}
export default {
  waiterActions({ state, dispatch, commit }) {
    switch (state.waiterAction) {
      case 'nameUpdatedOpenTable':
        state.command.open_table.name = state.waiterActionData.name;
        dispatch('tableName');
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'waiterUpdated':
        console.log("waiterActionData", state.waiterActionData);
        dispatch('waiterUpdated');
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'purchaseNew':
        dispatch('purchaseNew');
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'purchaseUpdated':
        dispatch('purchaseUpdated');
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'purchasePackageUpdated':
        dispatch('purchasePackageUpdated');
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'purchasesConfirmed':
        console.log("waiterActionData", state.waiterActionData);
        dispatch('purchasesConfirmed');
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'purchaseDeleted':
        dispatch('purchaseDeleted');
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'accountNewAdded':
        state.command.open_table.table_accounts.push(state.waiterActionData[0]);
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'accountsUpdated':
        commit('setCommand', null)
        commit('setCommand', state.waiterActionData[0])
        //state.command.open_table.table_accounts.push(state.waiterActionData[0]);
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'accountClosed':
        console.log("waiterActionData", state.waiterActionData)
        //commit('setCommand', null)
        //commit('setCommand', state.waiterActionData[0])
        //state.command.open_table.table_accounts.push(state.waiterActionData[0]);
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      case 'closeOpenTable':
        state.command.open_table.close = state.waiterActionData[0];
        dispatch('isTableBookingClosed');
        commit("setWaiterAction", '');
        commit("setWaiterActionData", '');
        break;
      default:
        break;
    }
  },
  purchaseNew({ state }) {
    state.command.open_table.table_accounts.map((accounts) => {
      if (accounts.account == state.waiterActionData.account) {
        accounts.purchases.push(state.waiterActionData.purchases)
      }
    })
  },
  purchaseUpdated({ state }) {
    console.log("waiterActionData", state.waiterActionData);
    state.command.open_table.table_accounts.filter(function (accounts) {
      if (accounts.account == state.waiterActionData.account) {
        let index_update = null
        accounts.purchases.filter(function (purchase, index) {
          if (purchase.id == state.waiterActionData.purchase.id) {
            return index_update = index
          }
        })
        if (index_update != null) {
          switch (state.waiterActionData.purchase.action_purchase) {
            case 'purchaseUpdated':

              accounts.purchases.splice(index_update, 1, state.waiterActionData.purchase.value.purchases)
              break;
            case 'purchaseQuantityUpdated':
              accounts.purchases[index_update].quantity = state.waiterActionData.purchase.value;
              break;
            case 'purchaseAmountUpdated':
              accounts.purchases[index_update].amount = state.waiterActionData.purchase.value;
              break;
            case 'purchaseCommentsUpdated':
              accounts.purchases[index_update].comments = state.waiterActionData.purchase.value;
              break;
            case 'purchaseSpecialProduct':
              console.log("state.waiterActionData", state.waiterActionData)
              accounts.purchases[index_update].special_added = state.waiterActionData.purchase.value
              break;

            default:
              break;
          }

        }
      }
    })
  },


  purchasePackageUpdated({ state }) {
    console.log("waiterActionData", state.waiterActionData);
    state.command.open_table.table_accounts.filter(function (accounts) {
      if (accounts.account == state.waiterActionData.account) {
        accounts.purchases.filter(function (purchase) {
          if (purchase.purchasable_type == 'available_package') {
            purchase.purchases.filter(function (purchase_package) {
              if (purchase_package.id == state.waiterActionData.purchase.id) {

                switch (state.waiterActionData.purchase.action_purchase) {
                  case 'purchaseSpecialProduct':
                    purchase_package.special_added = state.waiterActionData.purchase.value
                    break;
                  case 'purchaseCommentsUpdated':
                    purchase_package.comments = state.waiterActionData.purchase.value;
                    break;
                  default:
                    break;
                }


              }
            })
          }

        })
        /*if (index_update != null) {
          switch (state.waiterActionData.purchase.action_purchase) {
            case 'purchaseUpdated':

              accounts.purchases.splice(index_update, 1, state.waiterActionData.purchase.value.purchases)
              break;
            case 'purchaseQuantityUpdated':
              accounts.purchases[index_update].quantity = state.waiterActionData.purchase.value;
              break;
            case 'purchaseAmountUpdated':
              accounts.purchases[index_update].amount = state.waiterActionData.purchase.value;
              break;
            case 'purchaseCommentsUpdated':
              accounts.purchases[index_update].comments = state.waiterActionData.purchase.value;
              break;
            case 'purchaseSpecialProduct':
              console.log("state.waiterActionData", state.waiterActionData)
              accounts.purchases[index_update].special_added = state.waiterActionData.purchase.value
              break;

            default:
              break;
          }

        }*/
      }
    })
  },
  purchasesConfirmed({ state }) {
    state.command.open_table.table_accounts.filter(function (accounts, index_account) {
      if (accounts.account == state.waiterActionData[index_account].account) {
        let index_account_data = index_account;
        accounts.purchases.filter(function (purchase, index) {
          if (purchase.id == state.waiterActionData[index_account_data].purchases[index].id) {
            accounts.purchases[index].confirmed = state.waiterActionData[index_account_data].purchases[index].confirmed;
            accounts.purchases[index].pre_confirmed = state.waiterActionData[index_account_data].purchases[index].pre_confirmed;
          }
        })
      }
    })
  },
  purchaseDeleted({ state }) {
    state.command.open_table.table_accounts.filter(function (item) {
      let index_delete = null;
      item.purchases.filter(function (purchase, index) {
        if (purchase.id == state.waiterActionData.purchase_id && state.waiterActionData.response) {
          return index_delete = index
        }
      })
      index_delete != null ? item.purchases.splice(index_delete, 1) : index_delete
    })

  },


  isTableBookingClosed({ state, commit }) {
    let closeOpenTable =
      state.command.open_table.close != null
        ? true
        : false;
    commit('setCloseOpenTable', closeOpenTable);
  },

  tableName({ state, commit }) {
    let tableName = state.command.open_table.name != null
      ? state.command.open_table.name
      : state.table.name;
    commit('setTableName', tableName);
  },

  client({ state, commit }) {
    let client = "";
    if (state.command.open_table.table_accounts != null) {

      let clientObj = state.command.open_table.table_accounts[0].client;
      client = clientObj != null ? clientObj.name + " " + clientObj.last_name : i18n.t('title.invited');
    }
    commit('setClient', client);
  },

  waiterUpdated({ state }) {
    state.command.open_table.waiter.id = state.waiterActionData[0].id;
    state.command.open_table.waiter.name = state.waiterActionData[0].name;
  },
  buttonBillData({ state, dispatch, commit }) {
    console.log("table_accounts",state.command.open_table.table_accounts)
    console.log("accountSelectedCommand",state.command.accountSelected)
    state.command.open_table.table_accounts.filter((account) => {
      if (state.command.accountSelected == account.account) {
        dispatch("total", account.purchases);
        commit("setTotalProducts", account.purchases.length);
      }
    });
    commit('setButtonBillDataTotal', state.total)
    commit('setButtonBillDataTotalProducts', state.totalProducts)
  },

  loadCommand({ commit, getters }) {
    // let accounts = getters.commandData.table_accounts
    let accounts = getters.commandData.accounts;
    commit('changeAccountSelected', [parseInt(accounts[0].account)])
    commit('setClientData', {
      clientPhoneId: accounts[0].client_phone_id,
      clientAddressId: accounts[0].client_address_id
    })
    commit('refreshAccount', [accounts])
  },
  loadCommands({ commit, state, getters }) {
    // let accounts = getters.commandData.table_accounts
    let accounts = getters.commandData.accounts;
    accounts.forEach(a => {
      commit('changeAccountSelected', [parseInt(a.account)])
      if (a.account > 1)
        commit('addSplitAcount');
      let products = a.table_products
      products.forEach(p => {
        if (p.confirmed == false && state.command.status == false) {
          commit('changeCommandStatus', [true]);
        }
        let split = a.account;
        let clone = state.command.productsSelected.find(e => {
          return e.account_id == split && e.product.id == p.product.id;
        });
        let index = 0
        if (typeof clone !== 'undefined') {
          index = clone.product.quantity;
          commit('addProduct', [p.product])
          // commit('addQuantity',[p.product,parseInt(a.account)])
        }
        else {
          commit('addProduct', [p.product])
        }
        p.special_added.forEach(s => {
          commit('addSpecialProduct', [p.product, s, index, parseInt(a.account)])
        })
      });
    });
    commit('changeAccountSelected', [1]);
  },
  loadId({ commit }, value) {
    commit('loadId', value);
  },
  loadState({ commit }, value) {
    commit('loadState', value);
  },

  changeAccountSelected({ commit, dispatch, getters }, accountSelected) {
    commit('changeAccountSelected', accountSelected)
    commit('setAccountSelected', accountSelected)
    dispatch("buttonBillData");
    let clientObj = getters.currentAccount.client;
    let client = clientObj != null ? clientObj.name + " " + clientObj.last_name : i18n.t('title.invited');
    commit('setClient', client)
  },
  changeStatus({ commit, state }) {
    let data = state.command;
    axios.post('/command', data)
      .then(res => {
        commit('refreshAccount', [res.data.table]);
        commit('changeCommandStatus', [false]);
        if (res.data.products_without_area) commit('alertNonPrinted', [res.data.products_without_area]);
      }).catch(e => {
        let message = e.message;

        if (e.response && e.response.data.message) {
          message = e.response.data.message;
        }

        let aMessage = i18n.t('messages.error_print') + ": " + message;
        commit('alertError', [aMessage]);
      });
  },
  total({ commit }, productOptions) {

    let total = 0
    const extras = productOptions.reduce(function reducer(acc, purchase) {
      return (
        acc +
        purchase.extras.reduce(
          (acc, extra) => acc + parseInt(extra.amount),
          0
        ) +
        purchase.purchases.reduce(reducer, 0)
      );
    }, 0);

    const products = productOptions.reduce(
      (acc, purchase) => acc + (purchase.amount / 100) * purchase.quantity,
      0
    );
    total = products + extras / 100;
    commit('setTotal', total);
    // return total;
  },
  onResize() {
    this.windowWidth = window.innerWidth;
  },
  categorySelected({ state, commit }, category) {

    commit('setCategoryFather', state.categoryFather.push(state.category));
    commit('setCategory', category);

  },
  returnCategory({ state, commit }) {
    commit('setCategory', state.categoryFather[state.categoryFather.length - 1]);
    commit('setCategoryFather', state.categoryFather.pop());
  },
  getfullImagePath({ state }, image_path) {
    state.imagePath = state.tenantUrl != null ? state.tenantUrl + "" + image_path : '';

  },
  async refresh({ commit, state }, command) {
    state.isLoadingCommand = true;
    axios.get(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/accounts-refresh/${command.id}`).then(r => {
      commit('refreshAccount', [r.data])
      state.isLoadingCommand = false;
    }).catch(e => {
      console.log("index.js:refresh:e", e)
      state.isLoadingCommand = false;
      let aMessage = i18n.t('messages.error_reload_accounts');
      commit('setAlerts', [aMessage]);
    });
  },
  addProduct({ state, commit, dispatch, getters }, data) {
    commit('setProcessing', true);
    let product = data.product;
    commit('setQty', 1);
    commit('addProductBeingAdded', product);
    console.warn("It's that all? :",state.automatic_preconfirm === true? "yes!" : "Give me a couple of minutes");
      if(state.automatic_preconfirm)
      {
          data.command_id = getters.commandData.id;
      }
    axios.post(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/purchases/add`, data)
      .then(r => {
        var accounts = r.data.accounts;
        var aMessage = i18n.t('messages.saved_product');
        console.log("index.js:addProduct:r.data:", r.data)
        console.log("index:addProduct:accounts:", accounts);
        commit('setAlerts', [])
        commit('setAlerts', [{
          title: 'Comanda',
          content: aMessage,
          type: 'success'
        }]);
        commit('changeCommandStatus', [true]);
        //commit('refreshAccount', [r.data.accounts]);
        commit('removeProductBeingAdded', product);
        commit('setProcessing', false);
        dispatch('hideProductPanel');
        dispatch('hideComposableProductPanel');
        commit("showProducts");

      }).catch(e => {
        let aMessage = "Error: " + e.message;
        if (e.response && e.response.data && e.response.data.error) {
          aMessage = "Error: " + e.response.data.message;
        }
        if (e.response && e.response.status == 403) {
          //aMessage = i18n.t('messages.not_add_prod_allowed');
        }
        aMessage += i18n.t('messages.not_saved_product')
        commit('setAlerts', [])
        commit('setAlerts', [{
          title: 'Comanda',
          content: aMessage,
          type: 'danger'
        }]);
        commit('removeProductBeingAdded', product);
        commit('setProcessing', false);
        dispatch('hideComposableProductPanel');
        dispatch('hideProductPanel');
        dispatch('refresh', data.command);
        commit("showProducts");
      });
  },

  addPackage({state,commit,dispatch}, data){
    commit('setProcessing', true);
    let product = data.product;
    commit('addProductBeingAdded', product);
    axios.post(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/purchases/addPackage`, data)
    .then(r => {
      var accounts = r.data.accounts;
      var aMessage = i18n.t('messages.saved_package');
      console.log("index.js:addProduct:r.data:", r.data)
      console.log("index:addProduct:accounts:", accounts);
      commit('setAlerts', [])
      commit('setAlerts', [{
        title: 'Comanda',
        content: aMessage,
        type: 'success'
      }]);
      commit('changeCommandStatus', [true]);
      //commit('refreshAccount', [r.data.accounts]);
      commit('removeProductBeingAdded', product);
      commit('setProcessing', false);
      dispatch('hideProductPanel');
      dispatch('hideComposableProductPanel');
      commit("showProducts");

    }).catch(e => {
      let aMessage = "Error: " + e.message;
      if (e.response && e.response.data && e.response.data.error) {
        aMessage = "Error: " + e.response.data.message;
      }
      if (e.response && e.response.status == 403) {
        //aMessage = i18n.t('messages.not_add_prod_allowed');
      }
      aMessage += i18n.t('messages.not_saved_package')
      commit('setAlerts', [])
      commit('setAlerts', [{
        title: 'Comanda',
        content: aMessage,
        type: 'danger'
      }]);
      commit('removeProductBeingAdded', product);
      commit('setProcessing', false);
      dispatch('hideComposableProductPanel');
      dispatch('hideProductPanel');
      dispatch('refresh', data.command);
      commit("showProducts");
    });
  },

  addSpecialProduct({ commit, state }, [product, special]) {
    let data = {
      product: product,
      special: special,
      command: state.command
    };
    axios.post('/addSpecialProduct', data)
      .then(r => {
        commit('refreshAccount', [r.data])
      })
      .catch(e => {
        let aMessage = "Error: " + e.message;
        commit('alertError', [aMessage]);
      })
  },
  updateProduct({ state, commit, dispatch }, data) {
    console.log("index.js:updateProduct:state:", data)
    commit('setProcessing', true);
    axios.put(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/purchases/${data.product.id}`, data)
      .then(res => {
        console.log("updateProductr",res)
        let aMessage = i18n.t('messages.updated_product')
        commit('setAlerts', [])
        commit('setAlerts', [{
          title: 'Comanda',
          content: aMessage,
          type: 'success'
        }]);
        dispatch('hideComposableProductPanel');
        commit('setProcessing', false);
      })
      .catch(e => {
        let error = e.message;
        let aMessage = i18n.t('messages.not_updated_product')
        commit('setAlerts', [])
        commit('setAlerts', [{
          title: 'Comanda',
          content: aMessage + error,
          type: 'danger'
        }]);
        commit('setProcessing', false);
      })
  },

  async updateProductSelected({ commit, state, dispatch }, [property, value, product]) {
    try {
      console.log("property", property)
      let { data } = await axios.put(state.tenantApiUrlBase + `/table-reservations/${state.currentQRCode}/purchases/${product.id}`, {
        [property]: value
      });
      var message = data.message;
      commit('setAlerts', [])
      commit('setAlerts', [{
        title: 'Comanda',
        content: message,
        type: 'success'
      }]);
      dispatch('hideComposableProductPanel');
      commit('setProcessing', false);

    } catch (error) {
      let error_catch = error.message;
      let aMessage = i18n.t('messages.not_updated_product')
      commit('setAlerts', [])
      commit('setAlerts', [{
        title: 'Comanda',
        content: aMessage + error_catch,
        type: 'danger'
      }]);
      commit('setProcessing', false);
    }
  },
  deleteProduct({ state, commit, dispatch }, data) {
    console.log("index.js:deleteProduct:data:", data)
    commit('setProcessingDeleted', true);
    axios.post(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/purchases-delete`, data)
      .then(res => {
        var message = res.data.message;
        commit('setAlerts', [])
        commit('setAlerts', [{
          title: 'Comanda',
          content: message,
          type: 'success'
        }]);
        commit('setProcessingDeleted', false);
        dispatch('hideComposableProductPanel');
        dispatch('hideProductPanel');

      })
      .catch(e => {
        let error = e.message;
        commit('setAlerts', [])
        commit('setAlerts', [{
          title: 'Comanda',
          content: 'No se pudo eliminar el producto: ' + error,
          type: 'danger'
        }]);
        commit('setProcessingDeleted', false);
      })
  },
  removeSpecialProduct({ commit, state }, [product, special]) {
    let data = {
      product: product,
      special: special,
      command: state.command
    }
    axios.post('/removeSpecialProduct', data)
      .then(r => {
        console.log("removeSpecialProduct:", r.data)
        commit('refreshAccount', [r.data])
      })
      .catch(e => {
        let aMessage = "Error: " + e.message;
        commit('alertError', [aMessage]);
      })
  },
  getPrice(product) {
    // //TODO: Use currentPrice property
    let channelPrices;
    if (this.distributionChannel != null) {
      /*  if a channel is set, respect just prices for this channel */
      channelPrices = product.prices.filter(p => p.distribution_channel_id == this.distributionChannel.id);
      if (channelPrices.length == 0)
        channelPrices = product.prices.filter(p => p.distribution_channel_id == null);
    } else {
      /*  else, respect just prices for restaurant (null channel) */
      console.log("prices:", product)
      channelPrices = product.prices.filter(item => item.distribution_channel_id == null);
    }
    return channelPrices.length > 0 ? channelPrices[channelPrices.length - 1].amount / 100 : 0;
  },
  hideComposableProductPanel({ state, commit }) {
    console.log("index.js:hideComposableProductPanel:state", state);
    commit('setComposableProductValue', null);
    commit('setComposableProductSelected', null);
    commit('setPanel', '');
    commit('setProductSelected', null);
    commit('setShowingActionablePanel', false);
    commit('setHideComposableProductPrice', false);
    commit('setComponent', null);
    commit('setProductComposableBeingEdited', null);

  },
  showComposableProductPanel({ state, commit }, product) {
    console.log("state:showComposableProductPanel", state);
    commit('setPanel', 'composable-product-panel');
    commit('setComposableProductSelected', {
      composableProducts: product,
      product: product
    });
    commit('setShowingActionablePanel', true);
  },
  hideProductPanel({ state, commit }) {
    console.log("index.js:hideProductPanel:state", state);
    commit('setPanel', '');
    commit('setShowingActionablePanel', false);
    commit('setProductSelected', null);
    commit('setProductBeingEdited', null);

  },
  showEditProductPanel({ state, commit, dispatch }, [product, parent, level = 1, account]) {

    dispatch('changeAccountSelected', account);

    /*let composable_items = product.purchasable ? product.purchasable.composable_product_items : product.composable_product_items;
    let extras = product.purchasable ? product.purchasable.extras : product.extras;*/
    let extras = product.purchasable.extras ?? 0;
    if (
      product.purchases.length > 0 &&
      (product.purchasable_type === "product" ||
        product.purchasable_type === "composable_product_item") ||  extras.length > 0 
    ) {
      console.log("index.js:showEditComposableProductPanel:state", state);
      dispatch('hideComposableProductPanel');
      dispatch('hideProductPanel');
      commit('setPanel', 'composable-product-panel');
      commit('setComposableProductValue', {
        selection: extractSelectionValue(product),
        extras: extractExtrasValue(product)
      })
      commit('setProductComposableBeingEdited', product)
      commit('setComposableProductSelected', {
        composableProducts: product.purchasable,
        product: product
      });
      commit('setShowingActionablePanel', true);
    }
    else if (product.purchasable_type === "available_package") {
      console.log("product", product)
      commit('setLevel', level)
      commit('setPanel', 'ProductPanel');
      commit('setProductSelected', product);
      commit('setProductBeingEdited', product);
      commit('setParentSelected', parent);
      commit('setShowingActionablePanel', true);
    }
    else if (level === 1) { /** TODO: This maybe can be configured */

      console.log("index.js:ProductPanel:state", state);
      commit('setPanel', 'ProductPanel')
      commit('setParentSelected', parent);
      commit('setProductSelected', product);
      commit('setProductBeingEdited', product);
      commit('setShowingActionablePanel', true);
    }
  },
  showProductPanel({ state, commit }, product) {

    console.log("index.js:showProductPanel:state", state);
    commit('setPanel', 'ProductPanel');
    commit('setProductSelected', product);
    commit('setShowingActionablePanel', true);
  },

  showEditProductPanelPackage({ commit, dispatch }, [product, parent, level = 1, account]) {

    dispatch('changeAccountSelected', account)
    commit('setLevel', level)
    /** TODO: This maybe can be configured */
    commit('setPanel', 'ProductPanel')
    commit('setParentSelected', parent);
    commit('setProductSelected', product);

    commit('setProductBeingEdited', product);
    commit('setShowingActionablePanel', true);
  },

  showProductPanelPackage({ commit }, product) {

    /** TODO: This maybe can be configured */
    commit('setPanel', 'ProductPackagePanel')
    commit('setProductSelected', product);
    commit('setShowingActionablePanel', true);
  },
  async getProducts({ state, commit }) {
    state.loadingCategories = true;
    //Load categories and products
    axios.all([
      axios.get(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/available-packages`),
      axios.get(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/product-categories`),
      axios.get(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/distribution-channels`),
    ]).
      then(axios.spread((...responses) => {
        commit('setPackages', responses[0].data.data);
        commit('setCategories', responses[1].data.data);
        commit('setDistributionChannels', responses[2].data.data);
        state.loadingCategories = false;
      }))
      .catch(error => {
        // this.alerts = [];
        // this.alerts.push({
        //     title: 'Mesas',
        //     content: error.response.data.message,
        //     type: 'danger'
        // });
        console.log("index.js:getProducts:error", error);
        state.loadingCategories = false;
      });
  },

  assignTableWaiter({ state }, data) {
    console.log(state)
    axios.post(state.tenantApiUrlBase + `/api-v2/table-reservations/${state.currentQRCode}/assign-table-waiter`, data)
      .then(r => {

        console.log("assignTable:", r.data)
      })
      .catch(e => {
        let aMessage = "Error: " + e.message;
        console.log("assignTable:Error", aMessage)
      })
  },

  makeReservation({ state }) {
    this.requestingInfo = true;
    //var token = this.getCustomerID();
    let resp = null;
    axios
      .post(
        state.tenantApiUrlBase +
        "/api-v2/table-reservations/" +
        state.currentQRCode +
        "/add",
        {
          table_qrcode: state.currentQRCode,
          //username: token
        }
      )
      .then((res) => {
        resp = res.data.data;
        console.log("Table.vue:makeReservation:resp:", resp);
        if (resp != null && resp.booking.length > 0) {
          this.booking = resp.booking;

          let command =
            this.booking.length > 0 && this.booking[0].open_table != null
              ? this.booking[0].open_table
              : null;
          this.$store.commit("setCommand", command);
        }

        if (resp.table != null) this.table = resp.table;
        this.$store.commit("setTable", this.table);
        console.log("booking", this.booking);
        /*let data = {
          table_id : this.table.id,
          user_id: this.booking.waiter.id
        }
        this.$store.dispatch('assignTableWaiter',data);*/
        //If reservation its created and return data then subscribe to Channel
        if (this.booking.length > 0) {
          this.subscribeToChannelAndListenEvents();
        }

        //If reservation was just created successfully then subscribe to Channel
        if (res.data.message == "created_new_rsv") {
          let aMessage = this.$t("messages.call_made_staff");
          this.alerts = [];
          this.alerts.push({
            title: "Mesas",
            content: aMessage,
            type: "info",
          });
          this.booking = [];
          this.booking = resp;
          if (this.booking.length > 0)
            this.subscribeToChannelAndListenEvents();
        }

        this.requestingInfo = false;
      })
      .catch((error) => {
        let message = "";
        if (
          error.response &&
          (error.response.status == "404" || error.response.status == "500")
        ) {
          message = error.response.data.message;
          this.notFoundTable = true;
        } else {
          message = error.response.data.message;
        }
        this.alerts = [];
        this.alerts.push({
          title: "Mesas",
          content: "Error!, " + message,
          type: "danger",
        });
        this.requestingInfo = false;
      });
  },
  
 

}