<template>
  <div
    class="d-flex flex-grow-1 overflow-hidden"
    :class="{ 'app-content-left': $route.name == 'command.show' }"
  >
    <div class="flex-grow-1 py-lg-5" v-if="requestingInfo">
      <div class="text-center pt-4 pb-0">
        <img
          src="../assets/logo-modern-restaurant-sidebar.png"
          class="img-fluid mb-5"
          alt="logo"
        />
        <h2 v-if="isValidTable" class="text-info">
          {{ $t("info.welcome") }} {{ hotelName }}!
        </h2>
      </div>
      <div
        class="text-center d-flex justify-content-center mb-2"
        style="color: #495057"
      >
        <h3>
          <b-spinner variant="primary"></b-spinner>
          <strong class="mb-2 ml-0"> {{ $t("info.loading") }}</strong>
        </h3>
      </div>
    </div>
    <div
      v-else-if="
        !isReserved &&
        !isCommandSection &&
        !showingMenu &&
        !isTableBookingClosed &&
        !isPayAccountSection
      "
      class="container position-fixed"
    >
      <b-row class="row justify-content-between align-items-center">
        <b-col cols="12">
          <div class="text-center" v-if="isValidTable">
            <h2 class="text-info">{{ $t("info.welcome") }}</h2>
          </div>

          <h4 v-if="isValidTable" class="table-title">
            {{ $tc("title.table", 1) }}: {{ tableName }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4" style="text-align: -webkit-center">
          <b-button
            size="lg"
            :title="$t('title.see_menu')"
            v-if="isAcceptedReservation"
            class="text-white ml-2 mb-3 text-center-button btn-req-menu"
            variant="info"
            @click.prevent="showMenu"
            :disabled="callingWaiter"
          >
            <b-icon class="fa-4x" icon="book"></b-icon>
            {{ $t("title.see_menu") }}
          </b-button>
        </b-col>
        <b-col cols="12" md="4" style="text-align: -webkit-center">
          <b-button
            v-if="!isTableBookingClosed && !isReserved"
            size="lg"
            :title="$t('title.call_waiter')"
            class="text-white ml-2 mb-3 text-center-button btn-call-waiter"
            variant="warning"
            @click.prevent="makeRequest('action_type_call')"
            :disabled="callingWaiter"
          >
            <b-icon class="fa-4x text-white" icon="bell"></b-icon>
            {{ $t("title.call_waiter") }}
          </b-button>
        </b-col>

        <!--<b-col cols="6">
          <b-button
            v-if="isAcceptedReservation"
            :title="seeOrder ? $t('title.see_order') : $t('title.place_order')"
            class="text-white ml-2 mb-3 btn-block text-center-button"
            @click.prevent="addCommand"
            :disabled="callingWaiter"
          >
            <span
              v-if="seeOrder"
              class="fa-4x fas fa-concierge-bell"
              aria-hidden="true"
            ></span>

            <b-icon
              v-if="!seeOrder"
              class="fa-4x"
              icon="pencil-square"
            ></b-icon>

            <span>{{
              seeOrder ? $t("title.see_order") : $t("title.place_order")
            }}</span>
          </b-button>
        </b-col>-->
        <b-col
          cols="12"
          md="4"
          style="text-align: -webkit-center"
          v-if="isAcceptedReservation && seeOrder"
        >
          <b-button
            :title="
              hasPaymentPlatform && purchasesConfirm
                ? $t('title.pay_account')
                : $t('title.request_account')
            "
            :variant="
              hasPaymentPlatform && purchasesConfirm ? 'success' : 'warning'
            "
            size="lg"
            class="text-white ml-2 mb-3 text-center-button btn-req-bill"
            @click.prevent="preparePayAccount()"
            :disabled="callingWaiter || !seeOrder"
          >
            <b-icon
              class="fa-4x"
              :icon="
                hasPaymentPlatform && purchasesConfirm
                  ? ' bag-check-fill'
                  : ' file-earmark-text'
              "
            ></b-icon>
            <span>
              {{
                hasPaymentPlatform && purchasesConfirm
                  ? $t("title.pay_account")
                  : $t("title.request_account")
              }}</span
            >
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="
        !requestingInfo &&
        !isReserved &&
        !isCommandSection &&
        isTableBookingClosed &&
        !notFoundTable
      "
      class="
        d-flex
        flex-column flex-grow-1
        justify-content-center
        h-100
        text-center
        py-2 py-md-4
      "
    >
      <div class="row">
        <div class="col-md-8 col-xl-10 offset-md-2 offset-xl-1">
          <h1 class="h3 my-1 text-dark">{{ $t("info.thank_preference") }}</h1>
          <h2 class="h3 my-1 text-dark">{{ $t("info.come_back") }}</h2>
        </div>
      </div>
      <div
        class="my-2 my-md-4 d-flex justify-content-center align-items-center"
      >
        <div
          class="icon-preview-img d-flex align-items-center"
          id="preview-image"
        >
          <img
            src="https://cdn2.iconfinder.com/data/icons/mobil-conveniences/100/check-512.png"
            width="60%"
            class="d-block mx-auto"
          />
        </div>
      </div>
      <div class="py-2 text-center" v-if="timerEnabled">
        <h4 v-if="timerCount > 0">
          {{ $t("info.redirecting_in") }} {{ timerCount }}
          {{ $t("info.second") }}
        </h4>
        <b-spinner v-else variant="success"></b-spinner>
      </div>
    </div>

    <component
      v-if="
        !requestingInfo && !isReserved && isAcceptedReservation && showingMenu
      "
      :is="menuTemplate"
      :isMobile="isMobile"
      :table="table"
      :key="'template_01'"
    ></component>

    <Command
      v-if="
        !requestingInfo &&
        !isReserved &&
        isAcceptedReservation &&
        isCommandSection &&
        !isPayAccountSection
      "
      :table="table"
    />

    <NewPay
      v-if="
        !requestingInfo &&
        !isReserved &&
        isAcceptedReservation &&
        isPayAccountSection
      "
      @makerequest="makeRequest('action_type_bill')"
      :table="table"
      :callingWaiter="callingWaiter"
      :company="company"
    />

    <div
      v-else-if="
        !requestingInfo &&
        !isReserved &&
        !isAcceptedReservation &&
        isCommandSection
      "
      class="
        d-flex
        flex-column flex-grow-1
        justify-content-center
        h-100
        text-center
        py-2 py-md-4
        h-100
        position-relative
      "
    >
      <div class="row">
        <div class="col-md-8 col-xl-10 offset-md-2 offset-xl-1">
          <h1 class="h3 my-1 text-dark">{{ $t("info.opss") }}</h1>
          <h2 class="h3 my-1 text-dark">
            {{ $t("info.menu_not_available") }}
          </h2>
        </div>
      </div>
      <div
        class="my-2 my-md-4 d-flex justify-content-center align-items-center"
      >
        <div
          class="icon-preview-img d-flex align-items-center"
          id="preview-image"
        >
          <img
            src="https://cdn2.iconfinder.com/data/icons/mobil-conveniences/100/fragile-512.png"
            width="60%"
            class="d-block mx-auto"
          />
        </div>
      </div>
      <div class="text-center">
        <h5 class="mt-2" v-if="timerCount > 0">
          {{ $t("info.redirecting_in") }} {{ timerCount }}
          {{ $t("info.second") }}
        </h5>
        <b-spinner v-else variant="success"></b-spinner>
      </div>
    </div>

    <div
      v-else-if="!requestingInfo && isReserved && !notFoundTable"
      class="d-flex flex-column flex-grow-1 justify-content-center mt-4"
    >
      <div class="row m-3">
        <div class="col-md-8 col-xl-10 offset-md-2 offset-xl-1">
          <h4 v-if="isValidTable" class="table-title">
            {{ $tc("title.table", 1) }}:{{ tableName }}
          </h4>
          <h1 class="h3 my-1 text-dark">{{ $t("opss") }}</h1>
          <h5 class="my-1 text-danger">
            {{ $t("info.table_in_service") }}
          </h5>
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center">
        <img src="../assets/table_reserved.png" style="width: 200px" />
      </div>
      <p class="lead mt-4">
        <b-button
          variant="primary"
          class="btn btn-squared-default-plain ml-2 mb-2"
          size="lg"
          @click.prevent="reloadPage"
          :disabled="requestingInfo"
        >
          <span class="fa fa-chevron-left" aria-hidden="true"></span>
          {{ $t("new_table") }}
        </b-button>
      </p>
    </div>

    <div
      v-else-if="!requestingInfo && !isReserved && notFoundTable"
      class="d-flex flex-column flex-grow-1 justify-content-center mt-4"
    >
      <div class="row m-3">
        <div class="col-md-8 col-xl-10 offset-md-2 offset-xl-1">
          <h1 class="h3 my-1 text-dark">{{ $t("opss") }}</h1>
          <h5 class="my-1 text-danger">
            {{ $t("info.table_not_found") }}
          </h5>
        </div>
      </div>

      <div
        class="d-flex justify-content-center align-items-center flex-column m-3"
      >
        <img src="../assets/error.png" style="width: 200px" />
        <h5 class="mt-2" v-if="timerCount > 0">
          {{ $t("info.redirecting_in") }} {{ timerCount }}
          {{ $t("info.second") }}
        </h5>
        <b-spinner v-else variant="success"></b-spinner>
      </div>
    </div>

    <vtr-alert :alerts="alerts"></vtr-alert>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Echo from "laravel-echo";
import Command from "./Command/Command.vue";
import NewPay from "./PayAccount/NewPay.vue";
import Template from "./Command/Menu/Template.vue";
import Template2 from "./Command/Menu/Template2.vue";
import { checkAllIsConfirmed } from "../js/utils.js";
//import Hashids from "hashids";
//const hashids = new Hashids("M0D3RN_R3574UR4N7-2021", 4);

export default {
  props: {
    company: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      table: null,
      purchasesConfirm: false,
      customer: {
        name: "Guest",
      },
      message: "",
      callingWaiter: false,
      requestMessage: "",
      booking: [],
      showWelcome: false,
      isReserved: false,
      orderOnline: true,
      hasMenuTemplate: true,
      alerts: [],
      requestingInfo: false,
      timerCount: 10,
      timerEnabled: false,
      notFoundTable: false,
      windowWidth: 0,
      visited: 0,
    };
  },
  components: {
    Command,
    Template,
    Template2,
    NewPay,
  },
  beforeMount() {
    this.purchasesConfirm = this.getCurrentPurchasesConfirm();
    console.log("after calling BeforeMount", this.purchasesConfirm);
  },
  mounted() {
    this.makeReservation();

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.detectMobile();
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getCustomerID() {
      // check a cookie exist
      var hasToken = this.$cookies.isKey("noWaitUid");
      var token = "";

      if (!hasToken) {
        this.$cookies.set("noWaitUid", this.getRandom(20));
      }
      // get a cookie
      token = this.$cookies.get("noWaitUid");
      return token;
    },
    getCurrentPurchasesConfirm() {
      const currentCommand = this.$store.state.command;
      //console.log("getCurrentPurchasesConfirm", this.accountSelected, this.accounts, currentCommand);
      if(!currentCommand.accountSelected)
        return false;
      let confirm = false;
      const currentAccount = this.accounts.filter( (account) => account.account == currentCommand.accountSelected )[0];
      //console.log("getCurrentPurchasesConfirm 2", currentCommand.accountSelected, this.accounts, currentAccount);
      confirm = checkAllIsConfirmed(currentAccount.purchases);
      return confirm;
    },
    checkAllIsConfirmed,
    makeReservation() {
      this.requestingInfo = true;
      //var token = this.getCustomerID();
      let resp = null;
      this.$http
        .post(
          this.$root.tenantApiUrlBase +
            "/api-v2/table-reservations/" +
            this.$root.table_qrcode +
            "/add",
          {
            table_qrcode: this.$root.table_qrcode,
            //username: token
          }
        )
        .then((res) => {
          resp = res.data.data;
          console.log("Table.vue:makeReservation:resp:", resp);
          if (resp != null && resp.booking.length > 0) {
            this.booking = resp.booking;
            let command =
              resp.booking.length > 0 && resp.booking[0].open_table != null
                ? resp.booking[0]
                : null;

            let lang =
              resp.booking.length > 0 && resp.booking[0].preffered_lang != null
                ? resp.booking[0].preffered_lang
                : null;
            this.$store.commit("setCommand", command);
            this.$store.commit("setLang", lang);
          }

          if (resp.table != null) this.table = resp.table;
          this.$store.commit("setTable", this.table);
          console.log("booking", this.booking);
          /*let data = {
            table_id : this.table.id,
            user_id: this.booking.waiter.id
          }
          this.$store.dispatch('assignTableWaiter',data);*/
          //If reservation its created and return data then subscribe to Channel
          if (this.booking.length > 0) {
            this.subscribeToChannelAndListenEvents();
            //this.getHasPlatformsPayment();
          }

          //If reservation was just created successfully then subscribe to Channel
          if (res.data.message == "created_new_rsv") {
            let aMessage = this.$t("messages.call_made_staff");
            this.alerts = [];
            this.alerts.push({
              title: "Mesas",
              content: aMessage,
              type: "info",
            });
            this.booking = [];
            this.booking = resp;
            if (this.booking.length > 0)
              this.subscribeToChannelAndListenEvents();
            //this.getHasPlatformsPayment();
          }
          this.$store.dispatch("tableName");
          this.$store.dispatch("client");
          this.requestingInfo = false;
        })
        .catch((error) => {
          //console.error(error)
          //console.error(error.response)
          /*console.error(this.$root.tenantApiUrlBase +
            "/api-v2/table-reservations/" +
            this.$root.table_qrcode +
            "/add");*/
          let message = "";
          if (
            error.response &&
            (error.response.status == "404" || error.response.status == "500")
          ) {
            message = error.response.data.message;
            this.notFoundTable = true;
          } else {
            message = error.response.data.message;
          }
          this.alerts = [];
          this.alerts.push({
            title: "Mesas",
            content: "Error!, " + message,
            type: "danger",
          });
          this.requestingInfo = false;
        });
    },
    subscribeToChannelAndListenEvents() {
      if (this.company.pusher_key && this.isAcceptedReservation) {
        //Subscribe to channel
        //Echo.private(`tablersv.${this.table.id}`)
        window.Echo.channel(`table_booking.${this.table.id}`).listen(
          ".WaiterAction",
          (e) => {
            this.$store.commit("setWaiterAction", e.action);
            this.$store.commit("setWaiterActionData", e.data);

            this.message = e.message;
            if (this.message != "") {
              this.alerts = [];
              this.alerts.push({
                title: "Mesas",
                content: this.message,
                type: "primary",
              });
            }

            if (e.data.length > 0) this.customer = e.data[0].customer;
            this.table = e.table;
            this.$store.commit("setTable", this.table);
          }
        );
      } else {
        this.unsubscribeToChannel(this.table.id);
      }
    },
    unsubscribeToChannel(table_id) {
      //var channel = Echo.channel(`table_booking.${this.table.id}`)
      var channel = Echo.channel(`table_booking.${table_id}`);
      // Remove all handlers for the 'CallWaiter' event
      channel.stopListening(".CallWaiter");
      window.Echo.leaveChannel(`table_booking.${table_id}`); //Unsubscribe to channel
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmitCustomer();
    },
    handleSubmitCustomer() {
      this.callingWaiter = true;
      this.$http
        .put(
          this.$root.tenantApiUrlBase +
            `/api-v2/table-reservations/${this.$root.table_qrcode}/customers/` +
            this.customer.id,
          {
            customer_name: this.customer.name,
          }
        )
        .then((response) => {
          const resp = response.data.message;
          this.alerts = [];
          this.alerts.push({
            title: "Mesas",
            content: resp,
            type: "success",
          });

          this.callingWaiter = false;
          // Hide the modal manually
          // this.$nextTick(() => {
          //   this.$bvModal.hide('modal-prevent-closing')
          // })
        })
        .catch((error) => {
          this.alerts = [];
          this.alerts.push({
            title: "Mesas",
            content: error.response.message,
            type: "danger",
          });
          this.callingWaiter = false;
        });
    },
    resetModal() {
      this.customer = {
        name: "Guest",
      };
    },
    preparePayAccount() {
      if (this.hasPaymentPlatform && this.purchasesConfirm) {
        this.addPayAccount();
      } else {
        this.makeRequest("action_type_bill");
      }
    },
    addPayAccount() {
      if (this.hasPaymentPlatform) {
        // named route
        this.$store.commit("setIsLoadingCommand", false);
        this.$router.push({
          name: "payaccount.show",
          params: {
            id: this.$root.table_qrcode,
          },
        });
      } else {
        this.makeRequest("action_type_bill");
      }
    },
    makeRequest(request_type) {
      let resp = "";
      if (this.booking.length > 0 && typeof this.booking[0].id != "undefined") {
        //let reservation_id = this.booking[0].id;
        //reservation_id = hashids.encode(reservation_id);
        this.callingWaiter = true;
        this.$http
          .post(
            this.$root.tenantApiUrlBase +
              `/api-v2/table-reservations/${this.$root.table_qrcode}/addRequest`,
            {
              reservation_id: this.booking[0].id,
              request_type: request_type,
              table_qrcode: this.$root.table_qrcode,
            }
          )
          .then((res) => {
            let title = this.$tc("title.table", 1);
            let message = "";
            switch (res.data.action) {
              case "action_type_call":
                message = this.$t("messages.call_made_staff");
                break;

              case "action_type_menu":
                message = this.$t("messages.action_type_menu");
                break;
              case "action_type_bill":
                message = this.$t("messages.action_type_bill");
                break;

              case "action_type_order":
                message = this.$t("messages.action_type_order");
                break;

              default:
                message = "";
                break;
            }
            this.alerts = [];
            this.alerts.push({
              title: title,
              content: message,
              type: "info",
            });
            console.log("resp_request:", resp);
            this.callingWaiter = false;
          })
          .catch((error) => {
            if (
              error.response.data.message == "no_found_reservation" &&
              error.response.status == 405
            ) {
              let aMessage = this.$t("messages.not_connected_table");
              this.alerts = [];
              this.alerts.push({
                title: "Mesas",
                content: aMessage,
                type: "warning",
              });
              setTimeout(function () {
                window.location = "/";
              }, 3000);
            } else {
              this.alerts = [];
              this.alerts.push({
                title: "Mesas",
                content: error.response.data.message,
                type: "danger",
              });
            }
            this.callingWaiter = false;
          });
      } else {
        let aMessage = this.$t("messages.not_reservation_loaded");
        this.alerts = [];
        this.alerts.push({
          title: "Mesas",
          content: aMessage,
          type: "warning",
        });
      }
    },
    showMenu() {
      if (this.hasMenuTemplate) {
        this.$store.commit("showProducts");
        this.$router.push({
          name: "menu.show",
          params: {
            id: this.$root.table_qrcode,
          },
        });
      } else {
        this.makeRequest("action_type_menu");
      }
    },
    addCommand() {
      if (this.orderOnline) {
        // named route
        this.$store.commit("setIsLoadingCommand", false);
        this.$router.push({
          name: "command.show",
          params: {
            id: this.$root.table_qrcode,
          },
        });
      } else {
        this.makeRequest("action_type_order");
      }
    },
    tryNew() {
      //window.location.href = '/';
      window.location.reload();
    },
    reloadPage() {
      window.location.href = "/";
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    detectMobile() {
      if (this.isMobile) {
        //this.$router.push('/MainMobile');
        this.$store.commit("showProducts");
        this.$store.commit("setIsMobile", this.isMobile);
      } else {
        //this.$router.push('/Main');
        this.$store.commit("hideProducts");
        this.$store.commit("setIsMobile", this.isMobile);
      }
    },
  },
  computed: {
    ...mapState({
      hotel: (state) => state.hotel,
      menuTemplate: (state) => state.menuTemplate,
      total: (state) => state.total,
      totalProducts: (state) => state.totalProducts,
      accountSelected: (state) => state.command.accountSelected,
      commandData: (state) => state.command,
      accounts: (state) => state.command.open_table.table_accounts,
      waiterAction: (state) => state.waiterAction,
      tableName: (state) => state.tableName,
      client: (state) => state.client,
      closeOpenTable: (state) => state.closeOpenTable,
      isLoadingCommand: (state) => state.isLoadingCommand,
      hasPaymentPlatform: (state) => state.hasPaymentPlatform,
    }),
    ...mapGetters(["isValidTable"]),
    
    hotelName() {
      return this.hotel != null ? this.hotel.name : null;
    },

    seeOrder() {
      var seeOrder = false;
      this.accounts.map(($account) => {
        if ($account.purchases.length > 0) {
          seeOrder = true;
        }
      });

      return seeOrder;
    },
    getPurchasesConfirm() {
      var confirm = false;
      this.accounts.map((account) => {
        if (account.purchases.length > 0) {
          account.purchases.map((purchase) => {
            if (purchase.confirmed) {
              confirm = true;
            } else {
              confirm = false;
            }
          });
        }
      });

      return confirm;
    },
    isTableBookingClosed() {
      return this.booking.length == 0
        ? true
        : this.booking.length > 0 && this.booking[0].closed_at != null;
    },
    isAcceptedReservation() {
      var allowed = false;
      if (this.booking.length > 0)
        allowed = //this.booking[0].waiter_id != null &&
          this.booking[0].closed_at == null &&
          this.booking[0].open_table != null;

      return allowed;
    },
    routeName() {
      return this.$route.name;
    },
    isCommandSection() {
      return this.routeName === "command.show";
    },

    isPayAccountSection() {
      return this.routeName === "payaccount.show";
    },
    showingMenu() {
      return this.routeName === "menu.show";
    },
    isMobile() {
      let flag = false;
      if (
        screen.width <= 760 ||
        (this.windowWidth != 0 && this.windowWidth <= 760) ||
        /webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        console.log("windowWidth", this.windowWidth);
        console.log("screen.width", screen.width);
        flag = true;
      }
      return flag;
    },
  },
  watch: {
    windowWidth(newWidth) {
      //console.log(`it changed to ${newWidth} from ${oldWidth}`);
      this.windowWidth = newWidth;
      this.detectMobile();
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else if (this.timerEnabled && value == 0) {
          window.location.href = "/"; //comment to prevent reload
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    closeOpenTable: function (newVal) {
      if (!this.requestingInfo && newVal) {
        this.timerCount = 3;
        this.timerEnabled = newVal;
      }
    },
    notFoundTable: function (newVal) {
      if (!this.requestingInfo && newVal) {
        this.timerCount = 5;
        this.timerEnabled = newVal;
      }
    },
    waiterAction: function (newVal) {
      console.log("actionWaiter:", newVal);
      this.$store.dispatch("waiterActions");
      this.$store.dispatch("buttonBillData");
    },
  },
};
</script>