import vue from 'vue';

export default {
  setCommand: (state, data) => {

    state.command = data
  },
  setIsLoadingCommand: (state, isLoadingCommand) => {
    state.isLoadingCommand = isLoadingCommand
  },
  loadId: (state, [value]) => {
    state.command.id = value
  },
  loadState: (state, [value]) => {
    state.state = value
  },
  setComponentSelections(state, selections) {
    vue.set(state.selections, state.currentComponent, selections)
    state.showNumberPad = false
  },
  setComponent(state, componentId) {
    state.currentComponent = componentId
  },
  showProducts: (state) => {
    state.showingProducts = true;
    state.showingActionablePanel = false;
  },
  hideProducts: (state) => {
    state.showingProducts = false;
    state.showingActionablePanel = false;
  },
  loadTenant(state, value) {
    let company = value;
    let mode = process.env.VUE_APP_NODE_ENV;
    console.log("mode",mode);
    let tenantURL = '';
    if (company != null) {
      if (mode === "development" || process.env.VUE_APP_LOCAL_TEST) { //
        //tenantURL = 'http://' + company.subdomain + '.' + company.domain + ':8081';
        tenantURL = 'http://' + company.subdomain + '.' + company.domain;
      } else { //
        //tenantURL = 'https://' + company.subdomain + '.' + company.domain + ':8081';
        tenantURL = 'https://' + company.subdomain + '.' + company.domain; //

      } //
      console.log("index.js:loadTenant:mode", mode)

      state.tenantUrl = tenantURL;
      state.tenantApiUrlBase = tenantURL;
    }
  },
  setTableQrCode: (state, code) => {
    state.currentQRCode = code;
  },
  setHotel: (state, hotel) => {
    state.hotel = hotel;
  },
  setTable: (state, table) => {
    state.table = table;
  },
  setPackages: (state, packages) => {
    state.packages = packages;
  },
  setCategories: (state, categories) => {
    state.categories = categories;
  },
  setDistributionChannels: (state, dc) => {
    state.distributionChannels = dc;
  },
  setProductSelected: (state, product) => {
    state.productSelected = product;
  },
  setProductBeingEdited: (state, product) => {
    state.productBeingEdited = product;
  },
  addProductBeingAdded: (state, product) => {
    state.productsBeingAdded.push(product.id)
  },
  removeProductBeingAdded: (state, product) => {
    state.productsBeingAdded = state.productsBeingAdded.filter(p => p != product.id);
  },
  setShowingActionablePanel: (state, flag) => {
    state.showingActionablePanel = flag;
    state.showingProducts = flag;
  },
  changeAccountSelected: (state, [accountSelected]) => {
    state.command.accountSelected = parseInt(accountSelected);
  },
  setAccountSelected: (state, [accountSelected]) => {
    console.log("accountSelected", accountSelected);
    state.accountSelected = accountSelected;
  },
  changeCommandStatus: (state, [status]) => {
    state.command.status = status
  },
  toggleMenuTemplate(state, template) {
    state.menuTemplate = template;
  },
  setComposableProductSelected: (state, product) => {
    state.productComposableSelected = product;
  },
  setProductComposableBeingEdited: (state, product) => {
    state.productComposableBeingEdited = product;
  },
  setComposableProductValue: (state, value) => {
    state.composableProductValue = value
  },
  setHideComposableProductPrice: (state, value) => {
    state.hideComposableProductPrice = value
  },
  setPanel: (state, panelName) => {
    state.panel = panelName
  },
  resetAccumulator: (state) => {
    state.padAccumulator = '';
  },
  setQty: (state, qty) => {
    state.qty = qty;
  },
  addProduct: (state, [product]) => {
    product['items'] = [{ specials_added: [] }];
    let prod = {
      id: product.id,
      name: product.name,
      amount: product.amount,
      quantity: 1,
      descriptions: [{ description: null }],
      items: [{ specials_added: [] }],
      specials: product.specials
    };
    state.command.productsSelected = prod;
    // state.command.productsSelected.push({
    //     account_id: state.command.accountSelected,
    //     product: prod
    // });
  },
  addSpecialProduct: (state, [product, special, index, split]) => {
    //Reacondicionar recuerda que se agrego un nivel mas product y necesitas split
    state.command.productsSelected.map(p => {
      if (p.account_id === split) {
        if (p.product.id == product.id) {
          special['index'] = index;
          p.product.items[index]['specials_added'].push(special);
        }
      }
      return p;
    });
    state.command.productsSelected = state.command.productsSelected.slice(0);
  },
  setLevel: (state, value) => {
    state.level = value
  },
  setAlerts: (state, alerts) => {
    state.alerts = alerts;
  },
  setIsMobile: (state, ismobile) => {
    state.isMobile = ismobile;
  },
  setCategory: (state, category) => {
    state.category = category;
  },
  setCategoryFather: (state, categoryfather) => {
    state.categoryfather = categoryfather;
  },
  setTotal: (state, total) => {
    state.total = total;
  },
  setTotalProducts: (state, totalProducts) => {
    state.totalProducts = totalProducts;
  },
  setProcessingDeleted: (state, processingDeleted) => {
    state.processingDeleted = processingDeleted
  },
  setProcessing: (state, processing) => {
    state.processing = processing
  },
  setAutomaticPreconfirm: (state, ap) => {
    state.automatic_preconfirm = ap
  },
  refreshAccount: (state, [accounts]) => {
    state.command.accounts = accounts;
    state.command.splitAccount = state.command.accounts.length;
    console.log("command", state.command)
    /*  Restore productSelected to avoid bugs   */
    if (state.productSelected != null) {
      let product = null;

      if (state.productSelected.purchasable_type === 'available_package_item' && state.parentSelected !== null) {
        let account = state.command.accounts.find(a => a.id == state.parentSelected.table_account_id);
        if (typeof (account) != 'undefined' || account != undefined) {
          const parent = account.purchases.find(p => p.id == state.parentSelected.id);
          product = parent.purchases.find(p => p.id == state.productSelected.id);
        }
      } else {
        let account = state.command.accounts.find(a => a.id == state.productSelected.table_account_id);
        if (typeof (account) != 'undefined' || account != undefined) {
          product = account.purchases.find(p => p.id == state.productSelected.id);
        }
      }

      if (product != null) state.productSelected = product;
    }

    const showConfirmButton = state.command.accounts.reduce((b, account) => {
      return b || account.purchases.reduce(function verifyIfConfirmed(b2, p) {
        const isConfirmed = (
          (!p.confirmed)
          || b2
          || p.purchases.reduce(verifyIfConfirmed, b2)
          || p.extras.reduce((acc, e) => acc || (!e.confirmed), b2)
        );

        return isConfirmed
      }, b)
    }, false)

    state.command.status = showConfirmButton
  },
  setButtonBillDataTotal: (state, total) => {
    state.buttonBillData.total = total;
  },
  setButtonBillDataTotalProducts: (state, totalProducts) => {
    state.buttonBillData.totalProducts = totalProducts;
  },
  setParentSelected: (state, parent) => {
    state.parentSelected = parent;
  },
  setClientData: (state, { clientPhoneId, clientAddressId }) => {
    state.client_phone_id = clientPhoneId;
    state.client_address_id = clientAddressId;
  },
  setWaiterAction: (state, waiterAction) => {
    state.waiterAction = waiterAction
  },
  setWaiterActionData: (state, waiterActionData) => {
    state.waiterActionData = waiterActionData
  },
  setTableName: (state, tableName) => {
    state.tableName = tableName
  },
  setTableAccounts: (state, table_accounts) => {
    state.command.open_table.table_accounts = table_accounts
  },
  setCloseOpenTable: (state, closeOpenTable) => {
    state.closeOpenTable = closeOpenTable
  },
  setClient: (state, client) => {
    state.client = client
  },
  setHasPaymentPlatform: (state, hasPaymentPlatform) => {
    state.hasPaymentPlatform = hasPaymentPlatform
  },

  setTipsSettings: (state, tipsSettings) => {
    state.tipsSettings = tipsSettings;
  },

  setLang: (state, lang) => {
    state.lang = lang;
  },


}