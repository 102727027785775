<template>
  <div class="d-flex flex-column h-100 text-sm">
    <h5 :class="
        !isMobile
          ? 'mb-1 d-flex justify-content-between p-5'
          : 'mb-3 d-flex justify-content-between'
      ">
      <span>
        <a
          href="#"
          class="d-inline-block mr-3"
          @click.prevent="hideComposableProductPanel"
        >
          <i class="fas fa-arrow-left"></i>
          {{
            productComposableBeingEdited !== null
              ? $t("title.return_account")
              : $t("title.return_menu")
          }}
        </a>
      </span>
    </h5>

    <div class="d-flex flex-column p-3 overflow-auto">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          lg="9"
          v-if="confirmed()"
          class="text-center mb-3 text-success font-weight-bold"
        >
          <i class="fa fa-check-circle"></i>
          {{ $t("title.confirmed_product") }}
        </b-col>
        <b-col
          cols="12"
          lg="9"
          v-if="currentComponent == null && !showingExtras"
        >
          <b-overlay
            spinner-variant="primary"
            class="h-100 text-center mb-3 text-info font-weight-bold"
          >
            <figure
              class="card card-product pusheable h-100 justify-content-between"
            >
              <div class="img-wrap-product">
                <img :src="getFullImagePath(product)" width="auto" />

                <h6 class="title text-center">{{ product.name }}</h6>
                <div class="price-wrap h6 text-center" v-if="description != ''">
                  <p class="title text-center">
                    {{ description }}
                  </p>
                </div>
                <div class="price-wrap h6 text-center">
                  <span v-if="showPrice" class="font-weight-bold"
                    >{{ price }}
                  </span>
                  <del v-else class="font-weight-bold">N/A</del>
                  <span v-if="extrasPrice > 0" class="text-success"
                    >(+ {{ extrasPriceString }})</span
                  >
                </div>
                <!-- price-wrap.// -->
              </div>

              <!-- <div class="d-flex position-absolute top-0 h-100 w-100 justify-content-center align-items-center shadow"  v-if="isAdding">
                  <b-spinner small ></b-spinner>
              </div> -->
            </figure>
          </b-overlay>
        </b-col>
        <b-col
          cols="12"
          lg="9"
          class="mt-3"
          v-if="currentComponent == null && !showingExtras"
        >
          <b-row class="justify-content-center">
            <b-col cols="12" lg="9">
              <b-form-group>
                <template #label>
                  <span class="text-base"
                    >{{ $t("title.quantity") }}
                    <i class="fa fa-spin fa-spinner" v-if="savingQuantity"></i
                  ></span>
                </template>
                <vtr-lw-spinbutton
                  v-model="quantity"
                  :min="0.5"
                  size="lg"
                  :disabled="confirmed()"
                ></vtr-lw-spinbutton>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <composable-product-selector
        v-if="showingExtras"
        :title="$tc('title.extras', 2)"
        :items="product.extras"
        :qty="qty"
        @item-selected="resetAccumulator"
        @close="hideExtrasPanel"
        :value="extras"
        @add-product="addProduct"
        :command="command"
        :nesting="nesting"
        :class="{ 'h-100': !onlyHasExtras }"
        :saving="saving"
        :confirmed="confirmed()"
        :preConfirmed="preConfirmed()"
        :empty-selection-label="$t('title.without_extras')"
        @update-selector="updateExtras"
      >
        <template #item-name="{ item }">
          {{ item.name }} {{ formatMoney(item.current_price / 100) }}
        </template>
      </composable-product-selector>
      <composable-product-selector
        v-else-if="currentComponent"
        :title="currentComponent.name"
        :items="currentComponent.products"
        :qty="qty"
        :limit="currentComponent.maximum_number_of_products"
        @item-selected="resetAccumulator"
        @close="hideComponentGroupPanel"
        :value="selections[currentComponent.id]"
        @add-product="addProduct"
        :nesting="nesting"
        :command="command"
        :saving="saving"
        :confirmed="confirmed()"
        :preConfirmed="preConfirmed()"
        :class="{ 'h-100': !onlyHasOneComponent }"
        :without-confirmation="onlyHasOneComponent"
        @update-selector="updateComponents"
      ></composable-product-selector>
      <b-container fluid v-else>
        <b-row class="justify-content-center">
          <b-col
            button
            v-for="component in components"
            :key="component.id"
            class="p-2 text-base clickeable"
            lg="9"
            :cols="12"
            @click="selectComponent(component)"
          >
            <div
              class="
                clicked
                bg-white
                p-3
                text-dark text-center text-dark
                border
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <span>{{ component.name }}</span>
              <span v-if="component.keep_count">
                {{ totalSelectedFor(component.id) }} /
                {{ component.maximum_number_of_products }}
              </span>
              <b-icon
                icon="check2"
                scale="1.5"
                class="text-success"
                v-else-if="isCompleted(component)"
              ></b-icon>
            </div>
          </b-col>
          <b-col
            button
            key="extras"
            lg="9"
            :cols="12"
            class="p-2 text-base clickeable"
            @click="selectExtras()"
            v-if="product.extras.length > 0"
          >
            <div class="bg-white p-3 text-dark text-center text-dark border">
              {{ $tc("title.extras", 2) }}
              <span v-if="extras.length > 0">({{ extrasQty }})</span>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-row
        class="justify-content-center"
        v-if="
          productComposableBeingEdited && !showingExtras && !currentComponent
        "
      >
        <b-form-group v-if="product.specials != ''">
          <template #label>
            <h4 class="text-base">{{ $t("title.special_requests") }}:</h4>
          </template>
          <special-product
            class=""
            :specials="product.specials"
            :command="command"
            :product="productComposableBeingEdited"
            :disabled="confirmed()"
          >
          </special-product>
        </b-form-group>
      </b-row>
      <b-row
        class="justify-content-center"
        v-if="
          productComposableBeingEdited && !showingExtras && !currentComponent
        "
      >
        <b-col cols="12" lg="9">
          <product-comments
            class="mt-3"
            :disabled="confirmed()"
            :product="productComposableBeingEdited"
            :comment="productComposableBeingEdited.comments"
            @refreshAlerts="refreshAlerts"
          ></product-comments>
        </b-col>
      </b-row>
    </div>
    <!--<div v-if="(!showingExtras && !currentComponent) || onlyHasOneSelector">
      <div v-if="confirmationAsked" class="text-muted mb-1 text-sm text-muted">
        {{ $t("info.incomplete_configuration") }}
      </div>
      <div v-if="preConfirmed() === false && confirmed() === false">
        <b-button
          v-if="confirmationAsked"
          variant="success"
          @click="addProduct"
        >
          <b-icon icon="check2-all" class="mr-1"></b-icon>
          <span>Confirmar</span>
        </b-button>
        <div v-else>
          <b-row class="justify-content-center">
            <b-col md="8" class="mt-3">
              <b-button
                v-if="!currentComponent && !showingExtras"
                :variant="
                  productComposableBeingEdited !== null ? 'primary' : 'success'
                "
                @click="addProduct"
                :disabled="processing"
                size="lg"
                block
                class="text-truncate"
              >
                <b-spinner
                  v-if="processing"
                  small
                  variant="success"
                ></b-spinner>
                <span
                  v-else
                  :class="
                    productComposableBeingEdited !== null
                      ? 'fas fa-edit'
                      : 'fas fa-plus'
                  "
                ></span>
                {{
                  productComposableBeingEdited !== null
                    ? $tc("title.updated_product", 1)
                    : $tc("title.add_products", 1)
                }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row class="justify-content-center">
        <b-col
          md="8"
          class="mt-3"
          v-if="
            productComposableBeingEdited !== null &&
            showPrice &&
            currentComponent == null &&
            !showingExtras &&
            preConfirmed() === false &&
            confirmed() === false
          "
        >
          <b-button
            variant="danger"
            :disabled="deleting"
            @click="deleteProduct"
            class="text-truncate"
            block
            size="lg"
          >
            <b-spinner v-if="deleting" small variant="success"></b-spinner>
            <span v-else class="fas fa-minus"></span>
            {{ $tc("title.removed_product", 1) }}
          </b-button>
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center"
        v-if="
          (isMobile && confirmed()) ||
          (isMobile &&
            productComposableBeingEdited !== null &&
            showPrice &&
            currentComponent == null &&
            !showingExtras)
        "
      >
        <b-col md="8" class="mt-3">
          <b-button
            variant="info"
            @click="hideComposableProductPanel"
            class="text-truncate"
            block
            size="lg"
          >
            <b-icon icon="cart-check"></b-icon>
            <span> {{ $t("title.see_account") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div>-->

    <div
      class="button-container"
      v-if="
        (preConfirmed() === false &&
          confirmed() === false &&
          !currentComponent &&
          preConfirmed() === false &&
          !showingExtras) ||
        (productComposableBeingEdited != null &&
          preConfirmed() === false &&
          confirmed() === false)
      "
    >
      <button-composable-product
        @addProduct="addProduct(product)"
        :isAccountEditable="isAccountEditable"
        @deleteProduct="deleteProduct(product)"
        :productComposableBeingEdited="productComposableBeingEdited"
        :processing="processing"
        :product="product"
        :processingDeleted="processingDeleted"
        :showingExtras="showingExtras"
        :currentComponent="currentComponent"
        :onlyHasOneSelector="onlyHasOneSelector"
        :confirmationAsked="confirmationAsked"
        :preConfirmed="preConfirmed"
        :confirmed="confirmed"
        :showPrice="showPrice"
        :deleting="deleting"
        @hideComposableProductPanel="hideComposableProductPanel()"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import ComposableProductSelector from "./ComposableProductSelector.vue";
import debounce from "lodash/debounce";
import {
  formatMoney,
  extractExtrasPrice,
  extractExtrasPriceInComponents,
} from "../../../js/utils.js";
import ProductComments from "../Product/ProductComment.vue";
import SpecialProduct from "../Product/SpecialProduct.vue";
import ButtonComposableProduct from "../Buttons/ButtonComposableProduct.vue";

export default {
  name: "composable-product",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    product: {
      type: Object,
      required: true,
    },
    nesting: {
      type: Number,
      default: 1,
    },
    hidePrice: {
      type: Boolean,
      default: false,
    },
    command: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentComponent: null,
      selections: null,
      confirmationAsked: false,
      showingExtras: false,
      extras: [],
      quantity: 1,
      showingQuantity: true,
      savingQuantity: false,
    };
  },
  components: {
    ComposableProductSelector,
    ProductComments,
    SpecialProduct,
    ButtonComposableProduct,
  },
  computed: {
    isAccountEditable() {
      return true;
      // return this.account.closed_at === null
      //   && this.account.locked_at === null
      //   && this.account.cancelled_at === null;
    },
    ...mapState({
      productComposableBeingEdited: (state) =>
        state.productComposableBeingEdited,
      productsBeingAdded: (state) => state.productsBeingAdded,
      productsBeingDeleted: (state) => state.productsBeingDeleted,
      accountSelected: (state) => state.command.accountSelected,
      imagePath: (state) => state.imagePath,
      isMobile: (state) => state.isMobile,
      processing: (state) => state.processing,
      processingDeleted: (state) => state.processingDeleted,
    }),
    qty() {
      const parsed = parseFloat(1);
      return isNaN(parsed) ? 1 : parsed;
    },
    components() {
      return this.product.composable_product_items;
    },
    price() {
      return formatMoney(this.product.current_price / 100);
    },
    extrasPrice() {
      return (
        (extractExtrasPrice(this.extras) +
          extractExtrasPriceInComponents(this.selections)) /
        100
      );
    },
    extrasPriceString() {
      return formatMoney(this.extrasPrice);
    },
    extrasQty() {
      return this.extras.reduce((acc, e) => acc + e.qty, 0);
    },
    isFull() {
      return this.product.composable_product_items.reduce(
        (full, group) => full && (group.keep_count || this.isCompleted(group)),
        true
      );
    },
    order() {
      return 100 + this.nesting;
    },
    showPrice() {
      return this.nesting === 1 && !this.hidePrice; // hidePrice for nesting product comming from accountList
    },
    saving() {
      return this.productsBeingAdded.includes(this.product.id);
    },
    deleting() {
      return this.productComposableBeingEdited
        ? this.productsBeingDeleted.includes(
            this.productComposableBeingEdited.id
          )
        : false;
    },
    onlyHasOneComponent() {
      return (
        this.product.composable_product_items.length === 1 &&
        this.product.extras.length === 0
      );
    },
    onlyHasExtras() {
      return (
        this.product.extras.length > 0 &&
        this.product.composable_product_items.length === 0
      );
    },
    onlyHasOneSelector() {
      return this.onlyHasOneComponent || this.onlyHasExtras;
    },
    description() {
      let description = "";
      if (this.product.description != null) {
        description = this.product.description;
      } else {
        description = this.product.purchasable.description;
      }
      return description;
    },
  },
  created() {
    let quantityProduct = this.productComposableBeingEdited
      ? (quantityProduct = this.productComposableBeingEdited.quantity)
      : this.quantity;
    this.quantity = parseFloat(quantityProduct);
  },
  mounted() {
    let quantityProduct = this.productComposableBeingEdited
      ? (quantityProduct = this.productComposableBeingEdited.quantity)
      : this.quantity;
    this.quantity = parseFloat(quantityProduct);
  },
  methods: {
    saveQuantity: debounce(async function (value) {
      if (this.productComposableBeingEdited != null) {
        this.savingQuantity = true;
        await this.updateProductSelected([
          "quantity",
          value,
          this.productComposableBeingEdited,
        ]);
        this.savingQuantity = false;
      }
    }, 500),
    confirmed() {
      let confirmed = false;
      if (this.productComposableBeingEdited != null) {
        confirmed = this.productComposableBeingEdited.confirmed ?? false;
      }
      return confirmed;
    },
    preConfirmed() {
      let preConfirmed = false;
      if (this.productComposableBeingEdited != null) {
        preConfirmed = this.productComposableBeingEdited.pre_confirmed ?? false;
      }
      return preConfirmed;
    },
    ...mapMutations(["resetAccumulator"]),
    ...mapMutations(["setComponentSelections", "setComponent"]),
    ...mapActions(["refreshAlerts", "updateProductSelected"]),
    formatMoney,
    hideComposableProductPanel() {
      if (this.productComposableBeingEdited != null) {
        this.$emit("close-panel");
      } else {
        this.$emit("close-panel");
        this.$store.commit("showProducts");
      }
    },

    selectComponent(cg) {
      this.currentComponent = cg;
      this.confirmationAsked = false;
      if (this.product.extras.length > 0) {
        this.showingQuantity = false;
      } else {
        this.showingQuantity = true;
      }
    },

    hideComponentGroupPanel() {
      // this.$set(this.selections, this.currentComponent.id, selections)
      // TODO: This should change
      if (
        this.onlyHasOneComponent &&
        this.preConfirmed === false &&
        this.confirmed === false
      ) {
        this.addProduct();
      } else {
        this.currentComponent = null;
        this.showingQuantity = true;
      }
    },
    selectExtras() {
      this.showingExtras = true;
      this.showingQuantity = false;
    },
    hideExtrasPanel() {
      // this.extras = selections.filter(s => s.product !== null);
      if (
        this.onlyHasExtras &&
        this.preConfirmed === false &&
        this.confirmed === false
      ) {
        this.addProduct();
      } else {
        this.showingExtras = false;
        this.showingQuantity = true;
      }
    },
    isCompleted(group) {
      return (
        group.maximum_number_of_products == this.totalSelectedFor(group.id)
      );
    },
    totalSelectedFor(groupId) {
      return this.selections[groupId].reduce((acc, el) => acc + el.qty, 0);
    },
    addProduct() {
      if (!this.isFull && !this.confirmationAsked) {
        this.confirmationAsked = true;
        return;
      }
      let price = this.product.current_price / 100;
      if (this.productComposableBeingEdited === null) {
        this.$emit("add-product", {
          product: this.product,
          open_table_id: this.command.open_table.id,
          account: this.accountSelected,
          price: price,
          quantity: this.quantity,
          image_path: this.product.image_path,
          table_qrcode: this.$root.table_qrcode,
          id: this.product.id,
          components: this.selections,
          extras: this.extras,
          prices: this.product.prices, // This is only for getPrice method in actions.js
        });
        console.log("selections:", this.selections, " extras:", this.extras);
        this.confirmationAsked = false;
      } else {
        let product = {
          components: this.selections,
          current_price: this.productComposableBeingEdited.current_price,
          extras: this.extras,
          id: this.productComposableBeingEdited.id,
          image_path: this.productComposableBeingEdited.image_path,
          name: this.productComposableBeingEdited.name,
        };
        this.$emit("update-product", {
          product: product,
          table_qrcode: this.$root.table_qrcode,
          id: this.productComposableBeingEdited.id,
          open_table_id: this.command.open_table.id,
          account: this.accountSelected,
          quantity: this.quantity,
          comments: this.productComposableBeingEdited.comments,
          price: price,
        });
        this.confirmationAsked = false;
      }
    },
    deleteProduct() {
      this.$emit("delete-product", {
        productId: this.productComposableBeingEdited.id,
        open_table_id: this.command.open_table.id,
        table_qrcode: this.$root.table_qrcode,
      });
    },
    updateExtras(selections) {
      this.extras = selections.filter((s) => s.product !== null);
    },
    updateComponents(selections) {
      this.$set(this.selections, this.currentComponent.id, selections);
    },
    /*getFullImagePath(product) {
      return this.tenantUrl != null ? this.tenantUrl + product.image_path : "";
    },*/
    getFullImagePath(product) {
      let image_path = product.image_path;
      this.$store.dispatch("getfullImagePath", image_path);
      return this.imagePath;
    },
  },
  watch: {
    product: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;

        this.selections = {};

        newValue.composable_product_items.forEach((composableItem) => {
          let selections = [];

          for (
            let i = 0;
            i < composableItem.maximum_number_of_product_types;
            i++
          ) {
            selections.push({
              product: null,
              qty: 0,
            });
          }

          this.$set(this.selections, composableItem.id, selections);
        });
      },
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.selections = { ...newValue.selection };
          this.extras = [...newValue.extras];
        }
      },
    },
  },
};
</script>

<style scoped>
.pad-number-indicator {
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-right: 6rem;
  margin-bottom: 1rem;
}
</style>
