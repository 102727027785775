<template>
  <div
    class="
      bg-white
      d-flex
      flex-md-column flex-nowrap
      p-3
      shadow
      flex-shrink-0
      overflow-hidden
      h-100
    "
  >
    <div
      class="
        d-flex
        flex-md-column flex-nowrap
        justify-content-center
        overflow-auto
        flex-grow-1
      "
    >
      <b-button
        v-if="!currentComponent && !showingExtras  &&  confirmed() === false  && preConfirmed() === false"
        :variant="productComposableBeingEdited !== null ? 'primary' : 'success'"
        @click="addProduct"
        :disabled="processing"
        size="lg"
      >
        <b-spinner v-if="processing" small variant="success"></b-spinner>
        {{
          productComposableBeingEdited !== null
            ? $tc("title.updated_product", 1)
            : $tc("title.add_products", 1)
        }}
      </b-button>

      <b-button
        v-if="
          productComposableBeingEdited !== null &&
          showPrice &&
          currentComponent == null &&
          !showingExtras &&
          preConfirmed() === false &&
          confirmed() === false
        "
        size="lg"
        variant="danger"
        :disabled="deleting"
        @click="deleteProduct"
      >
        <b-spinner v-if="deleting" small variant="success"></b-spinner>
        <span v-else class="fas fa-trash"></span>
        {{ $tc("title.removed_product", 1) }}
      </b-button>

      <!--<b-button
        v-if="
          (isMobile && confirmed()) ||
          (isMobile &&
            productComposableBeingEdited !== null &&
            showPrice &&
            currentComponent == null &&
            !showingExtras)
        "
        variant="info"
        @click="hideComposableProductPanel"
        size="lg"
      >-->
      <!--<b-icon icon="cart-check"></b-icon>-->
      <!--{{ $t("title.see_account") }}
      </b-button>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ButtonComposableProduct",
  props: [
    "isAccountEditable",
    "productComposableBeingEdited",
    "processing",
    "product",
    "processingDeleted",
    "showingExtras",
    "currentComponent",
    "onlyHasOneSelector",
    "confirmationAsked",
    "preConfirmed",
    "confirmed",
    "showPrice",
    "deleting",
  ],
  methods: {
    addProduct() {
      this.$emit("addProduct");
    },

    hideComposableProductPanel() {
      this.$emit("hideComposableProductPanel");
    },

    deleteProduct() {
      this.$emit("deleteProduct");
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
    }),
  },
};
</script>

<style scoped>
.btn-pad {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

@media screen and (min-width: 768px) and (max-height: 760px) {
  .btn-pad {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
