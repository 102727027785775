<template>
  <div class="d-flex flex-column flex-grow-1 overflow-hidden pr-md-3 position-relative flex-grow-1">
    <div class="text-dark d-flex justify-content-between d-md-none p-3 border-bottom">
        <h5 class="m-0 d-flex align-items-center pb-0" title="Ver cuenta">
            <!--<a class="clickeable d-inline-block mr-3" @click.prevent="hideProducts" v-if="isMobile">
                <i class="fas fa-arrow-left"></i>
            </a>-->
            <span>{{ $tc("title.product",2) }}</span>
        </h5>
    </div>

    <div class="form-row flex-grow-1 overflow-auto px-2 px-md-0  align-content-start" v-show="category==null">
        <div class="col-4 col-md-6 col-lg-4 col-xl-2 mb-3"  v-for="(cat,index) in category_list" :key="index">
            <b-card @click="categorySelected(cat)" class="clickeable category-text bg-category  card-menu text-center mt-3 ml-1 h-100">
                <span>{{cat.name}}</span>
            </b-card>
        </div>
        <div v-if="loadingCategories" class="d-flex position-absolute top-0 h-100 w-100 justify-content-center align-items-center" style="background: #ffffffa6;">
            <b-spinner variant="primary"></b-spinner>
        </div>
    </div>

    <!-- Be careful when the previous brother div remain in the DOM at the same time -->
    <div class="form-row mt-3 mb-1 flex-grow-1 overflow-auto px-2 px-md-0 align-content-start">
            <div class="col-4 col-md-6 col-lg-4 col-xl-2">
                <b-card title-tag="Categoría anterior" @click="returnCategory()" v-if="category!=null" class="product bg-subcategory text-center ml-1 text-white clickeable">
                    <i class="fas fa-arrow-left"></i> {{$t('title.back')}}
                </b-card>
            </div>
            <div v-for="(subCategory, key) in subCategories"
                :key="key+subCategory.id"
                :id="key+subCategory.id"
                class="col-4 col-md-6 col-lg-4 col-xl-2"
            >
                <figure @click="categorySelected(subCategory)"
                    class="card clickeable card-product category-text  card-menu">
                    <figcaption class="info-wrap">
                           
                    </figcaption>
                    <!--<div class="img-wrap">
                        <img width="100%" :src="subCategory.image ? $root.tenantUrl + '/img/products/'+subCategory.image : $root.tenantUrl + '/storage/products/default_category.png'">
                    </div>-->
                    <figcaption class="info-wrap">
                            <h6 class="title text-center product-text">{{subCategory.name}}</h6>
                    </figcaption>
                </figure>
            </div>
            <Package
                v-for="(pack,index) in package_list"
                :key="index"
                :product="pack" 
                :quantity="quantity" 
                @setQty="setQty"
                :command="command"
                :isMobile="isMobile"
            >
            </Package>
            <Product
                v-for="(product,index) in products"
                :key="index"
                :product="product" 
                :quantity="quantity" 
                @setQty="setQty" 
                :command="command"
                :isMobile="isMobile"
                >
            </Product>
    </div>
   
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Package from "./ProductPackage/Package.vue";
import Product from "./Product/Product.vue";
export default {
    name: "Products",
    props: ["command", "quantity"],
    components: {
        Package, Product 
    },
    methods: {
        ...mapMutations(['hideProducts']),
        ...mapActions(['returnCategory','categorySelected']), 
        setQty(value) {
            this.$emit('setQty', value);
        }
    },
    computed: {
        ...mapState({
            showingActionablePanel: state => state.showingActionablePanel,
            loadingCategories: state => state.loadingCategories,
            categories: state => state.categories,
            packages: state => state.packages,
            category: state=> state.category,
            categoryFather: state=> state.categoryFather,
            isMobile: state=>state.isMobile
        }),
        package_list() {
            let pack = [];
            if(this.category != null) {
                pack = this.category.packages;
            }
            return pack;
        },
        products() {
            let prods = [];
            if(this.category!=null) {
                prods = this.category.products;
            }
            return prods;
        },
        subCategories() {
            let sub = [];
            if(this.category != null) {
                sub = this.category.subcategories ?? this.category.sub_categories ;
            }
            return sub;
        },
        category_list() {
            let cats = [];
            if(this.packages.length > 0) {
                let pq = [{
                    name: this.$tc('title.package',2),
                    packages: this.packages
                }];
                cats = pq.concat(this.categories);
            } else {
                cats = this.categories;
            }

            return cats;
        }
    }
}
</script>

<style>
.legend-load-prods {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.position-inherit {
    position: inherit !important;
}
</style>