<template>
  <b-modal
    @hidden="cancel()"
    hide-footer
    centered
    
    v-model="modal"
    id="modal-lg"
    foote
    size="lg"
    class="d-flex flex-column p-4 overflow-auto"
   
  >
  
    <h5 class="mb-3">{{ $t("title.select_product_package")}} {{ name  }}  </h5>

    <div class="bg-gray-200 d-flex overflow-y-auto">
      <div
        v-for="(slotPosition, idx) in slotPositions"
        :key="`slot_selection_${idx}`"
        class="slot"
        @click="currentSlot = slotPosition"
      >
        <figure
          v-if="productsSelected[slotPosition]"
          class="clickeable card card-product mt-3"
          :class="{ 'active-slot': currentSlot == slotPosition }"
        >
          <div class="img-wrap">
            <img
              :src="
                $root.tenantUrl +
                '' +
                productsSelected[slotPosition].product.image_path
              "
            />
          </div>
          <figcaption class="info-wrap">
            <h6 class="title text-center">
              {{ productsSelected[slotPosition].product.name }}
            </h6>
          </figcaption>
        </figure>
        <figure
          v-else
          class="clickeable card card-product mt-3"
          :class="{ 'active-slot': currentSlot == slotPosition }"
        >
          <div class="img-wrap">
            <img :src="$root.tenantUrl + '/storage/products/default.png'" />
          </div>
          <figcaption class="info-wrap">
            <h6 class="title text-center">{{$t('title.select_product')}}</h6>
          </figcaption>
        </figure>
      </div>
    </div>

    <b-row class="mt-3">
      <div
        v-for="(option, idx) in options"
        :key="`slot_option_${idx}`"
        class="col-md-3 col-sm-4"
        @click="selectOption(option)"
      >
        <figure class="clickeable card card-product position-relative">
          <div
            v-if="
              productsSelected[currentSlot] &&
              productsSelected[currentSlot].id === option.id
            "
            class="
              position-absolute
              w-100
              h-100
              top-0
              left-0
              d-flex
              justify-content-center
              align-items-center
              bg-black-alpha-400
              z-index-10
            "
          >
            <b-icon icon="check" class="text-success" font-scale="3"></b-icon>
            <!-- Terminar marca para la opcion seleccionada -->
          </div>
          <div class="img-wrap">
            <img :src="$root.tenantUrl + '' + option.product.image_path" />
          </div>
          <figcaption class="info-wrap">
            <h6 class="title text-center">{{ option.product.name }}</h6>
          </figcaption>
        </figure>
      </div>
    </b-row>

    <div class="mt-3 text-right">
      <b-button
        variant="primary"
        :disabled="!isComplete || isSaving"
        @click="savePackage"
      >
        <b-icon
          icon="arrow-clockwise"
          animation="spin"
          v-if="isSaving"
        ></b-icon>
        Aceptar
      </b-button>
    </div>
  </b-modal>
 
</template>
<script>
export default {
  props: {
    isMobile:{
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    slots: {
      type: Object,
      required: true,
    },
    slotPositions: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: false,
      productsSelected: {},
      currentSlot: 1,
    };
  },

  methods: {
    selectOption(option) {
      this.$set(this.productsSelected, this.currentSlot, option);
    },
    savePackage() {
      this.$emit(
        "savePackage",
        this.slotPositions.map((p) => this.productsSelected[p])
      );
      this.productsSelected = {};
      this.currentSlot = 1;
    },
    cancel() {
      this.currentSlot = 1;
      this.productsSelected = {};
      this.$emit("cancel");
      this.$nextTick(() => {
          this.$bvModal.hide('modal-lg')
        })
    },
    setDefaultSlots() {
      this.productsSelected = {};
      this.slotPositions.forEach((p) => {
        if (this.slots[p].length === 1) {
          this.$set(this.productsSelected, p, this.slots[p][0]);
        } else {
          this.$set(this.productsSelected, p, null);
        }
      });
    },
  },
  computed: {
    options() {
      return this.slots[this.currentSlot];
    },
    isComplete() {
      return this.slotPositions.reduce((acc, p) => {
        return acc && this.productsSelected[p] !== null;
      }, true);
    },
  },
  watch: {
    show(value) {
      this.modal = value;
      if (value) {
        this.setDefaultSlots();
      }
    },
    slotPositions: {
      immediate: true,
      handler(positions) {
        positions.forEach((p) => {
          if (this.slots[p].length === 1) {
            this.$set(this.productsSelected, p, this.slots[p][0]);
          } else {
            this.$set(this.productsSelected, p, null);
          }
        });
      },
    },
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.productsSelected = newValue;
        }
      },
    },
  },
};
</script>

<style scoped>
.active-slot {
  border: 1px solid #3490dc !important;
  box-shadow: 0px 0px 4px #3490dc;
}

.slot {
  width: 150px;
  min-width: 150px;
  margin-left: 8px;
  margin-right: 8px;
}
</style>
