import Mutations from './mutations'
import Actions from './actions'

export default {
  state: {
    lang:'es',
    waiterAction: '',
    waiterActionData: [],
    tableName: '',
    closeOpenTable:false,
    isLoadingCommand: false,
    windowWidth: 0,
    category: null,
    categoryFather: [],
    panel: '',
    alerts: [],
    total: 0,
    totalProducts: 0,
    hotel: null,
    table: null,
    tenantUrl: null,
    tenantApiUrlBase: null,
    currentQRCode: null,
    productSelected: null,
    parentSelected: null,
    composableProductValue: null,
    productComposableSelected: null,
    productBeingEdited: null,
    productComposableBeingEdited: null,
    currentComponent: null,
    productsBeingAdded: [],
    productsBeingDeleted: [],
    showingActionablePanel: false,
    showingProducts: false,
    command: {
      open_table: {}
    },
    accountSelected: null,
    client: '',
    loadingCategories: false,
    packages: [],
    categories: [],
    distributionChannels: [],
    menuTemplate: 'Template',
    imagePath: '',
    processingDeleted: false,
    processing: false,
    automatic_preconfirm: false,
    buttonBillData: {
      totalProducts : 0,
      total:0,
    },
    payment_metotod: [],
    hasPaymentPlatform: false,
    tipsSettings: [],
  },
  getters: {
    isValidTable(state) {
      return state.table != null && typeof (state.table) != 'undefined' && Object.keys(state.table).length > 0;
    },
    distributionChannel(state) {
      if (state._distributionChannels != null && state._distributionChannels != [] && state.command.distribution_channel_id != null)
        return state._distributionChannels.find(d => d.id == state.command.distribution_channel_id);
      else return null;
    },
   
    alerts(state) {
      return typeof state.alerts === 'undefined' ? [] : state.alerts;
    },
    commandData(state) {
      return state.command;
      // return state._command;
    },
    accountSelected(state) {
      return state.command.accountSelected;
      // return state._command;
    },

    currentAccount(state) {
      let account = null;
      account = state.command.open_table.table_accounts.find(a => {
          return a.account == state.accountSelected;
      });
      return account;
    },
  

  },
  actions: Actions,
  mutations: Mutations,
}
