<template>
  <div
    class="
      bg-white
      d-flex
      flex-md-column flex-nowrap
      p-3
      shadow
      flex-shrink-0
      overflow-hidden
      h-100
      card-menu
      
    "
  >
    <div
      class="
        d-flex
        flex-md-column flex-nowrap
        justify-content-start
        overflow-auto
        flex-grow-1
      "
    >
      <b-button
        v-if="status"
        md-2
        variant="success"
        @click="payAccount()"
        class="flex-grow-1 mb-md-3 button-span-align"
        :disabled="isSaving"
      >
        <b-icon
          v-if="isSaving"
          icon="arrow-counterclockwise"
          animation="spin-reverse-pulse"
          font-scale="1"
        ></b-icon>
        <span>
          <b-icon
            class="fa-4x"
            icon="credit-card"
            style="width: 20px; height: 20px"
          ></b-icon>
          {{ $t("title.pay") }}</span
        >
        <span>{{ $t("title.total") }}:{{ formatMoney(total) }}</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { formatMoney } from "../../js/utils.js";
export default {
  name: "ButtonPay",
  props: ["status", "isSaving", "total"],
  methods: {
    payAccount() {
      this.$emit("payAccount");
    },
    formatMoney,
  },
  computed: {},
};
</script>

<style scoped>
.btn-pad {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

@media screen and (min-width: 768px) and (max-height: 760px) {
  .btn-pad {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
