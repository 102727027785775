var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white d-flex flex-md-column flex-nowrap p-3 shadow flex-shrink-0 overflow-hidden h-100"},[_c('div',{staticClass:"d-flex flex-md-column flex-nowrap justify-content-center overflow-auto flex-grow-1"},[(
        !_vm.productSelected.confirmed ||
        (!_vm.productSelected.confirmed && _vm.productBeingEdited != null &&
          _vm.productSelected.purchasable_type !== 'available_package_item')
      )?_c('b-button',{attrs:{"variant":_vm.productBeingEdited !== null ? 'primary' : 'success',"disabled":_vm.processing || _vm.productSelected.confirmed || !_vm.isAccountEditable,"size":"lg"},on:{"click":_vm.addProduct}},[(_vm.processing)?_c('b-spinner',{attrs:{"small":"","variant":"success"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.productBeingEdited !== null ? _vm.$tc("title.updated_product", 1) : _vm.$tc("title.add_products", 1))+" ")],1):_vm._e(),(
        !_vm.productSelected.confirmed &&
        _vm.productBeingEdited !== null &&
        _vm.productSelected.purchasable_type !== 'available_package_item'
      )?_c('b-button',{attrs:{"variant":"danger","disabled":_vm.processingDeleted || _vm.productSelected.confirmed || !_vm.isAccountEditable,"size":"lg"},on:{"click":_vm.deleteProduct}},[(_vm.processingDeleted)?_c('b-spinner',{attrs:{"small":"","variant":"success"}}):_c('span',{staticClass:"fas fa-trash"}),_vm._v(" "+_vm._s(_vm.$tc("title.removed_product", 1))+" ")],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }