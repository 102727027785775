<template>
  <div class="col-4 col-md-6 col-lg-4 col-xl-2 mb-3">
    <figure @click="packageSelected(product)" class="clickeable card card-product card-menu h-100 justify-content-between glow">
      <div class="img-wrap">
        <!-- <img :src="tenantUrl + product.image_path" />-->
        <!--<b-icon icon="folder-symlink-fill" font-scale="6"></b-icon>-->
      </div>
      <figcaption class="info-wrap">
        <h6 class="title text-center product-text">{{product.name}}</h6>
        <div class="price-wrap h6 text-center">
          <span class="price-new">
            <strong>$ {{ price.amount / 100}}</strong>
          </span>
        </div>
      </figcaption>

      <div class="d-flex position-absolute top-0 left-0 h-100 w-100 justify-content-center align-items-center shadow"  v-if="isAdding">
        <b-spinner small></b-spinner>
      </div>
    </figure>

    <package-modal @savePackage="savePackage"
      @cancel="cancel"
      :name="product.name"
      :slots="slots"
      :slot-positions="slotPositions"
      :show="show"
      :is-saving="isAdding"
      :isMobile="isMobile"
    ></package-modal>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy';
import PackageModal from "./PackageModal";
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Package',
  components: { PackageModal },
  props: ['product', 'quantity', 'command','isMobile'],
  data() {
    return {
      show: false,
      isAdding: false,
    };
  },
  computed: {
    ...mapGetters([
      'distributionChannel'
    ]),
    ...mapState({
        accountSelected:state => state.command.accountSelected
    }),
    slots() {
      return groupBy(this.product.available_package_items, item => item.slot);
    },
    slotPositions() {
      return Object.keys(this.slots).sort();
    },
    shouldSelectProducts() {
      return this.slotPositions.reduce((acc, position) => {
        return this.slots[position].length > 1 || acc;
      }, false);
    },
    price() {
      if (this.distributionChannel) {
        const channelPrice = this.product.prices.find(p => p.distribution_channel_id == this.distributionChannel.id);
        if (channelPrice) {
          return channelPrice;
        }
      }

      return this.product.current_price;
    },
   
  },
  methods:  {
    packageSelected(product) {
      if (this.shouldSelectProducts) {
        this.show = true;
      } else {
     
          this.$store.dispatch("showProductPanelPackage",product);
        //this.savePackage(this.slotPositions.map(p => this.slots[p][0]));
      }
    },
    async savePackage(slots) {
        let data = {
          available_package_id: this.product.id,
          price_id: this.price.id,
          items: slots.map(s => s.id),
          quantity: this.quantity,
          open_table_id: this.command.open_table.id,
          account: this.accountSelected,
          table_qrcode: this.$root.table_qrcode
        };
      this.isAdding = true;
        try {
          let res = await this.$http.post(this.$root.tenantApiUrlBase + `/api-v2/table-reservations/${this.$root.table_qrcode}/purchases/addPackage`, data);
          this.$emit('setQty', 1);
          this.show = false;
          
          console.log(res);
        } catch(e) {
          let aMessage = "Error: " + e.message;
          if (e.response && e.response.data && e.response.data.error) {
            aMessage = "Error: " + e.response.data.message;
          }
          if (e.response && e.response.status == 403) {
            aMessage = "Acción no permitida";
          }
          console.log(aMessage);
           this.isAdding = false;
        }

         this.isAdding = false;
    },
    cancel() {
      this.show = false;
    }
  }
}
</script>

<style>
.card-product .info-wrap {
    overflow: hidden;
    padding: 15px;
}
</style>