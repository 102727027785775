var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-list-group',_vm._l((_vm.purchases),function(product,index){return _c('b-list-group-item',{key:product.id,staticClass:"border-0 rounded-0 d-flex flex-column align-items-stretch pl-0 pr-0 py-2",attrs:{"button":"","active":_vm.productSelected && product.id === _vm.productSelected.id},on:{"click":function($event){$event.stopPropagation();return _vm.selectProduct(product, _vm.account)}}},[_c('div',{staticClass:"d-flex w-100 justify-content-end px-2"},[_c('span',{class:(product.purchasable_type === 'product' && product.amount > 0) ||
          product.purchasable_type === 'available_package'
            ? 'flex-grow-1 font-weight-bold  font-size-purchase'
            : 'flex-grow-1'},[(product.confirmed)?_c('i',{staticClass:"fa fa-check-circle text-success"}):_vm._e(),_vm._v(" "+_vm._s(_vm.keepCount ? `# ${index + 1}) ` : "")+_vm._s(product.purchasable.name)+" ")]),(product.purchasable_type !== 'composable_product_item')?_c('span',{staticClass:"qty-style text-right font-size-purchase"},[_vm._v(" "+_vm._s(product.amount == 0 ? " x " + product.quantity : _vm.formatMoney(product.amount / 100) + " x " + product.quantity))]):_vm._e(),(product.purchasable_type !== 'composable_product_item')?_c('div',{staticClass:"amount-style text-right font-size-purchase"},[_c('span',{staticClass:"qty-style text-right"},[_vm._v(_vm._s(product.amount == 0 ? "" : _vm.formatMoney((product.amount / 100) * product.quantity))+" ")])]):_vm._e()]),(
        (product.comments != null && product.comments != '') ||
        product.special_added.length > 0
      )?_c('div',{staticClass:"d-flex w-100 justify-content-between font-size-purchase"},[_c('span',{staticClass:"pl-4 text-light-gray"},[_c('i',{staticClass:"fa fa-comments-alt mr-2"}),_vm._v(" "+_vm._s(_vm.specialLabel(product.special_added) + (product.comments != "" && product.special_added.length > 0 ? ", " + product.comments : product.comments))+" ")])]):_vm._e(),(
        product.purchases.length > 0 &&
        (product.purchasable_type === 'product' ||
          product.purchasable_type === 'composable_product_item')
      )?_c('div',{staticClass:"pl-3"},[_c('b-collapse',{staticClass:"mt-2",attrs:{"id":_vm.collapseId(product)}},[_c('purchase-list',{attrs:{"account":_vm.account,"purchases":product.purchases,"level":_vm.level + 1,"keep-count":product.purchasable_type === 'composable_product_item' &&
            product.purchasable.keep_count}}),(
            product.purchases.length > 0 &&
            product.purchasable_type === 'product'
          )?_c('div',{staticClass:"list-group-item border-0 rounded-0 d-flex flex-column pl-3 py-1"},[_c('b-button',{attrs:{"variant":"primary","title":product.confirmed
                ? _vm.$t('title.see_product')
                : _vm.$t('title.edit_product')},on:{"click":function($event){$event.stopPropagation();return _vm.selectProductwithComponentes(product, _vm.account)}}},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(product.confirmed ? _vm.$t("title.see_product") : _vm.$t("title.edit_product")))])])],1):_vm._e()],1)],1):_vm._e(),(product.extras && product.extras.length > 0)?_c('div',{staticClass:"pl-3"},[_c('b-collapse',{staticClass:"mt-2",attrs:{"id":_vm.collapseId(product)}},[_c('span',{},[_vm._v(_vm._s(_vm.$tc("title.extras",2))+":")]),_c('purchase-list',{attrs:{"account":_vm.account,"purchases":product.extras,"level":_vm.level + 1}})],1)],1):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":_vm.collapseId(product)}},[(product.purchasable_type === 'available_package')?_c('b-list-group',{staticClass:"w-100"},_vm._l((product.purchases),function(packageProduct){return _c('b-list-group-item',{key:`purchase_product_${product.id}_${packageProduct.id}`,staticClass:"border-0 rounded-0 d-flex flex-column pl-3 py-1",class:{
            'bg-primary':
              _vm.productSelected && product.id === _vm.productSelected.id,
          },attrs:{"active":_vm.productSelected && packageProduct.id === _vm.productSelected.id},on:{"click":function($event){$event.stopPropagation();return _vm.selectProductPackage(packageProduct, product, _vm.account)}}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[(packageProduct.confirmed)?_c('i',{staticClass:"fa fa-check-circle text-success"}):_vm._e(),_vm._v(" "+_vm._s(packageProduct.purchasable.product.name)+" ")]),_c('div',[_vm._v("x "+_vm._s(packageProduct.purchasable.required_quantity))])]),(
              (packageProduct.comments != null &&
                packageProduct.comments != '') ||
              packageProduct.special_added.length > 0
            )?_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('span',{staticClass:"pl-4 text-light-gray"},[_c('i',{staticClass:"fa fa-comments-alt mr-2"}),_vm._v(" "+_vm._s(_vm.specialLabel(packageProduct.special_added) + (packageProduct.comments != "" && packageProduct.special_added.length > 0 ? ", " + packageProduct.comments : packageProduct.comments))+" ")])]):_vm._e()])}),1):_vm._e(),(
          (product.purchases.length > 0 &&
            product.purchasable_type === 'available_package') ||
          (product.extras.length > 0 && product.purchases.length == 0)
        )?_c('div',{staticClass:"list-group-item border-0 rounded-0 d-flex flex-column pl-3 py-1"},[_c('b-button',{attrs:{"variant":"primary","title":product.confirmed
              ? _vm.$t('title.see_product')
              : _vm.$t('title.edit_product')},on:{"click":function($event){return _vm.selectProductwithComponentes(product, _vm.account)}}},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(product.confirmed ? _vm.$t("title.see_product") : _vm.$t("title.edit_product")))])])],1):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }