<template>
  <div
    class="
      bg-white
      d-flex
      flex-md-column flex-nowrap
      p-3
      shadow
      flex-shrink-0
      overflow-hidden
      h-100
    "
  >
    <div
      class="
        d-flex
        flex-md-column flex-nowrap
        justify-content-start
        overflow-auto
        flex-grow-1
      "
    >
      <b-button
        v-if="status"
        md-2
        variant="success"
        @click="changeStatus()"
        class="flex-grow-1 mb-md-3"
        :disabled="isSaving"
      >
        <b-icon
          v-if="isSaving"
          icon="arrow-counterclockwise"
          animation="spin-reverse-pulse"
          font-scale="1"
        ></b-icon>
        {{ $t("title.confirm_products") }}
      </b-button>
      <b-button
        variant="primary"
        class="d-md-none ml-2"
        float="right"
        @click.prevent="showProducts"
        >{{ $tc("title.add_products", 2) }}</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonActions",
  props: ["status", "isSaving"],
  methods: {
    changeStatus() {
      this.$emit("changeStatus");
    },
    showProducts() {
      this.$store.commit("showProducts");
    },
  },
  computed: {},
};
</script>

<style scoped>
.btn-pad {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

@media screen and (min-width: 768px) and (max-height: 760px) {
  .btn-pad {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
