<template>
  <b-row class="mx-0 flex-grow-1 flex-shrink-1 flex-nowrap flex-basis-auto">
    <b-overlay
      class="col-lg-4 bg-white p-0"
      rounded="sm"
      :show="isLoadingCommand"
    >
      <Cart :table="table"/>
    </b-overlay>

    <div
      class="
        col-lg-8
        box
        bg-products
        d-none d-md-block
        position-fixed position-md-relative
        h-100 h-md-auto
        top-0
        px-0 px-md-3
        fixable
      "
      style="z-index: 10"
      :class="{ 'd-block': showingProducts || showingActionablePanel }"
    >
      <div class="d-flex flex-column h-100" v-if="showingActionablePanel">
        <!-- Dynamic component, "is" prop determine with component to render -->
        <component
          :is="panel"
          :key="productSelected ? productSelected.id : 0"
          :command="commandData"
          @refreshAlerts="refreshAlerts"
        >
        </component>
      </div>
      <div class="d-flex flex-column flex-md-row h-100" v-else>
        <Products
          class="flex-grow-1"
          :quantity="quantity"
          @setQty="setQty"
          :command="commandData"
        />
        <div class="bill-container" v-if="isMobile">
          <ButtonBill  ref="ButtonBill"></ButtonBill>
        </div>
      </div>
    </div>

    <vtr-alert :alerts="alerts"></vtr-alert>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
import Products from "./Products.vue";
import ProductPanel from "./Product/ProductPanel.vue";
import ProductPackagePanel from "./ProductPackage/ProductPackagePanel.vue";
import ButtonBill from "./Buttons/ButtonBill.vue";
import Cart from "./Cart.vue";
import ComposableProductPanel from "./ComposableProduct/ComposableProductPanel.vue";

export default {
  name: "Command",
  props: ["table"],
  data() {
    return {
      qty: 1,
      windowWidth: 0,
    };
  },
  components: {
    Cart,
    Products,
    ProductPanel,
    ButtonBill,
    ComposableProductPanel,
    ProductPackagePanel, 
  },
  created() {
    this.getProducts();
  },
  mounted() {
    this.$store.dispatch('buttonBillData');
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.detectMobile();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    detectMobile() {
      if (this.isMobile) {
        //this.$router.push('/MainMobile');
        this.$store.commit("showProducts");
        
      } else {
        //this.$router.push('/Main');
        this.$store.commit("hideProducts");
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getProducts() {
      this.$store.dispatch("getProducts");
    },
    setQty(value) {
      this.qty = value;
    },
    refreshAlerts(event) {
      this.alerts = [];
      this.alerts.push(event);
    },
  },
  computed: {
    ...mapState({
      showingActionablePanel: (state) => state.showingActionablePanel,
      showingProducts: (state) => state.showingProducts,
      panel: (state) => state.panel,
      loadingCategories: (state) => state.loadingCategories,
      productSelected: (state) => state.productSelected,
      alerts: (state) => state.alerts,
      total: (state) => state.total,
      totalProducts: (state) => state.totalProducts,
      isLoadingCommand: (state) => state.isLoadingCommand,
      commandData: (state) => state.command,
      accounts: (state) => state.command.open_table.table_accounts,
    
    }),

    isMobile() {
      let flag = false;
      if (
        screen.width <= 760 ||
        (this.windowWidth != 0 && this.windowWidth <= 760) ||
        /webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        console.log("windowWidth", this.windowWidth);
        console.log("screen.width", screen.width);
        flag = true;
        this.$store.commit("setIsMobile", flag);
      } else {
        this.$store.commit("setIsMobile", flag);
      }
      return flag;
    },

    quantity: {
      get() {
        return this.qty;
      },
      set(value) {
        this.qty = value;
      },
    },
  },
  watch: {
    windowWidth(newWidth) {
      //console.log(`it changed to ${newWidth} from ${oldWidth}`);
      this.windowWidth = newWidth;
      this.detectMobile();
    },
  },
};
</script>

<style>
</style>