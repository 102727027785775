<template>
  <div class="d-flex flex-column h-100 text-sm">
    <h5
      :class="
        !isMobile
          ? 'mb-1 d-flex justify-content-between p-5'
          : 'mb-3 d-flex justify-content-between'
      "
    >
      <span>
        <a
          href="#"
          class="d-inline-block mr-3"
          @click.prevent="hideProductPanel"
        >
          <i class="fas fa-arrow-left"></i>

          {{
            productBeingEdited != null
              ? $t("title.return_account")
              : $t("title.return_menu")
          }}
        </a>
      </span>
    </h5>
    <div class="d-flex flex-column p-3 overflow-auto">
      <b-row class="justify-content-center">
        <b-col
          md="8"
          v-if="productSelected.confirmed"
          class="text-center mb-3 text-success font-weight-bold"
        >
          <i class="fa fa-check-circle"></i>
          {{ $t("title.confirmed_product") }}
        </b-col>
        <b-col cols="12" lg="9">
          <b-overlay
            spinner-variant="primary"
            class="h-100 text-center mb-3 text-info font-weight-bold"
          >
            <figure
              class="card card-product pusheable h-100 justify-content-between"
            >
              <div class="img-wrap-product">
                <img :src="getFullImagePath(productSelected)" width="auto" />
                <h6 class="title text-center">
                  {{ nameProduct }}
                </h6>
                <span v-if="!productSelected.confirmed && isPackage">
                  <b-icon
                    icon="pencil"
                    @click="editPackage"
                    v-if="shouldSelectProducts"
                  ></b-icon>
                </span>
                <div class="price-wrap h6 text-center" v-if="description != ''">
                  <p class="title text-center">
                    {{ description }}
                  </p>
                </div>
                <div class="price-wrap h6 text-center">
                  <span class="price-new">
                    <strong>{{ formatMoney(price) }} </strong>
                  </span>
                </div>
              </div>

              <!-- price-wrap.// -->

              <!-- <div class="d-flex position-absolute top-0 h-100 w-100 justify-content-center align-items-center shadow"  v-if="isAdding">
                    <b-spinner small ></b-spinner>
                </div> -->
            </figure>
          </b-overlay>
        </b-col>
        <b-col
          cols="12"
          lg="9"
          clas="mt-3"
          v-if="productSelected.purchasable_type !== 'available_package_item'"
        >
          <b-form-group>
            <template #label>
              <span class="text-base">
                <i class="fa fa-spin fa-spinner" v-if="savingQuantity"></i
              ></span>
            </template>
            <vtr-lw-spinbutton
              v-model="quantity"
              :min="0.5"
              size="lg"
              :disabled="productSelected.confirmed"
            ></vtr-lw-spinbutton>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="9" v-if="productBeingEdited !== null">
          <b-form-group v-if="specials != ''">
            <template #label>
              <h5 class="text-base">{{ $t("title.special_requests") }}:</h5>
            </template>
            <special-product
              class=""
              :specials="specials"
              :command="command"
              :product="productSelected"
              :disabled="productSelected.confirmed"
            >
            </special-product>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="9" v-if="!isPackage && productBeingEdited != null">
          <product-comments
            :disabled="productSelected.confirmed"
            :product="productBeingEdited"
            :comment="productBeingEdited.comments"
            @refreshAlerts="refreshAlerts"
          ></product-comments>
        </b-col>
      </b-row>
    </div>

    <package-modal
      v-if="isPackage"
      :name="currentPackage && currentPackage.name"
      :show="showPackageModal"
      :slots="slots"
      :slot-positions="slotPositions"
      :value="packageValue"
      @cancel="showPackageModal = false"
      @savePackage="savePackage"
      :isMobile="isMobile"
    ></package-modal>

    <div
      class="button-container"
      v-if="
        (isMobile && !productSelected.confirmed) || !productSelected.confirmed
      "
    >
      <button-product
        @addProduct="addProduct(productSelected)"
        :isAccountEditable="isAccountEditable"
        @deleteProduct="deleteProduct(productSelected)"
        :productBeingEdited="productBeingEdited"
        :processing="processing"
        :productSelected="productSelected"
        :processingDeleted="processingDeleted"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductComments from "./ProductComment";
import SpecialProduct from "./SpecialProduct";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import PackageModal from "../ProductPackage/PackageModal.vue";
import { formatMoney } from "../../../js/utils.js";
import ButtonProduct from "../Buttons/ButtonProduct.vue";

export default {
  name: "ProductPanel",
  data() {
    return {
      savingPrice: false,
      savingQuantity: false,
      price: 0,
      quantity: 1,
      showPackageModal: false,
      editproduct: false,
    };
  },
  props: ["command"],
  components: {
    ProductComments,
    SpecialProduct,
    ButtonProduct,
    PackageModal,
  },
  mounted() {
    let priceProduct =
      this.productSelected.amount ?? this.productSelected.current_price;
    this.price = priceProduct / 100;
    let quantityProduct = this.productSelected.quantity ?? this.quantity;
    this.quantity = parseFloat(quantityProduct);
  },
  computed: {
    ...mapState({
      productSelected: (state) => state.productSelected,
      accountSelected: (state) => state.command.accountSelected,
      productBeingEdited: (state) => state.productBeingEdited,
      packages: (state) => state.packages,
      imagePath: (state) => state.imagePath,
      isMobile: (state) => state.isMobile,
      processingDeleted: (state) => state.processingDeleted,
      processing: (state) => state.processing,
    }),
    nameProduct() {
      let name = "";
      if (this.isPackageItem && this.productSelected) {
        name = this.productSelected.purchasable.product.name;
      } else if (this.productSelected.name) {
        name = this.productSelected.name;
      } else {
        name = this.productSelected.purchasable.name;
      }
      return name;
    },
    description() {
      let description = "";
      if (this.isPackageItem && this.productSelected) {
        description = this.productSelected.purchasable.product.description;
      } else if (this.productSelected.description != null) {
        description = this.productSelected.description;
      } else {
        description = this.productSelected.purchasable.description;
      }
      return description;
    },

    isPackage() {
      return this.productSelected.purchasable_type === "available_package";
    },
    isPackageItem() {
      return this.productSelected.purchasable_type === "available_package_item";
    },
    isProduct() {
      return this.productSelected.purchasable_type === "product";
    },
    isAccountEditable() {
      return true;
      // return this.account.closed_at === null
      //   && this.account.locked_at === null
      //   && this.account.cancelled_at === null;
    },
    currentPackage() {
      if (this.productSelected.purchasable_type !== "available_package") {
        return undefined;
      }

      return this.packages.find(
        (p) => this.productSelected.purchasable.id == p.id
      );
    },
    slots() {
      if (this.currentPackage) {
        return groupBy(
          this.currentPackage.available_package_items,
          (item) => item.slot
        );
      }

      return {};
    },
    slotPositions() {
      return Object.keys(this.slots).sort();
    },
    shouldSelectProducts() {
      return this.slotPositions.reduce((acc, position) => {
        return this.slots[position].length > 1 || acc;
      }, false);
    },
    packageValue() {
      if (this.isPackage) {
        return this.productSelected.purchases.reduce((acc, purchase) => {
          acc[purchase.purchasable.slot] = purchase.purchasable;
          return acc;
        }, {});
      }

      return {};
    },
    specials() {
      if (this.isProduct) {
        let specials = this.productSelected.purchasable
          ? this.productSelected.purchasable.specials
          : this.productSelected.specials;
        return specials;
      } else if (this.isPackageItem) {
        return this.productSelected.purchasable.product.specials;
      } else {
        return [];
      }
    },
    isAdding() {
      return this.productsBeingAdded.includes(this.product.id);
    },
  },
  methods: {
    ...mapActions(["updateProductSelected"]),
    saveQuantity: debounce(async function (value) {
      if (this.productBeingEdited != null) {
        this.savingQuantity = true;
        await this.updateProductSelected([
          "quantity",
          value,
          this.productSelected,
        ]);
        this.savingQuantity = false;
      }
    }, 500),

    hideProductPanel() {
      if (this.productBeingEdited == null) {
        this.$store.dispatch("hideProductPanel");
        this.$store.commit("showProducts");
      } else {
        this.$store.dispatch("hideProductPanel");
      }
    },

    addProduct(product) {
      if (this.productBeingEdited === null) {
        let data = {
          product: product,
          open_table_id: this.command.open_table.id,
          account: this.accountSelected,
          price: this.price,
          quantity: this.quantity,
          table_qrcode: this.$root.table_qrcode,
        };
        this.$store.dispatch("addProduct", data);
      } else {
        let data = {
          product: this.productBeingEdited,
          table_qrcode: this.$root.table_qrcode,
          id: this.productBeingEdited.id,
          open_table_id: this.command.open_table.id,
          account: this.accountSelected,
          quantity: this.quantity,
          comments: product.comments,
          price: this.price,
        };
        this.$store.dispatch("updateProduct", data);
        this.confirmationAsked = false;
      }
    },
    getFullImagePath(product) {
      let image_path = "";

      if (product.purchasable_type === "available_package_item") {
        image_path = product.purchasable.product.image_path;
      } else {
        image_path = product.purchasable
          ? product.purchasable.image_path
          : product.image_path;
      }
      this.$store.dispatch("getfullImagePath", image_path);
      return this.imagePath;
    },
    deleteProduct(product) {
      let data = {
        productId: product.id,
        open_table_id: this.command.open_table.id,
        table_qrcode: this.$root.table_qrcode,
      };
      this.$store.dispatch("deleteProduct", data);
    },
    editPackage() {
      this.showPackageModal = true;
    },
    async savePackage(slots) {
      const data = this.productSelected.purchases.map((purchase) => {
        const newSlot = slots.find((s) => s.slot === purchase.purchasable.slot);

        return {
          id: purchase.id,
          purchasable_id: newSlot.id,
        };
      });

      await this.updateProductSelected([
        "purchases",
        data,
        this.productSelected,
      ]);
      // this.updatePackageItems([this.productSelected.id, slots]);
    },
    refreshAlerts(event) {
      this.$emit("refreshAlerts", event);
    },
    formatMoney,
  },
};
</script>

<style scoped>
.custom-control-label {
  cursor: pointer !important;
}
</style>