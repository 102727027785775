<template>
  <b-container>
    <b-row class="container-fluid">
      <b-col
        v-for="(special, key) in specials"
        :key="product.id + '' + special.id"
        class="p-2 text-base"
        :cols="6"
      >
        <div class="px-2 py-3 border-top">
          <b-form-group>
            <template>
              <span class="text-base text-dark">{{ special.name }}</span>
            </template>
            <b-check
              class="mb-3"
              :id="'checkbox_' + product.id + '_' + key + '_' + accountSelected"
              :checked="selected(special)"
              @change="changeSpecialProduct(product, $event, special)"
              value="true"
              unchecked-value="false"
              :disabled="disabled"
              size="lg"
            >
            </b-check>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["product", "specials", "id", "disabled", "command"],
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    changeSpecialProduct(product, event, special) {
      if (product.confirmed == false) {
        if (event == "true") this.addSpecialProduct(special);
        else this.removeSpecialProduct(special);
      }
    },
    addSpecialProduct(special) {
      let data = {
        product: this.product,
        special: special,
        open_table_id: this.command.open_table.id,
        table_qrcode: this.$root.table_qrcode,
      };

      this.$http
        .post(
          this.$root.tenantApiUrlBase +
            `/api-v2/table-reservations/${this.$root.table_qrcode}/purchases/addSpecialProduct`,
          data
        )
        .then((r) => {
          console.log("SpecialProduct.Vue:addSpecialProduct:r", r);
        })
        .catch((e) => {
          let aMessage = "Error: " + e.message;
          this.$emit("alerts", {
            title: "Especiales",
            content: aMessage,
            type: "danger",
          });
        });
    },
    selected(special) {
      let sp = this.product.special_added.find((p) => {
        return p.id == special.id;
      });
      if (typeof sp != "undefined") return "true";
      return "false";
    },
    removeSpecialProduct(special) {
      let data = {
        product: this.product,
        special: special,
        open_table_id: this.command.open_table.id,
        table_qrcode: this.$root.table_qrcode,
      };
      this.$http
        .post(
          this.$root.tenantApiUrlBase +
            `/api-v2/table-reservations/${this.$root.table_qrcode}/specials-remove`,
          data
        )
        .then((r) => {
          console.log("SpecialProduct:removeSpecialProduct:r", r.data);
        })
        .catch((e) => {
          let aMessage = "Error: " + e.message;
          this.$emit("alerts", {
            title: "Especiales",
            content: aMessage,
            type: "danger",
          });
        });
    },
  },
  computed: {
    ...mapState({
      accountSelected: (state) => state.command.accountSelected,
    }),
  },
};
</script>