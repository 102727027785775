<template>
  <div class="product-col clickeable" @click="productSelect(product)">
      <figure   class="
          card
          card-menu
          clickeable
          card-product
          pusheable
          h-100
          justify-content-between
        ">
    <div class="product-image">
      <b-img
        class="u-image"
        :src="getFullImagePath(product)"
        rounded="circle"
        alt=""
      ></b-img>
    </div>
    <h4 class="u-text product-title">{{ product.name }}</h4>
    <h6 class="u-text product-price">
      {{ getPrice(product) | currency }} {{ "MXN" }}
    </h6>
      </figure>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "MenuProduct",
  components: {},
  props: {
    product: {
      require: true,
      default: null,
    },
    command: {
      require: true,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      isAdding: false,
    };
  },
  computed: {
    ...mapGetters(["distributionChannel"]),
    ...mapState({
      accountSelected: (state) => state.command.accountSelected,
      isMobile: (state) => state.isMobile,
      tenantUrl: (state) => state.tenantUrl,
    }),
  },
  methods: {
    getPrice(product) {
      let channelPrices;
      if (this.command.distribution_channel_id != null) {
        if (this.distributionChannel != null) {
          /*  if a channel is set, respect just prices for this channel */
          channelPrices = product.prices.filter(
            (p) => p.distribution_channel_id == this.distributionChannel.id
          );
          if (channelPrices.length == 0)
            channelPrices = product.prices.filter(
              (p) => p.distribution_channel_id == null
            );
        } else {
          /*  else, respect just prices for restaurant (null channel) */
          channelPrices = [{ amount: product.current_price }];
        }
      } else {
        if (product.prices.length > 0) {
          channelPrices = product.prices.filter(
            (p) => p.distribution_channel_id == null
          );
        } else {
          channelPrices = product.current_price;
        }
      }

      return channelPrices.length > 0
        ? channelPrices[channelPrices.length - 1].amount / 100
        : 0;
    },
    getFullImagePath(product) {
     
      return this.tenantUrl != null ? this.tenantUrl + product.image_path : "";
    },

    productSelect(product) {
     
      if (product.purchasable_type === "available_package") {
        this.$store.dispatch("showProductPanelPackage", product);
      } else if (
        product.composable_product_items.length > 0 ||
        product.extras.length > 0
      ) {
        this.$store.dispatch("showComposableProductPanel", product);
      } else {
        this.$store.dispatch("showProductPanel", product);
      }
    },
  },
};
</script>

<style scoped>
.product-col {
  position: relative;
  flex: 1;
  max-width: auto !important;
  padding: auto !important;
}

.product-image {
  text-transform: none;
  font-style: italic;
  margin: 32px 2px 0;
}

img.u-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.product-title {
  text-transform: none;
  font-style: italic;
  margin: 32px 2px 0;
}

.product-price {
  color: #f12c0e !important;
  font-weight: 700;
  margin: 20px 0 0;
}

.u-text {
  word-wrap: break-word;
  position: relative;
}
</style>