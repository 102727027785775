<template>
    <b-row class="mx-0 flex-grow-1 flex-shrink-1 flex-nowrap flex-basis-auto">
      <div
        class="
          col-lg-12
          box
          bg-products
          d-none d-md-block
          position-fixed position-md-relative
          h-100 h-md-auto
          top-0
          px-0 px-md-3
          fixable
          d-block
          overflow-x-hidden
        "
        style="z-index: 10"
      >
        <h5 class="mb-3 d-flex justify-content-between">
          <span>
            <a href="#" class="d-inline-block mr-3" @click.prevent="back">
              <i class="fas fa-arrow-left"></i>
              {{ $t("title.back") }}
            </a>
          </span>
        </h5>
        <div class="flex-grow-1 py-lg-5" v-if="!isSaving">
          <div class="d-flex flex-column h-100 text-sm">
            <div class="text-center pt-4 pb-0">
              <h4 class="text-info font-size-table-waiter">
                {{ $t("info.consumption_note") }}
              </h4>
              <h6 class="text-info font-size-table-waiter" v-text="getAccountTitle(account)"></h6>
            </div>
            <div class="d-flex justify-content-between align-items-center p-3">
              <!-- <h5 class="text-dark m-0" v-if="isToGo">{{ $tc('account_to_go') }}</h5>-->
              <h5 class="m-0 text-info font-size-table-waiter">
                {{ $tc("title.table", 1) }}: {{ tableName }}
              </h5>
              <!-- <h5 class="text-dark m-0" v-if="isToGo">{{ $tc('attended_by') }}: {{waiterName}}</h5> -->
              <h5 class="text-info m-0 font-size-table-waiter">
                {{ $tc("title.waiter", 1) }}: {{ waiterName }}
              </h5>
            </div>
  
            <b-table
              responsive
              head-variant="light"
              small
              :fields="fields"
              :items="purchases"
            >
              <template v-slot:cell(quantity)="data">
                {{ data.item.quantity }}
              </template>
              <template v-slot:cell(name)="data">
                {{
                  data.item.purchasable_type == "extra"
                    ? $tc("title.extras", 1) +
                      " " +
                      $t("title.of", 1) +
                      " " +
                      data.item.purchasable.name
                    : data.item.purchasable.name
                }}
              </template>
              <template v-slot:cell(price)="data">
                {{ productPrice(data.item) }}
              </template>
              <template v-slot:cell(amount)="data">
                {{ productAmount(data.item) }}
              </template>
            </b-table>
            <div class="d-flex align-items-center justify-content-center p-3">
              <span>{{ $t("info.suggested_tips") }}:</span>
            </div>
              <b-button-group>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    button-span-align
                  "
                >
                <b-button
                  pill
                  size="sm"
                  :variant="isSavingTip ? 'success' : 'outline-success'"
                  @click="saveTip()"
                  >{{ formatMoney(0) }}</b-button
                >
                <b-button
                  v-for="(tipSetting, index) in tipsSettings"
                  :key="`tip_${index}`"
                  pill
                  size="sm"
                  :variant="currentIndex == index ? 'success' : 'outline-success'"
                  @click="saveTipSetting(tipSetting, index)"
                  ><span class="font-size-purchase"
                    >{{ tipSetting.value }}%({{ tipSetting.name }}):{{
                      formatMoney(getTip(tipSetting))
                    }}</span
                  ></b-button
                >
                </div>
              </b-button-group>
              <div class="mr-2 ml-2">
                <b-input-group
                  size="sm"
                  class="mb-3"
                >
                  <b-input-group-prepend>
                    <b-button
                      @click="saveTip(true)"
                      :variant="(isSavingCustomTip || wasSavingCustomTip) ? 'success' : 'outline-success'"
                      size="sm"
                      text="Button"
                    >{{ $t("info.another") }}: $</b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    type="number"
                    step="0.01"
                    ref="customTipInput"
                    :disabled="!isSavingCustomTip"
                    v-model="decimalTip"
                    v-on:blur="customTipBlur"
                    v-on:keyup.enter.esc="customTipBlur"
                  ></b-form-input>
                </b-input-group>
              </div>
            
          </div>
  
          <div class="bill-container">
            <ButtonPay
              @payAccount="payAccount"
              :status="status"
              :isSaving="isSaving || isSavingTip || isSavingTipGood || isSavingTipExcelent || isSavingCustomTip"
              :total="total"
            />
          </div>
        </div>
        <Paypal
          v-if="order"
          :order="order"
          :paymentPlatforms="paymentPlatforms"
          @on-successful-payment="paySuccessful"
          :currentQRCode="currentQRCode"
        >
        </Paypal>
        <vtr-alert :alerts="alerts"></vtr-alert>
      </div>
    </b-row>
  </template>
  
  <script>
  import axios from "axios";
  import { mapState } from "vuex";
  import debounce from "lodash/debounce";
  import { formatMoney, getAccountTitle } from "../../js/utils.js";
  import ButtonPay from "./ButtonPay.vue";
  import Paypal from "./Paypal.vue";
  import { i18n } from "./../../i18n-setup";
  
  //import Hashids from "hashids";
  //const hashids = new Hashids("M0D3RN_R3574UR4N7-2021", 4);
  
  export default {
    name: "ConsumptionNote",
    //props: ["account"],
    props: {
      account: {
        type: Object,
        required: true,
      },
      company: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        isSaving: false,
        isSavingTip: false,
        isSavingCustomTip: false,
        isSavingTipGood: false,
        isSavingTipExcelent: false,
        wasSavingCustomTip: false,
        tipPaypal: {
          name: null,
          value: null,
          amount: 0
        },
        status: true,
        requestingInfo: false,
        alerts: [],
        order: null,
        purchases: [],
        paymentPlatforms: [],
        openBox: [],
        exchangeRateCode: "MXN",
        exchangeRateValue: 1,
        total: 0,
        currentIndex: null,
        paymentMethodId: null,
      };
    },
    components: {
      ButtonPay,
      Paypal,
    },
    mounted() {
      //this.total = this.getTotal(this.purchases);
      this.gethasPaymentPlatform();
      //this.changeAccount();
      console.log("Mounted", this.account, this.accountTitle);
      this.purchases = this.getPurchases();
      this.total = this.getTotal(this.purchases);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },
    computed: {
      ...mapState({
        hotel: (state) => state.hotel,
        //menuTemplate: (state) => state.menuTemplate,
        commandData: (state) => state.command,
        accounts: (state) => state.command.open_table.table_accounts,
        waiter: (state) => state.command.open_table.waiter,
        //waiterAction: (state) => state.waiterAction,
        //client: (state) => state.client,
        //closeOpenTable: (state) => state.closeOpenTable,
        //isLoadingCommand: (state) => state.isLoadingCommand,
        hasPaymentPlatform: (state) => state.hasPaymentPlatform,
        accountSelected: (state) => state.command.accountSelected,
        currentQRCode: (state) => state.currentQRCode,
        tipsSettings: (state) => state.tipsSettings,
        tableName: (state) => state.tableName,
        table: (state) => state.table,
      }),
      waiterName() {
        console.log("command", this.commandData);
        return this.commandData != null
          ? this.commandData.open_table.waiter.name
          : "";
      },
      fields() {
        let showOnSMClass = "d-none d-sm-table-cell";
        return [
          { key: "quantity", label: this.$t("title.quantity") },
          { key: "name", label: this.$tc("title.product") },
          {
            key: "price",
            label: this.$tc("title.price"),
            thClass: showOnSMClass,
            tdClass: showOnSMClass,
          },
          { key: "amount", label: this.$t("title.amount") },
          // {key:'quantity',label:'Cantidad'},
        ];
      },
      accountId() {
          return this.account.id
      },
      decimalTip: {
        get() {
          return this.tipPaypal.amount/100;
        },
        set(amount) {
          this.tipPaypal.amount = amount * 100;
        }
      }
    },
    methods: {
      formatMoney,
      getAccountTitle,
      customTipBlur() {
        //console.log("input unfocused", this.tipPaypal);
        const { name } = this.tipPaypal;
        const total = this.getTotal(this.purchases);
        this.total = total + this.tipPaypal.amount / 100;
        this.wasSavingCustomTip = true;
        this.tipPaypal = {
          name: name,
          purchasable: {
            name: name,
          },
          amount: this.tipPaypal.amount,
          quantity: 1,
        };
        this.saveTips();
      },
      getTip(tip) {
        let tip_good = 0;
        let total = this.getTotal(this.purchases);
        tip_good = (tip.value * total) / 100;
        return tip_good;
      },
      gethasPaymentPlatform() {
        if (this.hasPaymentPlatform) {
          this.getTipsSettings();
          this.getPaymentPlatforms();
          this.getOpenBox();
          this.getPaymentMethodId();
        } else {
          this.goMain();
        }
      },
      async getPaymentMethodId() {
        let name = "paypal";
        this.requestingInfo = true;
        this.$http
          .get(
            this.$root.tenantApiUrlBase +
              `/api-v2/table-accounts/` +
              this.currentQRCode +
              `/payment-method/` +
              name
          )
          .then((response) => {
            //let setting_id = 0;
            console.log("getPaymentMethodId", response);

            this.paymentMethodId = response.data.paymentMethodId.id;

            this.requestingInfo = false;
          })
          .catch((e) => {
            console.log("e", e);
            this.requestingInfo = false;
          });
      },
      async getOpenBox() {
        this.requestingInfo = true;
        this.$http
          .get(
            this.$root.tenantApiUrlBase +
              `/api-v2/open-box/` +
              this.currentQRCode +
              `/hotels/` +
              this.hotel.id
          )
          .then((response) => {
            //let setting_id = 0;
            console.log("OpenBox", response);
            this.openBox = response.data.open_box;
            this.requestingInfo = false;
          })
          .catch((e) => {
            console.log("e", e);
            this.requestingInfo = false;
          });
      },
      async getTipsSettings() {
        this.requestingInfo = true;
        this.$http
          .get(
            this.$root.tenantApiUrlBase +
              `/api-v2/tips-settings/` +
              this.currentQRCode +
              `/hotels/` +
              this.hotel.id
          )
          .then((response) => {
            //let setting_id = 0;
            this.tipsSettings.push(response.data.tips);

            this.$store.commit("setTipsSettings", response.data.tips);

            this.requestingInfo = false;
          })
          .catch((e) => {
            console.log("e", e);
            this.requestingInfo = false;
          });
      },
      async getPaymentPlatforms() {
        this.requestingInfo = true;
        this.$http
          .get(
            this.$root.tenantApiUrlBase +
              `/api-v2/payment-platforms/` +
              this.currentQRCode +
              `/hotels/` +
              this.hotel.id
          )
          .then((response) => {
            //let setting_id = 0;
            console.log("getPaymentPlatforms", response);
            this.paymentPlatforms = response.data.payment_platforms;
            //this.$store.commit("setTipsSettings", response.data.tips);
            this.requestingInfo = false;
          })
          .catch((e) => {
            console.log("e", e);
            this.requestingInfo = false;
          });
      },
      saveTips: debounce(function () {
        let amount = this.tipPaypal.amount != null ? this.tipPaypal.amount / 100 : 0;
        axios
          .put(
            this.$root.tenantApiUrlBase +
              `/api-v2/table-accounts/` +
              this.currentQRCode +
              `/save-tips`,
            {
              tips: amount,
              exchange_rate_code: "MXN",
              exchange_rate: "1.0",
              table_account_id: this.accountId,
            }
          )
          .then((r) => {
            this.isSavingTip = false;
            this.isSavingTipGood = false;
            this.isSavingTipExcelent = false;
            this.isSavingCustomTip = false;
            console.log(r);
          })
          .catch((e) => {
            this.isSavingTip = false;
            this.isSavingTipGood = false;
            this.isSavingTipExcelent = false;
            this.isSavingCustomTip = false;
            console.log(e);
          });
      }, 500),
      back() {
        this.saveTip();
        if (this.make_payment) {
          if (this.isSaving) {
            this.isSaving = false;
          } else {
            this.make_payment = false;
          }
        } else {
          this.goMain();
        }
      },
      goMain() {
        this.saveTip();
        this.$router.push({
          name: "Home",
          params: { id: this.$root.table_qrcode },
        });
      },
      getPurchases() {
        let purchases = [];
        //console.warn("log account warn", this.account);
        //console.log("account.purchases", this.account.purchases);
        this.account.purchases.map((p) => {
            purchases.push(p);
            if (p.extras.length > 0) {
            p.extras.map((e) => {
                purchases.push(e);
            });
            }
        });
        return purchases;
      },
      productPrice(purchase) {
        let amount = purchase.amount / 100;
        return formatMoney(amount);
      },
      productAmount(purchase) {
        let amount = (purchase.amount * purchase.quantity) / 100;
        return formatMoney(amount);
      },
      payAccount() {
        this.makeOrderForPayment();
        this.isSaving = true;
      },
      makeOrderForPayment() {
        const { client } = this.account;
        let address = null;
        console.log("current client", client);
        if(client) {
          address = client.client_addresses.pop()
        }
        //console.error("tipPaypal", this.tipPaypal);
        this.order = {
          id: this.accountId,
          currency: {
            code: "MXN",
          },
          items: this.purchases,
          tipPaypal: this.tipPaypal,
          discount: 0,
          tax_total: 0,
          amount_total: this.total,
          item_total: this.total,
          persons: { //TODO adapt to the actual client
            first_name: client ? client.name : null,
            last_name: client ? client.last_name : null,
            email: client ? client.email_1 : null,
            postal_code: address ? address.zip_code : null,
            //phone: 234234
          },
          /*{
                email_address:
                  this.order != null ? this.order.persons.email : "",
                name: {
                  given_name:
                    this.order != null ? this.order.persons.first_name : "",
                  surname:
                    this.order != null ? this.order.persons.last_name : null,
                },
                address: {
                  country_code: "MX",
                  postal_code: this.order != null ? this.order.persons.postal_code : null,
                },
              }*/
        };
      },
      getTotal(purchases) {
        const products = purchases.reduce(
          (acc, purchase) => acc + (purchase.amount / 100) * purchase.quantity,
          0
        );
        let total = products;
        return total;
      },
      saveTip(custom = false) {
        this.currentIndex = null;
        this.isSavingCustomTip = custom;
        this.wasSavingCustomTip = custom;
        this.isSavingTip = !custom;
        this.isSavingTipGood = false;
        this.isSavingTipExcelent = false;
        if(custom) {
          this.tipPaypal.name = this.$t("info.specific_tip");
          this.$nextTick(() => {
              this.$refs.customTipInput.focus()
          })
        } else {
          let total = this.getTotal(this.purchases);
          this.total = total + 0;
          this.tipPaypal = {
            name: null,
            value: null,
            amount: 0
          };
          this.saveTips();
        }
      },
      saveTipSetting(tip, index) {
        this.isSavingTipGood = true;
        this.isSavingTipExcelent = true;
        console.log("saveTipSetting", tip, index);
        this.currentIndex = index;
        this.isSavingTip = false;
        this.isSavingCustomTip = false;
        this.wasSavingCustomTip = false;
        let amount = this.getTip(tip);
        let total = this.getTotal(this.purchases);
        this.total = total + amount;
        this.tipPaypal = {
          purchasable: {
            name: tip.name,
          },
          amount: amount * 100,
          quantity: 1,
        };
        this.saveTips();
      },
      paySuccessful(successful) {
        if (successful) {
          this.$swal.close();
          this.isSaving = false;
          this.addPayment();
          this.deleteAssigmentTable();
          this.closeAccount();
        }
      },
      async addPayment() {
        try {
          let timerInterval;
          let title = i18n.tc("title.account", 1);
          let text = i18n.t("messages.wait_save_pay_account");
          this.$swal({
            title: title,
            timer: 30000,
            html: text,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          const params = {
            table_account_id: this.accountId,
            payment_method: this.paymentMethodId,
            card_type: null,
            terminal: null,
            quantity: this.total,
            tips: 0, // TODO fix tips
            currency: "MXN",
            discount: 0, // TODO fix discount
            // discount_type: 'percent',
            discount_type: "percent", //TODO fix discount
            discount_comments: "", //TODO fix discount
            auth: null,
            reference: null,
            digits: null,
            box_id: this.openBox.id,
            box: this.openBox,
            exchange_rate_code: "MXN",
            exchange_rate: "1.0",
          };

          console.log("params", params, this);
          

          const response = await axios.post(
            this.$root.tenantApiUrlBase +
              "/api-v2/table-accounts/" +
              this.currentQRCode +
              "/save-payment",
            params
          );

          console.log("response", response);
          this.$swal.close();
          //this.$emit("on-successful-payment", true);
        } catch (error) {
          this.$swal({
            title: "Información de Pago",
            text: error.response.data.message,
            icon: "error",
          });

          this.$emit("on-error-payment", false);
        }
      },
      async closeAccount() {
        try {
          let title = i18n.tc("title.account", 1);
          let text = i18n.t("messages.thanks_close_account");
          let timerInterval;

          this.$swal.fire({
            title: title,
            html: text,
            timer: 30000,
            didOpen: () => {
              this.$swal.showLoading();
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          });

          let sale_total = this.getTotal(this.purchases);

          const params = {
            table_account_id: this.accountId,
            box: this.openBox.box_id,
            open_box_id: this.openBox.id,
            balance: 0,
            exchange_rate_code: this.exchangeRateCode,
            exchange_rate: this.exchangeRateValue,
            sale_total: sale_total * 100,
            hotel_id: this.hotel.id,
            company_id: this.company.id,
          };

          console.log("params", params);

          const response = await axios.post(
            this.$root.tenantApiUrlBase +
              "/api-v2/table-accounts/" +
              this.currentQRCode +
              "/close",
            params
          );
          console.log("response", response);
        } catch (error) {
          let title = i18n.tc("account", 1);
          console.log("closeaccount", error);
          this.$swal({
            title: title,
            text: error.response.data.message,
            icon: "error",
          });

          //this.$emit("on-error-payment", false);
        }
      },

      async deleteAssigmentTable() {
        try {
          console.log("table", this.table);
          const params = {
            table_id: this.table.id,
            user_id: this.waiter.id,
          };

          axios
            .put(
              this.$root.tenantApiUrlBase +
                "/api-v2/table-reservations/" +
                this.currentQRCode +
                "/delete-assigment",
              params
            )
            .then((r) => {
              console.log("deleteAssigments:", r);
            })
            .catch((e) => {
              let aMessage = "Error: " + e.message;
              console.log("deleteAssigments:Error", aMessage);
              this.getTableAssigments();
            });
        } catch (error) {
          console.log("closeaccount", error);
        }
      },
    },
    watch: {
      //TODO
    },
  };
  </script>