<template>
  <div class="not-found-section">
      <h1 class="text-center text-warning">404</h1>
      <h2>Not found</h2>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>