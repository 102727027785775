<template>
  <div class="">
    <div
      class="
        bg-gray-200 p-2 rounded d-flex justify-content-between
      "
    >
      <b-form-radio
        @change="changeAccountSelected($event)"
        v-model="_account"
        name="some-radios"
        :value="split.account"
        :disabled="disableAccount(split)"
      >
        <strong class="text-dark"
          >{{ $tc("title.account", 1) }}
          {{ String.fromCharCode(64 + parseInt(split.account)) }}
          {{ split.label ? "- " + split.label : "" }}
          {{
            split.closed_at
              ? `(${$t("closed")})`
              : split.locked_at
              ? `(${$t("blocked")})`
              : ""
          }}
          {{ split.cancelled_at ? `(${$t("canceled")})` : "" }}</strong
        >
        <!--                    <strong>Cuenta {{split.folio.consecutive.toString().padStart(4, "0")}} {{split.label ? '- ' + split.label : ''}} {{split.closed_at ? '(CERRADA)' : split.locked_at ? '(BLOQUEADA)' : ''}} {{split.cancelled_at ? '(CANCELADA)' : ''}}</strong>-->
      </b-form-radio>
      <div>
        {{ $t("title.total") }}: <strong>{{ formatMoney(total) }}</strong>
      </div>
    </div>
    
    <purchase-list
      :purchases="productOptions"
      :account="accountSplit"
    ></purchase-list>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { formatMoney, disableAccount } from "../../js/utils.js";
import PurchaseList from "./PurchaseList.vue";

export default {
  props: ["account", "split"],
  data() {
    return {
      labelEditing: null,
      example: [{ name: "Nombre 1" }, { name: "Nombre 2" }],
    };
  },
  beforeMount() {
    this.changeAccount();
  },
  components: { PurchaseList },
  methods: {
    ...mapActions(["showSplitAccountPSpecialProductanel", "showProductPanel"]),
    formatMoney,
    disableAccount,
    deleteProduct(item) {
      this.$store.dispatch("deleteProduct", item);
    },

    addQuantity(product) {
      this.$store.dispatch("addQuantity", [product, this.split]);
    },
    restQuantity(product) {
      this.$store.dispatch("restQuantity", [product, this.split]);
    },
    changeSpecialProduct(product, special, event) {
      if (event == "true")
        this.$store.dispatch("addSpecialProduct", [product, special]);
      else this.$store.dispatch("removeSpecialProduct", [product, special]);
    },
    changeAccount() {
      if(this.accountSelected) {
        return;
      }
      if (this.accounts != null) {
        const able = this.accounts.find(a => !disableAccount(a))
        this.$store.commit("changeAccountSelected", able.account);
      }
    },
    changeAccountSelected(event) {
      this.$store.dispatch("changeAccountSelected", [event]);
      this.$store.dispatch("buttonBillData");
    },
    showLabelModal() {
      this.labelEditing = this.split.label || this.split.account;
      this.$refs["labelModal"].show();
    },
    resetLabel() {
      this.labelEditing = null;
    },
    async changeLabel() {
      await this.$store.dispatch("changeLabel", [
        this.split,
        this.labelEditing,
      ]);
      this.$refs["labelModal"].hide();
    },
    specialLabel(specials) {
      return specials.reduce((c, e) => {
        return c != "" ? c + ", " + e.name : e.name;
      }, "");
    },
  },
  computed: {
    productOptions() {
      return this.split.purchases;
    },

    accountSplit() {
      return this.split.account;
    },
    fields() {
      return [
        // {key: 'id',label:''},
        { key: "name", label: "Nombre", class: "w-60" },
        // { key: 'name', label:'Nombre', class:'w-50' },
        // {key: 'quantity',label:'Cantidad',sortable: true,class:'w-25'},
        // {key: 'amount',label:'Costo',sortable: true,class:'w-25'},
        { key: "show_details", label: " ", class: "w-20 text-right" },
        { key: "delete", label: " ", class: "w-20 text-right" },
      ];
    },
    isAccountEditable() {
      return (
        this.split.closed_at === null &&
        this.split.locked_at === null &&
        this.split.cancelled_at === null
      );
    },
    ...mapGetters(["products", "statusConfirm"]),
    ...mapState({
      productSelected: (state) => state.productSelected,
       accounts: (state) => state.command.open_table.table_accounts,
      accountSelected: (state) => state.command.accountSelected,
      commandData: (state) => state.command,
    }),
    _account: {
      get() {
        return this.accountSelected || this.commandData.accountSelected;
      },
      set(value) {
        this.$store.commit("changeAccountSelected", [value]);
      },
    },
    total() {
      const extras = this.productOptions.reduce(function reducer(
        acc,
        purchase
      ) {
        return (
          acc +
          purchase.extras.reduce(
            (acc, extra) => acc + parseInt(extra.amount / 100)  * extra.quantity,
            0
          ) +
          purchase.purchases.reduce(reducer, 0)
        );
      },
      0);
      const products = this.productOptions.reduce(
        (acc, purchase) => acc + (purchase.amount / 100) * purchase.quantity,
        0
      );
      return products + extras ;
    },
  },
};
</script>
<style>
.hidden_header > table > thead {
  display: none;
}
</style>
