<template>
  <div class="d-flex flex-column justify-content-between h-100 text-sm">
    <div class="d-flex flex-column overflow-hidden">
      <h4 class="px-3 py-2 text-primary text-left restaurant-title">
        <span>
          <b-link class="d-inline-block mr-3" @click.prevent="goMain"
            ><i class="fas fa-arrow-left"></i
          ></b-link>
         
        </span>
      </h4>
      <div class="d-flex justify-content-between align-items-center p-3">
        <!-- <h5 class="text-dark m-0" v-if="isToGo">{{ $tc('account_to_go') }}</h5>-->
        <h5 class="text-dark m-0">
          {{ $tc("title.table", 1) }}: {{ tableName }}
        </h5>
        <!-- <h5 class="text-dark m-0" v-if="isToGo">{{ $tc('attended_by') }}: {{waiterName}}</h5> -->
        <h5 class="text-dark m-0">
          {{ $tc("title.waiter", 1) }}: {{ waiterName }}
        </h5>
      </div>

      <div class="row border-top border-bottom px-3 py-2">
        <b-form-group
          label-cols="6"
          label-cols-lg="6"
          label-size="md"
          label="Canal de Distribución"
          label-for="input-sm"
          class="col m-0"
          v-if="false"
        >
          <b-input-group class="">
            <!-- <b-form-select :options="distributionChannelOpts" :value="command.distribution_channel_id" @change="setDistributionChannel"></b-form-select> -->
          </b-input-group>
        </b-form-group>
        <!-- <b-form-group
          label-cols="6"
          label-cols-lg="6"
          label-size="md"
          :label="$t('title.client')"
          label-for="input-sm"
          class="col m-0"
          v-if="true"
        >
        <h5 class="text-dark m-0">
          {{client}}
        </h5>
          
        </b-form-group>-->
        <b-form-group
          label-cols="6"
          label-cols-lg="6"
          label-size="md"
          :label="$t('title.number_of_accounts')"
          label-for="input-sm"
          class="col m-0"
          v-if="true"
        >
          <b-input-group class="">
            <b-form-input
              class="text-center"
              readonly
              :value="splitAccount"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
      <div class="accounts flex-grow-1 overflow-y-auto overflow-x-hidden p-2">
        <AccountList
          v-for="(account, index) in tableAccounts"
          :key="index"
          :split="account"
        />
      </div>
    </div>
    <div class="bill-container">
      <ButtonActions
        @changeStatus="changeStatus"
        :status="status"
        :isSaving="isSaving"
      />
    </div>
  </div>
</template>

<script>
import AccountList from "./AccountList.vue";
import ButtonActions from "./Buttons/ButtonActions.vue";
import { mapState } from "vuex";
export default {
  name: "Cart",
  props: ["table"],
  data() {
    return {
      isSaving: false,
    };
  },
  components: {
    AccountList,
    ButtonActions,
  },
  methods: {
    changeStatus() {
      this.isSaving = true;
      this.$http
        .post(
          this.$root.tenantApiUrlBase +
            `/api-v2/table-reservations/${this.$root.table_qrcode}/purchases-preconfirmation`,
          {
            command_id: this.command.id,
            open_table_id: this.command.open_table.id,
            table_qrcode: this.$root.table_qrcode,
          }
        )
        .then((res) => {
          this.isSaving = false;
          this.$store.commit('setAutomaticPreconfirm', true);
          this.goMain();
          console.log("Cart.Vue:changeStatus:res", res);
        })
        .catch((e) => {
          let aMessage = this.$t("messages.error_confirming_products");
          let message = "";
          if (e.response && e.response.data.message) {
            message = e.response.data.message;
          }
          this.makeToast("danger", "Comanda", aMessage + message);
          this.isSaving = false;
        });
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    goMain() {
      this.$router.push({
        name: "Home",
        params: { id: this.$root.table_qrcode },
      });
    },
    showProducts() {
      this.$store.commit("showProducts");
    },
  },
  computed: {
    ...mapState({
      hotel: (state) => state.hotel,
      total: (state) => state.total,
      command: (state) => state.command,
      tableName: (state) => state.tableName,
      client: (state) => state.client,
      tableAccounts: (state) => state.command.open_table.table_accounts,
      isMobile: (state) => state.isMobile,
    }),

    waiterName() {
      console.log("command", this.command);
      return this.command != null ? this.command.open_table.waiter.name : "";
    },
    splitAccount() {
      return this.tableAccounts.length ?? 0;
    },
    status() {
      var status = false;
      if (
        this.command != null &&
        this.tableAccounts.length > 0 &&
        this.tableAccounts[0].purchases.length > 0
      ) {
        status = this.tableAccounts.reduce((b, account) => {
          return account.purchases.reduce((b2, p) => {
            return (!p.confirmed && !p.pre_confirmed) || b2;
          }, b);
        }, false);
      }
      return status;
    },
  },
};
</script>

<style>
</style>