<template>
  <div class="d-flex flex-grow-1 overflow-hidden app-content-left p-2">
    <b-row class="mx-0 flex-grow-1 flex-shrink-1 flex-nowrap flex-basis-auto">
      <b-overlay
        class="col-lg-4 col-md-4 bg-white p-0"
        rounded="sm"
        :show="isLoadingCommand"
      >
        <Cart :table="table" />
      </b-overlay>
      <div
        class="
          col-lg-8
          box
          bg-products
          d-none d-md-block
          position-fixed position-md-relative
          h-100 h-md-auto
          top-0
          px-0 
          fixable
          overflow-x-hidden
        "
        style="z-index: 10"
        :class="{ 'd-block': showingProducts || showingActionablePanel }"
      >
        <div class="d-flex flex-column h-100" v-if="showingActionablePanel">
          <component
            :is="panel"
            :key="productSelected ? productSelected.id : 0"
            :command="commandData"
            @refreshAlerts="refreshAlerts"
          >
          </component>
        </div>

        <div class="d-flex flex-column h-100" v-else>
          <CategoryNavigation :isMobile="isMobile" ref="CategoryNavigation" />
          <section class="main main-scroll">
            <b-row
              v-if="loadingCategories"
              class="
                d-flex
                justify-content-center
                align-items-center
                flex-column
              "
              style="height: 80vh"
            >
              <b-spinner
                variant="primary"
                class="mb-2"
                style="width: 4rem; height: 4rem"
              ></b-spinner>
              <h2 class="u-text">{{ $t("info.loading_menu") }}</h2>
            </b-row>
            <b-row
              v-else
              class="
                form-row
                flex-grow-1
                overflow-hidden
                px-2 px-md-0
                align-content-start
              "
            >
              <b-container class="mt-4 my-4" fluid>
                <b-row
                  >
                  <b-col cols="12" class="">
                   
                    <h2 class="u-text main-menu-title">
                      {{ $t("title.menu") }}
                    </h2>

                    <div
                      class="menu-products"
                      v-for="(cat, i) in category_list"
                      :key="`category_product${i}`"
                      :id="`category-${cat.id}`"
                    >
                      <div
                        class="
                          d-flex
                          justify-content-between
                          mt-3
                          category-name-wrap
                        "
                      >
                        <h3 class="tex-category">{{ cat.name }}</h3>
                      </div>

                      <div
                        class="product-container"
                        v-if="!cat.packages && cat.products.length > 0"
                      >
                        <div
                          class="product-row"
                          v-for="(prod, ii) in productList(cat)"
                          :key="`product_item${ii}`"
                        >
                          <menu-product :product="prod" :command="commandData">
                          </menu-product>
                        </div>
                      </div>
                      <div v-if="cat.packages">
                        <div class="product-container">
                          <div
                            class="product-row"
                            v-for="(pack, jj) in cat.packages"
                            :key="`product_item${jj}`"
                          >
                            <menu-package
                              :product="pack"
                              :command="commandData"
                            ></menu-package>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--<a class="btnAllProds" @click="toggleTemplate">{{$t('title.next_template')}}</a>-->
                  </b-col>
                </b-row>
              </b-container>
            </b-row>
          </section>
          <div v-if="isMobile" class="bill-container-template">
            <ButtonBill ref="ButtonBill"></ButtonBill>
          </div>
        </div>
      </div>
      <vtr-alert :alerts="alerts"></vtr-alert>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ButtonBill from "../Buttons/ButtonBill.vue";
import CategoryNavigation from "./CategoryNavigation.vue";
import ProductPanel from "../Product/ProductPanel.vue";
import ComposableProductPanel from "../ComposableProduct/ComposableProductPanel.vue";
import Cart from "../Cart.vue";
import MenuPackage from "./MenuProduct/MenuPackage.vue";
import MenuProduct from "./MenuProduct/MenuProduct.vue";
import ProductPackagePanel from "../ProductPackage/ProductPackagePanel.vue";

export default {
  props: {
    isMobile: null,
    table: null,
    variant: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {};
  },
  components: {
    ButtonBill,
    Cart,
    ProductPanel,
    ComposableProductPanel,
    CategoryNavigation,
    MenuPackage,
    ProductPackagePanel,
    MenuProduct,
  },
  mounted() {
    this.$store.dispatch("getProducts");
    this.$store.dispatch("buttonBillData");
  },
  computed: {
    ...mapState({
      showingProducts: (state) => state.showingProducts,
      showingActionablePanel: (state) => state.showingActionablePanel,
      alerts: (state) => state.alerts,
      productSelected: (state) => state.productSelected,
      tenantUrl: (state) => state.tenantUrl,
      hotel: (state) => state.hotel,
      loadingCategories: (state) => state.loadingCategories,
      categories: (state) => state.categories,
      total: (state) => state.total,
      panel: (state) => state.panel,
      commandData: (state) => state.command,
      packages: (state) => state.packages,
      category: (state) => state.category,
      isLoadingCommand: (state) => state.isLoadingCommand,
      productComposableSelected: (state) => state.productComposableSelected,
    }),
    products() {
      return this.$_recursiveProducts(this.categories, []);
    },

    category_list() {
      let cats = [];
      if (this.packages.length > 0) {
        let pq = [
          {
            id: 0,
            name: this.$tc("title.package", 2),
            packages: this.packages,
          },
        ];
        cats = pq.concat(this.categories);
      } else {
        cats = this.categories;
      }
      return this.$_recursiveCategories(cats, []);
    },
  },
  methods: {
    /*filterCategory(refName) {
      document.getElementById(refName).scrollIntoView();
    },*/
    refreshAlerts(event) {
      this.alerts = [];
      this.alerts.push(event);
    },
    productSelect(product) {
      if (product.purchasable_type === "available_package") {
        this.$store.dispatch("showProductPanelPackage", product);
      } else if (
        product.composable_product_items.length > 0 ||
        product.extras.length > 0
      ) {
        this.$store.dispatch("showComposableProductPanel", product);
      } else {
        this.$store.dispatch("showProductPanel", product);
      }
    },
    productList(category) {
      var products = [];

      if (!category.packages) {
        products = this.products.filter((p) => p.category_id == category.id);
      }
      return products;
    },

    subCategories(category) {
      let sub = [];

      if (category != null && category.subcategories.length > 0) {
        sub.push(category.subcategories ?? category.sub_categories);
      }

      sub.forEach(function callback(subcategoy, index) {
        // tu iterador

        if (subcategoy[index].sub_categories.length > 0) {
          sub.push(subcategoy[index].sub_categories);
        }
      });

      console.log("subcategory", sub);
      /*sub[0].map((subcategoy) => {
      
        if (subcategoy.sub_categories.length > 0) {
          sub.push(subcategoy.sub_categories);
         
        }
      });*/

      return sub;
    },

    $_recursiveProducts(items, arr) {
      //In order to be iterable, non-array objects must have a [Symbol.iterator]() method.
      var products = arr;
      for (let cat of items) {
        for (let p of cat.products) {
          products.push(p);
        }

        if ("subcategories" in cat) {
          this.$_recursiveProducts(cat.subcategories, products);
        }

        if ("sub_categories" in cat) {
          this.$_recursiveProducts(cat.sub_categories, products);
        }
      }

      return products;
    },

    $_recursiveCategories(items, arr) {
      //In order to be iterable, non-array objects must have a [Symbol.iterator]() method.
      var cats = arr;
      for (let cat of items) {
        cats.push(cat);
        if ("subcategories" in cat) {
          this.$_recursiveCategories(cat.subcategories, cats);
        }

        if ("sub_categories" in cat) {
          this.$_recursiveCategories(cat.sub_categories, cats);
        }
      }

      return cats;
    },
    goMain() {
      this.$router.push({
        name: "Home",
        params: { id: this.$root.table_qrcode },
      });
    },

    getPrice(product) {
      let channelPrices;
      if (this.commandData.distribution_channel_id != null) {
        if (this.distributionChannel != null) {
          /*  if a channel is set, respect just prices for this channel */
          channelPrices = product.prices.filter(
            (p) => p.distribution_channel_id == this.distributionChannel.id
          );
          if (channelPrices.length == 0)
            channelPrices = product.prices.filter(
              (p) => p.distribution_channel_id == null
            );
        } else {
          /*  else, respect just prices for restaurant (null channel) */
          channelPrices = [{ amount: product.current_price }];
        }
      } else {
        if (product.prices.length > 0) {
          channelPrices = product.prices.filter(
            (p) => p.distribution_channel_id == null
          );
        } else {
          channelPrices = product.current_price;
        }
      }

      return channelPrices.length > 0
        ? channelPrices[channelPrices.length - 1].amount / 100
        : 0;
    },

    getFullImagePath(product) {
      return this.tenantUrl != null ? this.tenantUrl + product.image_path : "";
    },
    toggleTemplate() {
      this.$store.commit("toggleMenuTemplate", "Template2");
    },
  },
};
</script>

<style scoped>
.main {
  background-color: #edf2f7 !important;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  width: auto;

  color: #edf2f7;

  /* cambiar el estilo por defecto de la barra de scroll */
}

.main-scroll {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  scrollbar-color: rgb(89, 0, 255) #1689be !important;
  scrollbar-width: 10px !important;
}

.h-title {
  width: 800px;
  margin: 60px auto 0;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.2;
  font-size: 2.25rem;
  letter-spacing: 1px;
  padding: 0;
}

.main-menu-title {
  color: #138496;
  border-bottom: 4px solid red;
  margin: 26px auto 0px;
  width: initial;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.2;
  font-size: 2.25rem;
  letter-spacing: 1px;
  padding: 0;
}

.tex-category {
  color: #138496;
  margin: 26px auto 0px;
  width: auto;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 1.2;
  font-size: 2.25rem;
  letter-spacing: 2px;
  padding: 0;
}

.u-text-2 {
  font-size: 1.25rem;
  font-style: italic;
  width: 800px;
  margin: 20px auto 0;
}
.product-container {
  --repeat: auto-fit;
}
@media (min-width: calc(250px * 3)) {
  .product-container {
    --repeat: 2;
  }
}
.product-container {
  min-height: 306px;
  grid-template-columns: repeat(
    var(--repeat, auto-fit),
    minmax(calc(250px * 1), 1fr)
  );
  grid-gap: 18px;
  margin: auto !important;
  display: grid;
  width: auto !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 9px;
}

.product-row {
  color: #138496;
  background-color: #ffffff;
  display: flex;
  position: relative;
  text-align: center;
  transition: 0.5s all ease;
  border-radius: auto !important;
}

.product-row:hover {
  transform: scale(1.04);
  transition: 0.5s all ease;
  cursor: pointer;
}

.product-col {
  position: relative;
  flex: 1;
  max-width: auto !important;
  padding: auto !important;
}

.product-image {
  text-transform: none;
  font-style: italic;
  margin: 32px 2px 0;
}

img.u-image {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.product-title {
  text-transform: none;
  font-style: italic;
  margin: 32px 2px 0;
}

.product-price {
  color: #f12c0e !important;
  font-weight: 700;
  margin: 20px 0 0;
}
.bill-container-template {
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  justify-content: start !important;
}

.u-text {
  word-wrap: break-word;
  position: relative;
}

.btnAllProds {
  border-width: 2px;
  border-color: #ffffff !important;
  color: #ffffff !important;
  background-color: transparent !important;
  letter-spacing: 1px;
  font-size: 1.125rem;
  line-height: 1.4;
  background-image: none;
  border-style: solid;
  margin: 30px auto 60px;
  padding: 10px 30px;
  font-family: Merriweather, serif;
  cursor: pointer;
  transition: 0.5s all ease;
}

.btnAllProds:hover {
  text-decoration: none !important;
  transition: 0.5s all ease;
  transform: scale(1.1);
}

@media (max-width: 1199px) {
  .product-container {
    min-height: 252px;
    height: auto;
    margin-right: initial;
    margin-left: initial;
  }
}

@media (max-width: 991px) {
  .product-container {
    grid-template-columns: repeat(2, calc(((100% - 720px) / 2) + 351px));
    margin-right: initial;
    margin-left: initial;
  }

  .h-title {
    width: 720px;
  }

  .u-text-2 {
    width: 720px;
  }
}

@media (max-width: 767px) {
  .product-container {
    grid-template-columns: 100%;
    margin-right: initial;
    margin-left: initial;
  }

  .h-title {
    display: none;
    width: 540px;
  }

  .u-text-2 {
    width: 540px;
  }
}

@media (max-width: 575px) {
  .h-title {
    width: 340px;
  }

  .u-text-2 {
    width: 340px;
  }

  .u-sheet {
    width: 340px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .u-sheet {
    width: 540px;
  }
}
.btn-pad {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

@media screen and (min-width: 768px) and (max-height: 760px) {
  .btn-pad {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}

.box-template {
  background-clip: border-box;
  overflow: auto;
}
</style>