<template>
  <div class="d-flex flex-column h-100 text-sm">
    <b-row>
      <b-col cols="12">
        <h5
          :class="
            !isMobile
              ? 'mb-3 d-flex justify-content-between p-5'
              : 'mb-3 d-flex justify-content-between'
          "
        >
          <span>
            <a
              href="#"
              class="d-inline-block mr-3"
              @click.prevent="hideProductPanel"
            >
              <i class="fas fa-arrow-left"></i>

              {{
                productBeingEdited !== null
                  ? $t("title.return_account")
                  : $t("title.return_menu")
              }}
            </a>
          </span>
        </h5>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="9">
        <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="3000"
          controls
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <b-carousel-slide>
            <template #img>
              <b-overlay
                spinner-variant="primary"
                class="h-100 text-center mb-3 text-info font-weight-bold"
              >
                <figure
                  class="
                    card card-product
                    pusheable
                    h-100
                    justify-content-center
                  "
                >
                  <div class="img-wrap-package">
                    <img
                      width="auto"
                      :src="getFullImagePath(productSelected.image_path)"
                    />
                  </div>
                  <figcaption class="info-wrap p-1">
                    <h6 class="title text-center">
                      {{ productSelected.name }}
                    </h6>
                    <div class="price-wrap h6 text-center">
                      <span class="price-new">
                        <strong>{{ formatMoney(price) }} </strong>
                      </span>
                    </div>
                    <!-- price-wrap.// -->
                  </figcaption>
                  <!-- <div class="d-flex position-absolute top-0 h-100 w-100 justify-content-center align-items-center shadow"  v-if="isAdding">
                    <b-spinner small ></b-spinner>
                </div> -->
                </figure>
              </b-overlay>
            </template>
          </b-carousel-slide>

          <b-carousel-slide
            v-for="(package_item, key) in PackageItems"
            :key="key"
          >
            <template #img>
              <b-overlay
                spinner-variant="primary"
                class="h-100 text-center mb-3 text-info font-weight-bold"
              >
                <figure
                  class="
                    card card-product
                    pusheable
                    h-100
                    justify-content-center
                  "
                >
                  <div class="img-wrap-package">
                    <img
                      width="auto"
                      :src="tenantUrl + '' + package_item.product.image_path"
                    />
                  </div>
                  <figcaption class="info-wrap p-1">
                    <h6 class="title text-center">
                      {{ package_item.product.name }}
                    </h6>
                    <div class="price-wrap h6 text-center">
                      <span class="price-new">
                        <strong>
                          {{ productSelected.name }}
                        </strong>
                      </span>
                    </div>
                  </figcaption>
                </figure>
              </b-overlay>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </b-col>
      <b-col
        cols="12"
        md="8"
        clas="mt-3"
        v-if="productSelected.purchasable_type !== 'available_package_item'"
      >
        <b-form-group>
          <template #label>
            <span class="text-base"
              >{{ $t("title.quantity") }}
              <i class="fa fa-spin fa-spinner" v-if="savingQuantity"></i
            ></span>
          </template>
          <vtr-lw-spinbutton
            v-model="quantity"
            :min="0.001"
            size="lg"
            :disabled="productSelected.confirmed"
          ></vtr-lw-spinbutton>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="bill-container" v-if="!productSelected.confirmed">
      <button-product
        @addProduct="addPackage()"
        :isAccountEditable="isAccountEditable"
        @deleteProduct="deleteProduct(productSelected)"
        :productBeingEdited="productBeingEdited"
        :processing="processing"
        :productSelected="productSelected"
        :processingDeleted="processingDeleted"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import groupBy from "lodash/groupBy";
import { formatMoney } from "../../../js/utils.js";
import ButtonProduct from "../Buttons/ButtonProduct.vue";

export default {
  name: "ProductPackagePanel",
  data() {
    return {
      slide: 0,
      imagePath: null,
      sliding: null,
      savingPrice: false,
      savingQuantity: false,
      price: 0,
      quantity: 1,
      showPackageModal: false,
      isAdding: false,
      editproduct: false,
    };
  },
  props: ["command"],
  components: {
    ButtonProduct,
  },
  mounted() {
    let priceProduct =
      this.productSelected.amount ?? this.productSelected.current_price.amount;
    this.price = priceProduct / 100;
    let quantityProduct = this.productSelected.quantity ?? this.quantity;
    this.quantity = parseFloat(quantityProduct);
  },
  computed: {
    ...mapGetters(["distributionChannel"]),
    ...mapState({
      productSelected: (state) => state.productSelected,
      accountSelected: (state) => state.command.accountSelected,
      productBeingEdited: (state) => state.productBeingEdited,
      packages: (state) => state.packages,
      isMobile: (state) => state.isMobile,
      processingDeleted: (state) => state.processingDeleted,
      processing: (state) => state.processing,
      tenantUrl: (state) => state.tenantUrl,
    }),

    PackageItems() {
      let package_items = [];
      if (this.productSelected != null) {
        package_items = this.productSelected.available_package_items;
      }
      return package_items;
    },

    isAccountEditable() {
      return true;
    },
    prices() {
      if (this.distributionChannel) {
        const channelPrice = this.productSelected.prices.find(
          (p) => p.distribution_channel_id == this.distributionChannel.id
        );
        if (channelPrice) {
          return channelPrice;
        }
      }

      return this.productSelected.current_price;
    },
    currentPackage() {
      if (this.productSelected.purchasable_type !== "available_package") {
        return undefined;
      }

      return this.packages.find(
        (p) => this.productSelected.purchasable.id == p.id
      );
    },
    slots() {
      if (this.currentPackage) {
        return groupBy(
          this.currentPackage.available_package_items,
          (item) => item.slot
        );
      }

      return {};
    },
  },
  methods: {
    onSlideStart(slide) {
      console.log("slide", slide);
      this.sliding = true;
    },
    onSlideEnd(slide) {
      console.log("slide", slide);
      this.sliding = false;
    },
    ...mapActions(["updateProductSelected"]),
    hideProductPanel() {
      if (this.productBeingEdited == null) {
        this.$store.dispatch("hideProductPanel");
        this.$store.commit("showProducts");
      } else {
        this.$store.dispatch("hideProductPanel");
      }
    },
    getFullImagePath(image_path) {
      this.imagePath =
        this.tenantUrl != null ? this.tenantUrl + "" + image_path : "";
      return this.imagePath;
    },
    deleteProduct(product) {
      let data = {
        productId: product.id,
        open_table_id: this.command.open_table.id,
        table_qrcode: this.$root.table_qrcode,
      };
      this.$store.dispatch("deleteProduct", data);
    },

    addPackage() {
      let slots = this.PackageItems;
      let data = {
        product: this.productSelected,
        available_package_id: this.productSelected.id,
        price_id: this.prices.id,
        items: slots.map((s) => s.id),
        quantity: this.quantity,
        open_table_id: this.command.open_table.id,
        account: this.accountSelected,
        table_qrcode: this.$root.table_qrcode,
      };
      this.$store.dispatch("addPackage", data);
    },

    refreshAlerts(event) {
      this.$emit("refreshAlerts", event);
    },
    formatMoney,
  },
};
</script>

<style scoped>
.custom-control-label {
  cursor: pointer !important;
}
</style>