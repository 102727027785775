export function moneyString(m) {
  if (m === null) return ''
  return moneyStringFull(m.amount / 100, m.currency)
}

export function moneyStringFull(amount, currency) {
  return moneyJustDigits(amount) + ' ' + currency
}

export function moneyStringWithoutCurrency(m) {
  if (m === null) return ''
  return moneyJustDigits(m.amount / 100)
}

export function moneyJustDigits(amount) {
  return Number.parseFloat(amount).toLocaleString('en', { minimumFractionDigits: 2 })
}

export function amountFromMoneyString(m) {
  return Number.parseFloat(m) * 100;
}

export function filterAndChangeMoneyStrings(object, allowed, money) {
  return Object.keys(object).filter(key => allowed.includes(key))
    .reduce((filtered, key) => {
      return {
        ...filtered,
        [key]: money.includes(key) ?
          amountFromMoneyString(object[key]) : object[key]
      }
    }, {});

}

/*export function can(action, resource) {
  if (permissions === null) return false
  if (typeof (permissions[resource]) === 'undefined') return false
  return permissions[resource].includes(action)
}*/

let Validation = function () {
  var errors = [];
  return {
    rule(valid, invalidMessage) {
      if (!valid) errors.push(invalidMessage)
    },
    validate: function () {
      return {
        valid: errors.length == 0,
        messageBag: errors
      }
    }
  }
};

export { Validation };

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-$" : "$";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log("Huston: we've had a problem -> " + e.message);
  }
}

export function getAccountTitle (account) {
  const account_title = `${this.$tc("title.account", 1)} ${String.fromCharCode(64 + parseInt(account.account))}`;
  if(account.closed_at)
  {
    return `${account_title} ${this.$t("info.account_closed")}`;
  }
  if(account.locket_at)
  {
    return `${account_title} ${this.$t("info.account_locked")}`;
  }
  if(account.cancelled_at)
  {
    return `${account_title} ${this.$t("info.account_cancelled")}`;
  }
  else
  {
    return account_title;
  }
}

export function disableAccount (account) {
  return !!(account.closed_at || account.locket_at || account.cancelled_at)
}

export function checkAllIsConfirmed(purchases) {
  let confirm = false;
  if (purchases.length > 0) {
      purchases.map((purchase) => {
      if (purchase.confirmed) {
        confirm = true;
      } else {
        confirm = false;
      }
    });
  }
  return confirm;
}

export function formatQueryString(params) {
  return Object.keys(params)
    .filter(param => !!params[param])
    .reduce((url, param) => url.concat(`${param}=${encodeURIComponent(params[param])}&`), '')
    .slice(0, -1);
}

export function extractExtras(product) {
  let extras = 0;

  if (Object.prototype.hasOwnProperty.call(product, 'extras')) {
    extras += extractExtrasPrice(product.extras)
  }

  if (Object.prototype.hasOwnProperty.call(product, 'components')) {
    extras += extractExtrasPriceInComponents(product.components)
  }

  return extras
}

export function extractExtrasPrice(extras) {
  return extras.reduce((acc, e) => {
    return acc + (e.qty * e.product.price)
  }, 0)
}

export function extractExtrasPriceInComponents(components) {
  return Object.keys(components).reduce((acc, key) => {
    return acc + components[key].reduce((accS, selection) => {
      if (selection.product === null) return accS;
      return accS + (selection.qty * extractExtras(selection.product))
    }, 0)
  }, 0)
}

export function getTotalPurchasePrice(purchase) {
  return (
    (purchase.amount * purchase.quantity) 
    + purchase.extras.reduce((acc, extra) => acc + (extra.amount * extra.quantity), 0)
    + purchase.purchases.reduce(
      (acc, purch) => acc + getTotalPurchasePrice(purch), 0
    )
  )
}

export function getTotalAccount(account) {
  return account.purchases.reduce(
    (acc, purchase) => acc + getTotalPurchasePrice(purchase), 0
  )
}
