<template>
  <div
    class="
      bg-white
      d-flex
      flex-md-column flex-nowrap
      p-3
      shadow
      flex-shrink-0
      overflow-hidden
      h-100
    "
  >
    <div
      class="
        d-flex
        flex-md-column flex-nowrap
        justify-content-center
        overflow-auto
        flex-grow-1
      "
    >
      <b-button
        v-if="
          !productSelected.confirmed ||
          (!productSelected.confirmed && productBeingEdited != null &&
            productSelected.purchasable_type !== 'available_package_item')
        "
        :variant="productBeingEdited !== null ? 'primary' : 'success'"
        :disabled="
          processing || productSelected.confirmed || !isAccountEditable
        "
        @click="addProduct"
        size="lg"
      >
        <b-spinner v-if="processing" small variant="success"></b-spinner>

        {{
          productBeingEdited !== null
            ? $tc("title.updated_product", 1)
            : $tc("title.add_products", 1)
        }}
      </b-button>
      <b-button
        v-if="
          !productSelected.confirmed &&
          productBeingEdited !== null &&
          productSelected.purchasable_type !== 'available_package_item'
        "
        variant="danger"
        :disabled="
          processingDeleted || productSelected.confirmed || !isAccountEditable
        "
        @click="deleteProduct"
        size="lg"
      >
        <b-spinner v-if="processingDeleted" small variant="success"></b-spinner>
        <span v-else class="fas fa-trash"></span>
        {{ $tc("title.removed_product", 1) }}
      </b-button>

      <!--<b-button
        v-if="
          (isMobile &&
            productSelected.confirmed &&
            productBeingEdited != null) ||
          (isMobile && productBeingEdited !== null)
        "
        variant="info"
        @click.prevent="hideProductPanel"
        size="lg"
      >
        <span> {{ $t("title.see_account") }}</span>
      </b-button>-->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ButtonProduct",
  props: [
    "isAccountEditable",
    "productBeingEdited",
    "processing",
    "productSelected",
    "processingDeleted",
  ],

  methods: {
    addProduct() {
      this.$emit("addProduct");
    },

    hideProductPanel() {
      if (this.productBeingEdited == null) {
        this.$store.dispatch("hideProductPanel");
        this.$store.commit("showProducts");
      } else {
        this.$store.dispatch("hideProductPanel");
      }
    },

    deleteProduct() {
      this.$emit("deleteProduct");
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
    }),
  },
};
</script>

<style scoped>
.btn-pad {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

@media screen and (min-width: 768px) and (max-height: 760px) {
  .btn-pad {
    padding: 0.25rem 0.5rem;
    font-size: 0.7875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
