import VueRouter from "vue-router";
import Home from "../components/Home.vue";
import NotFound from "../components/NotFound.vue";

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Home
  },
  {
    path: '/:id',
    name: 'Home',
    component: Home
  },
  {
    path: '/menu/:id',
    name: 'menu.show',
    component: Home
  },
  {
    path: '/command/:id',
    name: 'command.show',
    component: Home
  },
  {
    path: '/payaccount/:id',
    name: 'payaccount.show',
    component: Home
  },
  {
    path : '*',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;